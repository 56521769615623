import React from 'react';
import PropTypes from 'prop-types';
import Button from '@strava/ui/Button';

import I18n from 'utils/I18n';

import styles from './index.scss';

const I18nPrefix = 'components.button_cta_with_cancel.';
const I18nKey = {
  cancel: `${I18nPrefix}cancel`,
  ok: `${I18nPrefix}ok`,
  next: `${I18nPrefix}next`
};

function ButtonCTAWithCancel({
  onCancel,
  onCTA,
  disabled = false,
  ctaDisabled = false,
  ctaVariant = 'ok',
  ctaText
}) {
  return (
    <div className={styles.controls}>
      <Button
        key="cancel"
        type="button"
        className={styles.btnCancel}
        onClick={onCancel}
        disabled={disabled}
      >
        {I18n.t(I18nKey.cancel)}
      </Button>
      <Button
        key="next"
        type="button"
        variant="primary"
        className={styles.btnCta}
        onClick={onCTA}
        disabled={disabled || ctaDisabled}
      >
        {ctaVariant === 'custom' && ctaText
          ? ctaText
          : I18n.t(I18nKey[ctaVariant])}
      </Button>
    </div>
  );
}

ButtonCTAWithCancel.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onCTA: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  ctaDisabled: PropTypes.bool,
  ctaVariant: PropTypes.oneOf(['ok', 'next', 'custom']),
  ctaText: PropTypes.string
};

export default ButtonCTAWithCancel;
