import React from 'react';
import PropTypes from 'prop-types';

import ButtonGroup from '@strava/ui/ButtonGroup';
import Button, { LinkButton } from '@strava/ui/Button';
import Spinner from '@strava/ui/Spinner';
import ActionsArrowLeftNormalXsmall from '@strava/icons/ActionsArrowLeftNormalXsmall';
import ActionsArrowRightNormalXsmall from '@strava/icons/ActionsArrowRightNormalXsmall';
import I18n from 'utils/I18n';
import styles from './styles.scss';
import { STATUS } from '../useFetchLeaderboardData';

const thead = (columnHeaders) => (
  <thead>
    <tr>
      {columnHeaders.map((columnHeader) => (
        <th
          data-testid="column_header"
          className={`text-caption2 text-heavy ${
            styles[columnHeader.styleName]
          }`}
          key={columnHeader.id}
        >
          {columnHeader.label}
        </th>
      ))}
    </tr>
  </thead>
);

const messageRow = (numColumns, message, isError) => (
  <tr>
    <td colSpan={numColumns}>
      <p
        className={`text-caption1 ${styles.messageRow} ${
          isError ? styles.error : ''
        }`}
        data-testid="message_row"
      >
        {message}
      </p>
    </td>
  </tr>
);

const paywallMessage = (numColumns, message, onSubscribeCallback) => (
  <tr data-testid="paywall_row">
    <td colSpan={numColumns}>
      <div className={styles.paywallBanner}>
        <div className={styles.image} />
        <div className={styles.message}>
          <h2 className="text-headline mb-0">
            {I18n.t(
              'strava.challenges.challenge_detail.leaderboard.premium_leaderboards'
            )}
          </h2>
          <div className={`text-caption1 ${styles.subTitle}`}>{message}</div>
          <div className={styles.callToAction}>
            <LinkButton
              href="/subscribe"
              variant="primaryOutline"
              onClick={onSubscribeCallback}
            >
              {I18n.t(
                'strava.challenges.challenge_detail.leaderboard.subscribe'
              )}
            </LinkButton>
          </div>
        </div>
      </div>
    </td>
  </tr>
);

const tbody = (
  resultRows,
  columnHeaders,
  status,
  message,
  onSubscribeCallback
) => {
  const numColumns = columnHeaders.length;
  // Successful response with result rows
  if (status === STATUS.resolved && resultRows.length !== 0) {
    return resultRows.map((row) => (
      <tr
        data-testid="result_row"
        className={`text-caption1 ${styles.leaderboardEntry} ${
          row.highlight ? 'text-bold' : ''
        }`}
        key={row.id}
      >
        {row.value.map((col) => (
          <td data-testid="result_column" key={`${row.id}-${col.id}`}>
            {col.value}
          </td>
        ))}
      </tr>
    ));
  }
  // Successful response with no result rows
  if (
    [STATUS.resolved, STATUS.idle].includes(status) &&
    resultRows.length === 0
  ) {
    return messageRow(numColumns, message, false);
  }
  // Failed request
  if (status === STATUS.rejected) {
    return messageRow(numColumns, message, true);
  }

  // Restricted for unsubscribers
  if (status === STATUS.restricted) {
    return paywallMessage(numColumns, message, onSubscribeCallback);
  }

  // Loading...
  return (
    <tr>
      <td colSpan={numColumns}>
        <div className={styles.spinner} data-testid="spinner">
          <Spinner />
        </div>
      </td>
    </tr>
  );
};

const tfoot = (
  resultRows,
  status,
  columnHeaders,
  isFirstPage,
  isLastPage,
  onNextPageCallback,
  onPrevPageCallback,
  paginationLabel
) => {
  if (
    [STATUS.resolved, STATUS.idle].includes(status) &&
    resultRows.length !== 0
  ) {
    return (
      <tfoot>
        <tr>
          <td colSpan={columnHeaders.length}>
            <div className={`text-caption1 ${styles.pagination}`}>
              {paginationLabel && (
                <div
                  className={styles.paginationLabel}
                  data-testid="pagination-label"
                >
                  {paginationLabel}
                </div>
              )}
              <ButtonGroup>
                <Button
                  role="button"
                  variant="default"
                  onClick={onPrevPageCallback}
                  data-testid="prev_btn"
                  disabled={isFirstPage}
                >
                  <ActionsArrowLeftNormalXsmall />
                </Button>
                <Button
                  role="button"
                  disabled={isLastPage}
                  onClick={onNextPageCallback}
                  data-testid="next_btn"
                >
                  <ActionsArrowRightNormalXsmall />
                </Button>
              </ButtonGroup>
            </div>
          </td>
        </tr>
      </tfoot>
    );
  }
  return null;
};

const LeaderboardsTable = ({
  resultRows = [],
  status = STATUS.idle,
  columnHeaders = [],
  onNextPageCallback = () => {},
  onPrevPageCallback = () => {},
  onSubscribeCallback = () => {},
  isFirstPage = false,
  isLastPage = false,
  message = '',
  paginationLabel,
  className
}) => (
  <div className={`${styles.tableContainer} ${className}`}>
    <table>
      {thead(columnHeaders)}
      <tbody>
        {tbody(resultRows, columnHeaders, status, message, onSubscribeCallback)}
      </tbody>
      {tfoot(
        resultRows,
        status,
        columnHeaders,
        isFirstPage,
        isLastPage,
        onNextPageCallback,
        onPrevPageCallback,
        paginationLabel
      )}
    </table>
  </div>
);

LeaderboardsTable.propTypes = {
  columnHeaders: PropTypes.arrayOf(
    PropTypes.shape({
      style: PropTypes.string,
      label: PropTypes.string,
      styleName: PropTypes.string
    })
  ),
  resultRows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      value: PropTypes.arrayOf(
        PropTypes.shape({ id: PropTypes.number, value: PropTypes.any })
      )
    })
  ),
  onNextPageCallback: PropTypes.func,
  onPrevPageCallback: PropTypes.func,
  onSubscribeCallback: PropTypes.func,
  status: PropTypes.oneOf(Object.keys(STATUS)),
  isLastPage: PropTypes.bool,
  isFirstPage: PropTypes.bool,
  message: PropTypes.string,
  paginationLabel: PropTypes.string,
  className: PropTypes.string
};

export default LeaderboardsTable;
