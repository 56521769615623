import React, { useState } from 'react';
import I18n from 'utils/I18n';
import PropTypes from 'prop-types';
import DropdownSelect from '@strava/ui/DropdownSelect';

import Spinner from '@strava/ui/Spinner';
import { AutoSizer, InfiniteLoader, List } from 'react-virtualized';
import styles from './styles.scss';
import useFetchFollowers from './hooks/useFetchFollowers';

const FollowsList = ({
  athleteFirstName,
  primaryNav,
  hasBlockedAthletes,
  resultsPerPage,
  type
}) => {
  const t = (key, name) =>
    name
      ? I18n.t(`strava.athletes.follows.menu.${key}`, name)
      : I18n.t(`strava.athletes.follows.menu.${key}`);

  const [selectedOption, setSelectedOption] = useState(type);

  const { isLoading, loadFollows, results, hasMore, error } = useFetchFollowers(
    {
      resultsPerPage
    }
  );

  const options = (() => {
    if (primaryNav === 'profile') {
      // when viewing own profile
      const o = [
        { label: t('profile.following'), value: 'following' },
        { label: t('profile.followers'), value: 'followers' },
        { label: t('profile.suggested'), value: 'suggested' }
      ];
      if (hasBlockedAthletes) {
        o.push({ label: t('profile.blocked'), value: 'blocked' });
      }
      return o;
    }
    const nameParam = { firstName: athleteFirstName };
    // when viewing another athletes profile
    return [
      {
        label: t('athlete.following', nameParam),
        value: 'following'
      },
      {
        label: t('athlete.followers', nameParam),
        value: 'followers'
      },
      { label: t('athlete.both_following'), value: 'both_following' }
    ];
  })();

  const handleOptionChange = (option) => {
    setSelectedOption(option.value);
    // clear and reload results when an athlete changes the follows type
    loadFollows({
      selectedOption: option.value,
      reset: true
    });
  };

  const loader = () => {
    return (
      <div className={styles.spinner}>
        <Spinner />
      </div>
    );
  };

  /**
   * Number of follows.
   *
   * This messages to infinite scroller that there is always one more
   * row to be fetched until we verify that the athlete does not have any more follows
   * (hasMore == false). This happens when we fetch a page that returns less results than
   * the results per page limit (resultsPerPage)
   */
  const rowCount = (() => (hasMore ? results.length + 1 : results.length))();

  /**
   * Responsible for tracking the loaded state of each result row
   */
  const isRowLoaded = ({ index }) => {
    return index < results.length;
  };

  // responsible for rendering a single card entry (row), given its index
  const rowRenderer = (params) => {
    // [ named parameters from react-virtualized ]
    // index - index of row
    // key - unique key within array of rows
    // style - style object to be applied to row
    const { index, key, style } = params;
    let content;
    if (!isRowLoaded({ index })) {
      content = loader();
    } else {
      const athleteEntry = results[index].find((o) => o.athlete);
      const followEntry = results[index].find((o) => o.follow);

      const athlete = athleteEntry ? athleteEntry.athlete : {};
      const follow = followEntry ? followEntry.follow : {}; // some follows-types do not have this follow entry
      content = `${athlete.firstname} - ${follow.status}`;
    }

    return (
      <div key={key} style={style}>
        {content}
      </div>
    );
  };

  /**
   * Callback to be invoked when more rows must be loaded.
   */
  const loadMoreRows = ({ startIndex, stopIndex }) => {
    return new Promise((resolve) => {
      loadFollows({
        selectedOption,
        reset: false,
        startIndex,
        stopIndex
      }).then(() => {
        resolve();
      });
    });
  };

  /**
   *  Callback used to render placeholder content when rowCount is 0
   */
  const noRowsRenderer = () => {
    return isLoading ? (
      loader()
    ) : (
      <div>{t(`no_results.${selectedOption}`)}</div>
    );
  };

  return (
    <>
      <DropdownSelect
        className={styles.optionsDropdown}
        options={options}
        value={options.find((o) => o.value === selectedOption)}
        onChange={handleOptionChange}
        ariaLabel={t('aria_label')}
      />
      <div className={styles.resultsContainer}>
        {!error && (
          <InfiniteLoader
            isRowLoaded={isRowLoaded}
            loadMoreRows={loadMoreRows}
            rowCount={rowCount}
          >
            {({ onRowsRendered, registerChild }) => (
              <AutoSizer disableHeight={true}>
                {({ width }) => (
                  <List
                    className={styles.list}
                    height={800}
                    noRowsRenderer={noRowsRenderer}
                    rowCount={rowCount}
                    rowHeight={60}
                    rowRenderer={rowRenderer}
                    ref={registerChild}
                    onRowsRendered={onRowsRendered}
                    width={width}
                  />
                )}
              </AutoSizer>
            )}
          </InfiniteLoader>
        )}

        {error && <div className={styles.error}>{t('error_message')}</div>}
      </div>
    </>
  );
};

FollowsList.propTypes = {
  primaryNav: PropTypes.oneOf(['profile', 'athlete']).isRequired,
  hasBlockedAthletes: PropTypes.bool.isRequired,
  athleteFirstName: PropTypes.string.isRequired,
  resultsPerPage: PropTypes.number.isRequired,
  type: PropTypes.oneOf([
    'following',
    'followers',
    'both_following',
    'suggested',
    'blocked'
  ]).isRequired
};

export default FollowsList;
