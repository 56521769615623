import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import AchievementsLocalLegendNormalXsmall from '@strava/icons/AchievementsLocalLegendNormalXsmall';
import AchievementsKomHighlightedXsmall from '@strava/icons/AchievementsKomHighlightedXsmall';
import I18n from 'utils/I18n';
import Analytics from 'utils/analytics';
import styles from './styles.scss';

import AvatarWithDataRow from './components/AvatarWithDataRow';
import CardActionsSection from './components/CardActionsSection';

const SegmentDetailsSideBar = ({ loggedIn, sideBarProps }) => {
  useEffect(() => {
    let localLegendAthleteId = null;
    let localLegendEffortCount = null;
    let komAthleteId = null;
    let komEffortId = null;
    let qomAthleteId = null;
    let qomEffortId = null;
    let overallEffortId = null;
    let overallAthleteId = null;
    let prEffortId = null;
    let segmentEffortCount = null;
    if (sideBarProps.localLegend) {
      localLegendAthleteId = sideBarProps.localLegend.id;
      localLegendEffortCount = sideBarProps.localLegend.mayorEffortCount;
    }
    if (sideBarProps.fastestTimes.men) {
      komAthleteId = sideBarProps.fastestTimes.men.id;
      komEffortId = sideBarProps.fastestTimes.men.segmentEffortId;
    }
    if (sideBarProps.fastestTimes.women) {
      qomAthleteId = sideBarProps.fastestTimes.women.id;
      qomEffortId = sideBarProps.fastestTimes.women.segmentEffortId;
    }
    if (sideBarProps.fastestTimes.overall) {
      overallAthleteId = sideBarProps.fastestTimes.overall.id;
      overallEffortId = sideBarProps.fastestTimes.overall.segmentEffortId;
    }
    if (loggedIn) {
      prEffortId = sideBarProps.viewingAthlete.segmentEffortId;
      segmentEffortCount = sideBarProps.viewingAthlete.segmentEffortCount;
    }
    Analytics.trackV2({
      action: 'screen_enter',
      category: 'segments',
      page: 'segment_details',
      element: 'side_bar',
      properties: {
        segment_id: sideBarProps.segmentId,
        fastest_times: {
          kom_effort_id: komEffortId,
          kom_athlete_id: komAthleteId,
          qom_athlete_id: qomAthleteId,
          qom_effort_id: qomEffortId,
          overall_effort_id: overallEffortId,
          overall_athlete_id: overallAthleteId
        },
        local_legend: {
          athlete_id: localLegendAthleteId,
          efforts_count: localLegendEffortCount
        },
        your_stats: {
          pr_effort_id: prEffortId,
          segment_effort_count: segmentEffortCount
        }
      }
    });
  }, []);

  const yourStats = () => {
    return (
      <AvatarWithDataRow
        key="your_stats"
        data={sideBarProps.viewingAthlete}
        loadExcludedEfforts={true}
        segmentId={sideBarProps.segmentId}
      />
    );
  };

  const fastestTimes = () => {
    const icon = <AchievementsKomHighlightedXsmall fill="#F6C95F" />;
    const sectionComponents = [];
    if (sideBarProps.fastestTimes.overall) {
      sectionComponents.push(
        <AvatarWithDataRow
          key="fastest_times_overall"
          data={sideBarProps.fastestTimes.overall}
          achievementIcon={icon}
        />
      );
    }
    if (sideBarProps.fastestTimes.men) {
      sectionComponents.push(
        <AvatarWithDataRow
          key="fastest_times_men"
          data={sideBarProps.fastestTimes.men}
          achievementIcon={icon}
        />
      );
    }
    if (sideBarProps.fastestTimes.women) {
      sectionComponents.push(
        <AvatarWithDataRow
          key="fastest_times_women"
          data={sideBarProps.fastestTimes.women}
          achievementIcon={icon}
        />
      );
    }
    return sectionComponents;
  };

  const localLegend = () => {
    const icon = <AchievementsLocalLegendNormalXsmall fill="#F6C95F" />;
    return (
      <AvatarWithDataRow
        key="local_legend"
        data={sideBarProps.localLegend}
        achievementIcon={icon}
      />
    );
  };

  const sideBarEntryData = () => {
    let viewingAthleteStats = {};
    let localLegendStats = {};
    let fastestTimesStats = {};
    const { segmentId } = sideBarProps;
    if (loggedIn) {
      viewingAthleteStats = {
        title: I18n.t('templates.segments.analysis.side_bar.your_stats'),
        useHamlGoalCreationModule: true, // See CardActionsSection#onClickSetGoal
        urlLabel: I18n.t('templates.segments.analysis.side_bar.set_goal'),
        sectionComponents: yourStats(),
        segmentNotEligible: sideBarProps.viewingAthlete.segmentNotEligible,
        athleteRestricted: sideBarProps.viewingAthlete.athleteRestricted,
        upcomingGoal: sideBarProps.viewingAthlete.upcomingGoal,
        segmentId
      };
    }
    if (sideBarProps.fastestTimes.overall || sideBarProps.fastestTimes.women) {
      fastestTimesStats = {
        title: I18n.t('templates.segments.analysis.side_bar.fastest_times'),
        url: `/segments/${sideBarProps.segmentId}/compare`,
        urlLabel: I18n.t(
          'templates.segments.analysis.side_bar.compare_efforts'
        ),
        sectionComponents: fastestTimes(),
        segmentId,
        section: 'fastest_times'
      };
    }
    if (sideBarProps.localLegend) {
      localLegendStats = {
        title: I18n.t('templates.segments.analysis.side_bar.most_efforts'),
        url: `/segments/${sideBarProps.segmentId}/local-legend`,
        urlLabel: I18n.t('templates.segments.analysis.side_bar.view_lcl_stats'),
        sectionComponents: localLegend(),
        segmentId,
        section: 'local_legend'
      };
    }
    return [viewingAthleteStats, fastestTimesStats, localLegendStats].filter(
      (e) => {
        return Object.keys(e).length !== 0; // Remove components that are not populated with any data
      }
    );
  };
  return (
    <ul>
      {sideBarEntryData().map((entry) => (
        <li className={styles.card} key={entry.title}>
          <h2 className={`text-title3 ${styles.title}`}>{entry.title}</h2>
          <div className={styles.data}>{entry.sectionComponents}</div>
          <div className={styles.actionLink}>
            <CardActionsSection entry={entry} />
          </div>
        </li>
      ))}
    </ul>
  );
};

SegmentDetailsSideBar.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  sideBarProps: PropTypes.shape({
    segmentId: PropTypes.number.isRequired,
    viewingAthlete: PropTypes.shape({
      segmentEffortId: PropTypes.number,
      segmentEffortCount: PropTypes.number,
      segmentNotEligible: PropTypes.bool,
      athleteRestricted: PropTypes.bool,
      upcomingGoal: PropTypes.shape({}),
      stats: PropTypes.array
    }),
    fastestTimes: PropTypes.shape({
      overall: PropTypes.shape({
        id: PropTypes.number,
        segmentEffortId: PropTypes.number
      }),
      men: PropTypes.shape({
        id: PropTypes.number,
        segmentEffortId: PropTypes.number
      }),
      women: PropTypes.shape({
        id: PropTypes.number,
        segmentEffortId: PropTypes.number
      })
    }),
    localLegend: PropTypes.shape({
      id: PropTypes.number,
      mayorEffortCount: PropTypes.number,
      icon: PropTypes.element
    })
  }).isRequired
};

export default SegmentDetailsSideBar;
