import { useReducer } from 'react';
import { snakeCase } from 'lodash-es';
import { buildInitialKudosAndCommentsState } from '../../components/shared/KudosCommentsAndAchievements/kudosAndComments.initial-state';
import {
  initKudosAndCommentsState,
  kudosAndCommentsReducer
} from '../../components/shared/KudosCommentsAndAchievements/kudosAndComments.reducer';

// We need to provide a trackAnalytics function to buildInitialKudosAndCommentsState,
// but we are using trackv2 for analytics directly.
const trackAnalytics = () => {};

const useKudosAndCommentsReducer = (entityId, entity, comments) => {
  const [kudosAndCommentsEntityState, dispatch] = useReducer(
    kudosAndCommentsReducer,
    buildInitialKudosAndCommentsState({
      trackAnalytics,
      comments: { [entityId]: comments },
      entity: snakeCase(entity),
      entityId
    }),
    initKudosAndCommentsState
  );

  return { kudosAndCommentsEntityState, dispatch };
};

export default useKudosAndCommentsReducer;
