import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@strava/ui/Button';
import I18n from 'utils/I18n';

import styles from './styles.scss';

const I18nPrefix = 'strava.relative_effort.disclaimer';

function Disclaimer({ onContinueClick }) {
  const [fadeOut, setFadeOut] = useState(false);

  return (
    <div className={`${styles.container} ${fadeOut ? styles.fadeOut : ''}`}>
      <h3 className={`text-headline text-bold ${styles.title}`}>
        {I18n.t(`${I18nPrefix}.title`)}
      </h3>
      <p className={`text-footnote ${styles.body}`}>
        {I18n.t(`${I18nPrefix}.body`)}
      </p>
      <Button
        type="button"
        variant="default"
        className={`text-footnote text-bold ${styles.cta}`}
        onClick={() => {
          if (fadeOut) {
            return;
          }
          setFadeOut(true);
          setTimeout(() => onContinueClick(), 200);
        }}
      >
        {I18n.t(`${I18nPrefix}.cta`)}
      </Button>
    </div>
  );
}

Disclaimer.propTypes = {
  onContinueClick: PropTypes.func.isRequired
};

export default Disclaimer;
