import React, { useState } from 'react';
import PropTypes from 'prop-types';
import I18n from 'utils/I18n';
import Button, { LinkButton } from '@strava/ui/Button';
import Modal, { ModalActions, ModalBody } from '@strava/ui/Modal';
import verifiedBadge from '../../../assets/images/avatar/badges/icon-badge-verified.png';
import styles from './VerifiedSegmentModal.scss';

const VerifiedSegmentModal = ({ activityTypeTitle, location }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModalOpen = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <Button
        variant="icon"
        onClick={toggleModalOpen}
        title={I18n.t('components.verified_segment_modal.title')}
        className={styles.badge}
      >
        <img src={verifiedBadge} alt="" width={16} height={16} />{' '}
        {activityTypeTitle}
      </Button>{' '}
      {location}

      <Modal
        isOpen={isModalOpen}
        onDismiss={toggleModalOpen}
        hasClose={true}
        className={styles.modal}
      >
        <ModalBody>
          <div className={styles.modalHeader}>
            <img src={verifiedBadge} alt="" width={24} height={24} />
            <h2>{I18n.t('components.verified_segment_modal.title')}</h2>
          </div>
          <p>{I18n.t('components.verified_segment_modal.description')}</p>
        </ModalBody>
        <ModalActions>
          <Button
            onClick={toggleModalOpen}
            variant="primary"
            className={styles.button}
          >
            {I18n.t('components.verified_segment_modal.got_it')}
          </Button>
          <LinkButton
            variant="text"
            href="https://support.strava.com/hc/en-us/articles/27780101464973-Strava-Verified-Segments"
            className={styles.button}
          >
            {I18n.t('components.verified_segment_modal.learn_more')}
          </LinkButton>
        </ModalActions>
      </Modal>
    </>
  );
};

VerifiedSegmentModal.propTypes = {
  activityTypeTitle: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired
};

export default VerifiedSegmentModal;
