import React from 'react';
import PropTypes from 'prop-types';

import I18n from 'utils/I18n';

import ProgressGoalDisplay from '../ProgressGoalDisplay';
import styles from './styles.scss';

const I18nKey = 'strava.progress_goals.goals_display.period';

const ProgressGoalPeriodList = ({ goals, periodName, formatters }) => {
  return (
    <div className={`card ${styles.goalPeriodSection}`}>
      <div className="card-body">
        <div className="text-headline text-bold">
          {I18n.t(`${I18nKey}.${periodName}`)}
        </div>
        {goals.map((goal) => (
          <ProgressGoalDisplay
            key={goal.id}
            goal={goal}
            formatters={formatters}
          />
        ))}
      </div>
    </div>
  );
};

ProgressGoalPeriodList.propTypes = {
  formatters: PropTypes.shape({
    elevationFormatter: PropTypes.object.isRequired,
    timespanFormatter: PropTypes.object.isRequired,
    distanceFormatter: PropTypes.object.isRequired
  }).isRequired,
  periodName: PropTypes.string.isRequired,
  goals: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      goal_type: PropTypes.string,
      activity_types: PropTypes.arrayOf(PropTypes.string),
      period: PropTypes.string,
      threshold: PropTypes.number,
      value: PropTypes.number,
      year_percent_complete: PropTypes.number
    })
  ).isRequired
};

export default ProgressGoalPeriodList;
