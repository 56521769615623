import { snakeCase } from 'lodash-es';
import { FEED_ENTRY_TYPES } from '../../Feed/constants';

export const updateKudosEndpoint = ({ entity }) => {
  return entity === FEED_ENTRY_TYPES.groupActivity
    ? FEED_ENTRY_TYPES.activity
    : snakeCase(entity);
};

export default { updateKudosEndpoint };
