import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Modal, {
  ModalHeroImage,
  ModalTitle,
  ModalBody,
  ModalActions
} from '@strava/ui/Modal';
import Button from '@strava/ui/Button';

import I18n from 'utils/I18n';
import createNetworkingClient from 'utils/networking-client';
import { trackV2 } from 'utils/analytics';

import polylineImage from './assets/start-hidden.png';
import styles from './styles.scss';

const MapVisModal = ({ paths }) => {
  const units = I18n.measurementUnits();
  const i18nPrefix = 'templates.feed.map_visibility_modal.safe_by_default.';
  const modalTitleText = I18n.t(`${i18nPrefix}title`);
  const modalBodyText = I18n.t(`${i18nPrefix}description_${units}`);

  const modalButtonText = I18n.t(`${i18nPrefix}button_primary`);
  const modalButtonEmphasis = 'primary';
  const modalButtonTextSecondary = I18n.t(`${i18nPrefix}button_secondary`);
  const modalButtonSecondaryEmphasis = 'text';

  const trackEventProperties = { cta: 'hide_start_end_opt_out' };
  const trackEvent = (action, element = null, properties = null) => {
    trackV2({
      category: 'privacy_settings',
      page: 'map_visibility_modal',
      action,
      ...(element && { element }),
      ...(properties && { properties })
    });
  };

  useEffect(() => {
    trackEvent('screen_enter', null, trackEventProperties);
  }, []);
  const [isOpen, setIsOpen] = useState(true);
  const closeModal = () => setIsOpen(false);

  const updateImpressionCapping = () => {
    createNetworkingClient().post(paths.dismissModalPath, {
      promo: 'map_visibility_feed_coachmark_or_modal_dismissed'
    });
  };

  const handlePrimarySubmit = (e) => {
    e.preventDefault();
    trackEvent('click', 'looks_good', trackEventProperties);
    updateImpressionCapping();
    closeModal();
  };

  const handleSecondarySubmit = (e) => {
    e.preventDefault();
    trackEvent('click', 'customize', trackEventProperties);
    window.location.href = paths.hideAnywherePath;
    updateImpressionCapping();
    closeModal();
  };

  const dismissModal = () => {
    trackEvent('click', 'dismiss', trackEventProperties);
    updateImpressionCapping();
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={dismissModal}
      hasClose={true}
      className={styles.modal}
      aria-labelledby="map-vis-modal-title"
    >
      <ModalHeroImage
        src={polylineImage}
        alt={I18n.t('templates.feed.map_visibility_modal.modal_image')}
      />
      <ModalTitle className={styles.title} id="map-vis-modal-title">
        {modalTitleText}
      </ModalTitle>
      <ModalBody>
        <div className={styles.description}>
          {modalBodyText}
        </div>
      </ModalBody>
      <ModalActions style={{ justifyContent: 'space-between' }}>
        <Button
          className={styles.button}
          variant={modalButtonEmphasis}
          type="button"
          onClick={handlePrimarySubmit}
        >
          {modalButtonText}
        </Button>
        <Button
          className={styles.button}
          variant={modalButtonSecondaryEmphasis}
          type="button"
          onClick={handleSecondarySubmit}
        >
          {modalButtonTextSecondary}
        </Button>
      </ModalActions>
    </Modal>
  );
};

MapVisModal.propTypes = {
  paths: PropTypes.shape({
    settingsPath: PropTypes.string,
    hideAnywherePath: PropTypes.string,
    dismissModalPath: PropTypes.string
  }).isRequired
};

export default MapVisModal;
