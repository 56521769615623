import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import I18n from 'utils/I18n';
import Avatar from '@strava/ui/Avatar';
import { trackV2 } from 'utils/analytics';
import Cldr from 'utils/Cldr';
import { countries, lookup } from 'country-data';
import ActionsLockClosedNormalSmall from '@strava/icons/ActionsLockClosedNormalSmall';
import useFetchLeaderboardData, {
  STATUS,
  CATEGORIES,
  PREMIUM_CATEGORIES
} from './useFetchLeaderboardData';
import CurrentPlace from './CurrentPlace';
import CategoryTabs from './CategoryTabs';
import LeaderboardsTable from './LeaderboardsTable';
import ProgressBar from '../ProgressBar';

import styles from './styles.scss';

const I18N_PREFIX = 'strava.challenges.challenge_detail.leaderboard';

// Overall tab - subcategory filters
const GENDER_SUB_CATEGORIES = {
  overall: 'overall',
  men: 'men',
  women: 'women'
};

// Age Group - subcategory filters
const AGE_SUB_CATEGORIES = {
  bucket_0_19: '0_19',
  bucket_20_24: '20_24',
  bucket_25_34: '25_34',
  bucket_35_44: '35_44',
  bucket_45_54: '45_54',
  bucket_55_64: '55_64',
  bucket_65_69: '65_69',
  bucket_70_74: '70_74',
  bucket_75_plus: '75_plus'
};

const COUNTRY_SUB_CATEGORIES = countries.all
  .filter((c) => c.alpha3 !== '' && c.status === 'assigned')
  .map((c) => ({
    name: c.name,
    code: c.alpha3
  }))
  .sort((c1, c2) => {
    return c1.name.localeCompare(c2.name);
  });

const DEFAULT_PAGE_LIMIT = 20;

const Leaderboard = ({ currentAthlete, challengeId, limit = DEFAULT_PAGE_LIMIT, joined = false }) => {
  const [params, setParams] = useState({
    category: CATEGORIES.gender,
    sub_category: GENDER_SUB_CATEGORIES.overall,
    limit
  });

  const weightMeasurementUnit =
    (currentAthlete && currentAthlete.weight_measurement_unit) || 'lbs';

  const { data, status, message } = useFetchLeaderboardData({
    challengeId,
    joined,
    params,
    weightMeasurementUnit,
    subscribed: (currentAthlete && currentAthlete.subscribed) || false
  });
  const {
    entries,
    viewingAthletePublicEntry,
    activityType,
    size,
    dimension,
    showActivityCount,
    challengeType,
    isTeamChallenge
  } = data;

  const weightCategories = () => {
    if (weightMeasurementUnit === 'kg') {
      return [
        '0_54_kg',
        '55_64_kg',
        '65_74_kg',
        '75_84_kg',
        '85_94_kg',
        '95_104_kg',
        '105_114_kg',
        '115_plus_kg'
      ];
    }
    return [
      '0_124_lb',
      '125_149_lb',
      '150_164_lb',
      '165_179_lb',
      '180_199_lb',
      '200_224_lb',
      '225_249_lb',
      '250_plus_lb'
    ];
  };

  const defaultClub = currentAthlete && Object.keys(currentAthlete.clubs)[0];

  const trackAnalytics = (elementData, category, subCategory) => {
    trackV2({
      page: 'challenge_details',
      category: 'challenges',
      ...elementData,
      properties: {
        viewing_athlete_id: currentAthlete ? currentAthlete.id : null,
        challenge_id: challengeId,
        leaderboard_category_type: category,
        leaderboard_subcategory_type: subCategory
      }
    });
  };

  useEffect(() => {
    trackAnalytics(
      {
        element: 'challenge_leaderboards',
        action: 'screen_enter'
      },
      params.category,
      params.sub_category
    );
  }, []);

  const currentAthleteCountryISO = () => {
    const country = lookup.countries({ name: currentAthlete.country })[0];
    return country ? country.alpha3 : 'USA';
  };

  const activityCountLabel = () => {
    if (activityType === 'run') return I18n.t(`${I18N_PREFIX}.columns.runs`);
    if (activityType === 'ride') return I18n.t(`${I18N_PREFIX}.columns.rides`);
    return I18n.t(`${I18N_PREFIX}.columns.activities`);
  };

  const dimensionLabel = () => {
    if (dimension === 'elevation_gain' || dimension === 'elevation_loss')
      return I18n.t(`${I18N_PREFIX}.columns.elevation`);
    if (dimension === 'elapsed_time' || dimension === 'moving_time')
      return I18n.t(`${I18N_PREFIX}.columns.time`);
    if (dimension === 'distance')
      return I18n.t(`${I18N_PREFIX}.columns.distance`);
    if (dimension === 'avg_pace_min_distance')
      return I18n.t(`${I18N_PREFIX}.columns.pace`);
    return '';
  };

  // Overall filter is technically not a filter since that is a superset of
  // all the entries. The other filters are a subset.
  const hasFilteredEntrySubset = () => {
    return !(
      params.category === CATEGORIES.gender &&
      params.sub_category === GENDER_SUB_CATEGORIES.overall
    );
  };

  const showDistanceColumn = dimension === 'avg_pace_min_distance';

  const showProgressColumn =
    challengeType !== 'BestActivityChallenge' && !isTeamChallenge;

  const columnHeaders = () => {
    const headers = [
      {
        id: 1,
        label: I18n.t(`${I18N_PREFIX}.columns.overall`),
        styleName: 'overallRank'
      },
      {
        id: 2,
        label: I18n.t(`${I18N_PREFIX}.columns.name`),
        styleName: 'name'
      },
      { id: 4, label: dimensionLabel(), styleName: 'dimension' }
    ];
    if (showProgressColumn) {
      headers.push({
        id: 5,
        label: I18n.t(`${I18N_PREFIX}.columns.progress`),
        styleName: 'progress'
      });
    }

    if (hasFilteredEntrySubset()) {
      headers.splice(1, 0, {
        id: 6,
        label: I18n.t(`${I18N_PREFIX}.columns.place`),
        styleName: 'filteredEntryPlace'
      });
      // Readjust name column
      headers[2].style = styles.nameShort;
    }
    // hide/show activity count column
    if (showActivityCount) {
      headers.splice(hasFilteredEntrySubset() ? 3 : 2, 0, {
        id: 3,
        label: activityCountLabel(),
        styleName: 'activityCount'
      });
    }
    // hide/show extra distance column
    if (showDistanceColumn) {
      headers.splice(hasFilteredEntrySubset() ? 3 : 2, 0, {
        id: 7,
        label: I18n.t(`${I18N_PREFIX}.columns.distance`),
        styleName: 'distance'
      });
    }

    return headers;
  };

  const progressBar = (progressPercentage, progressFraction) => {
    return (
      <div className={styles.progress}>
        <ProgressBar
          className={styles.bar}
          progressBarFraction={progressFraction > 1 ? 1 : progressFraction || 0}
        />
        <div className={styles.percent}>{progressPercentage}</div>
      </div>
    );
  };

  const avatarColumn = (athlete) => (
    <div className={styles.avatarAndNameContainer}>
      <div className={styles.avatar}>
        <Avatar
          badge={
            athlete.memberType === 'premium' ? 'subscriber' : athlete.memberType
          }
          size="small"
          src={athlete.profile}
          type="athlete"
          name={athlete.name}
          href={`/athletes/${athlete.id}`}
        />
      </div>
      <div className={styles.details}>
        <div className={styles.name}>
          <a href={`/athletes/${athlete.id}`}>{athlete.name}</a>
        </div>
        <div className={styles.location}>{athlete.location}</div>
      </div>
    </div>
  );

  const dimensionValueColumn = (entry) => {
    if (entry.activityLink) {
      return <a href={entry.activityLink}>{entry.dimensionValue}</a>;
    }

    return entry.dimensionValue;
  };

  const isViewingAthleteEntry = (entryAthleteId) =>
    currentAthlete && entryAthleteId === currentAthlete.id;

  const extractColumnValuesForEntry = (entry) => {
    const displayRowColumns = [
      {
        id: 1,
        value: (entry && Cldr.formatDecimal(entry.overallRank)) || '...'
      },
      { id: 2, value: (entry && avatarColumn(entry.athlete)) || '...' },
      { id: 4, value: (entry && dimensionValueColumn(entry)) || '...' }
    ];
    if (showProgressColumn) {
      displayRowColumns.push({
        id: 5,
        value:
          (entry &&
            progressBar(entry.progressPercentage, entry.progressFraction)) ||
          '...'
      });
    }
    if (hasFilteredEntrySubset()) {
      displayRowColumns.splice(1, 0, {
        id: 6,
        value: (entry && Cldr.formatDecimal(entry.place)) || '--'
      });
    }
    // hide/show activity count column
    if (showActivityCount) {
      displayRowColumns.splice(hasFilteredEntrySubset() ? 3 : 2, 0, {
        id: 3,
        value: (entry && Cldr.formatDecimal(entry.activityCount)) || '...'
      });
    }
    // hide/show distance column
    if (showDistanceColumn) {
      displayRowColumns.splice(hasFilteredEntrySubset() ? 3 : 2, 0, {
        id: 7,
        value: (entry && entry.formattedDistance) || '--'
      });
    }
    return displayRowColumns;
  };

  const rowsWithDisplayValuesOnly = () => {
    const displayRows = [];
    let viewingAthleteIsOnPage = false;
    entries.forEach((entry, index) => {
      const isViewingAthlete = isViewingAthleteEntry(entry.athlete.id);
      if (isViewingAthlete) {
        viewingAthleteIsOnPage = true;
      }
      const row = {
        id: index,
        highlight: isViewingAthlete
      };
      row.value = extractColumnValuesForEntry(entry);
      displayRows.push(row);
    });
    // Build contextual position row for viewing athlete if:
    // 1. Viewing athlete public entry is present - athlete is participating in challenge
    // 2. Viewing athlete entry does not fall into current page ranking range
    // 3. Page has result rows
    // 4. Viewing athlete entry is on a later page (one of the next-pages)
    if (
      viewingAthletePublicEntry &&
      !viewingAthleteIsOnPage &&
      entries.length > 0 &&
      entries.slice(-1)[0].place < viewingAthletePublicEntry.place
    ) {
      // dummy row
      displayRows.push({
        id: entries.length + 1,
        highlight: false,
        value: extractColumnValuesForEntry()
      });
      displayRows.push({
        id: entries.length + 2,
        highlight: true,
        value: extractColumnValuesForEntry(viewingAthletePublicEntry)
      });
    }
    return displayRows;
  };

  // Builds leaderboard categories and their respective sub-category filter labels
  // This dictionary is used to populate leaderboard tabs and the values in the filter
  // dropdown for that particular category.
  // eg.
  //   { gender: {
  //     title:"Overall",
  //     subCategories:{male: "Male", female: "Female", overall: "Overall" ...}
  //   } }
  const categories = () => {
    const categoriesMap = {};
    // logged out athletes should only see overall tab
    if (currentAthlete) {
      Object.keys(CATEGORIES).forEach((category) => {
        categoriesMap[category] = {
          title: I18n.t(`${I18N_PREFIX}.categories.${category}`),
          premium: PREMIUM_CATEGORIES.includes(category)
        };
      });
    } else {
      categoriesMap[CATEGORIES.gender] = {
        title: I18n.t(`${I18N_PREFIX}.categories.gender`),
        premium: PREMIUM_CATEGORIES.includes(CATEGORIES.gender)
      };
    }
    // populate categories with their respective sub_categories
    Object.keys(categoriesMap).forEach((category) => {
      const subCategoryMap = {};

      switch (category) {
        // Overall tab - subcategoy
        case CATEGORIES.gender:
          Object.keys(GENDER_SUB_CATEGORIES).forEach((subCategory) => {
            subCategoryMap[subCategory] = I18n.t(
              `${I18N_PREFIX}.sub_categories.gender.${subCategory}`
            );
          });
          break;
        // Age tab - subcategory filter options
        case CATEGORIES.age:
          Object.values(AGE_SUB_CATEGORIES).forEach((subCategory) => {
            subCategoryMap[subCategory] = I18n.t(
              `${I18N_PREFIX}.sub_categories.age.${subCategory}`
            );
          });
          break;
        // Country tab - subcategory filter options
        case CATEGORIES.country:
          COUNTRY_SUB_CATEGORIES.forEach((f) => {
            subCategoryMap[f.code] = f.name;
          });
          break;
        // Clubs tab
        case CATEGORIES.club:
          Object.keys(currentAthlete.clubs).forEach((clubId) => {
            subCategoryMap[clubId] = currentAthlete.clubs[clubId];
          });
          break;
        // Weight tab
        case CATEGORIES.weight:
          weightCategories().forEach((weightCategory) => {
            subCategoryMap[weightCategory] = I18n.t(
              `${I18N_PREFIX}.sub_categories.weight.${weightCategory}`
            );
          });
          break;
        default:
      }
      categoriesMap[category].subCategories = subCategoryMap;
    });
    return categoriesMap;
  };

  const handleSubscribeClick = (e) => {
    e.preventDefault();
    trackAnalytics(
      {
        element: 'subscribe_btn',
        action: 'click'
      },
      params.category,
      params.sub_category
    );

    window.location = e.target.href;
  };

  const onNextClick = () => {
    const row = entries.slice(-1)[0];
    setParams((prevParams) => {
      // Remove before from params (can only navigate in one direction)
      const { before, ...rest } = prevParams;
      return {
        ...rest,
        after: {
          rank: row.place,
          value: row.value,
          athlete_id: row.athlete.id
        }
      };
    });
    trackAnalytics(
      {
        element: 'pagination_next_btn',
        action: 'click'
      },
      params.category,
      params.sub_category
    );
  };

  const onPrevClick = () => {
    const row = entries[0];
    setParams((prevParams) => {
      // Remove after from params (can only navigate in one direction)
      const { after, ...rest } = prevParams;
      return {
        ...rest,
        before: {
          rank: row.place,
          value: row.value,
          athlete_id: row.athlete.id
        }
      };
    });
    trackAnalytics(
      {
        element: 'pagination_prev_btn',
        action: 'click'
      },
      params.category,
      params.sub_category
    );
  };

  // Leaderboard tabs [ Overall, I'm Following, Clubs, Country etc... ]
  const onTabChange = (i) => {
    // Reset pagination progress and current filter when tab is changed
    const { before, after, sub_category: _, ...rest } = params;
    const newParams = { ...rest };
    switch (i) {
      case 0:
        newParams.category = CATEGORIES.gender;
        newParams.sub_category = GENDER_SUB_CATEGORIES.overall;
        break;
      case 1:
        // Following tab (has no filters)
        newParams.category = CATEGORIES.following;
        break;
      case 2:
        newParams.category = CATEGORIES.club;
        if (defaultClub) {
          newParams.sub_category = defaultClub;
        }
        break;
      case 3:
        newParams.category = CATEGORIES.country;
        newParams.sub_category = currentAthleteCountryISO();
        break;
      case 4:
        newParams.category = CATEGORIES.age;
        newParams.sub_category = currentAthlete.ageGroup;
        break;
      case 5:
        newParams.category = CATEGORIES.weight;
        newParams.sub_category = currentAthlete.weight_group.concat(
          weightMeasurementUnit === 'kg' ? '_kg' : '_lb'
        );
        break;
      default:
    }

    setParams(newParams);
    trackAnalytics(
      {
        element: 'category_tab',
        action: 'click'
      },
      newParams.category,
      newParams.sub_category
    );
  };

  const onFilterChange = (subCategory) => {
    // Reset pagination progress when filter is changed
    const { before, after, ...rest } = params;
    setParams({
      ...rest,
      sub_category: subCategory
    });
    trackAnalytics(
      { element: 'leaderboard_filter', action: 'click' },
      params.category,
      subCategory
    );
  };

  const tableColumnHeaders = columnHeaders();

  const buildPaginationLabel = () => {
    if (status === STATUS.resolved && entries.length > 0) {
      return I18n.t(`${I18N_PREFIX}.pagination_label`, {
        topRowRank: Cldr.formatDecimal(entries[0].place) || '--',
        bottomRowRank: Cldr.formatDecimal(entries.slice(-1)[0].place) || '--',
        leaderboardSize: Cldr.formatDecimal(size) || '--'
      });
    }
    return `--`;
  };

  const leaderboardData = {
    currentCategory: params.category,
    currentSubcategory: params.sub_category,
    viewingAthleteRank:
      viewingAthletePublicEntry && viewingAthletePublicEntry.place,
    categories: categories(),
    columnHeaders: tableColumnHeaders,
    size,
    currentPlaceLabel: I18n.t(`${I18N_PREFIX}.current_place`),
    status,
    limit: params.limit,
    message,
    resultRows: rowsWithDisplayValuesOnly(),
    isLastPage: entries.length > 0 && entries.slice(-1)[0].place === size,
    isFirstPage: entries.length > 0 && entries[0].place === 1,
    paginationLabel: buildPaginationLabel()
  };

  return (
    <div className={styles.container}>
      {!isTeamChallenge && <CurrentPlace {...leaderboardData} />}
      <div className={styles.privacySection}>
        <ActionsLockClosedNormalSmall />
        <div className={`text-caption2 ${styles.copy}`}>
          {I18n.t(
            `${I18N_PREFIX}.${
              isTeamChallenge ? 'team_privacy_v2' : 'privacy_v3'
            }`
          )}
        </div>
      </div>
      <CategoryTabs
        {...leaderboardData}
        onTabChangeCallback={onTabChange}
        onFilterChangeCallback={onFilterChange}
      />
      <LeaderboardsTable
        {...leaderboardData}
        onNextPageCallback={onNextClick}
        onPrevPageCallback={onPrevClick}
        onSubscribeCallback={handleSubscribeClick}
      />
    </div>
  );
};

Leaderboard.propTypes = {
  currentAthlete: PropTypes.shape({
    id: PropTypes.number,
    ageGroup: PropTypes.string,
    country: PropTypes.string,
    clubs: PropTypes.shape({}),
    weight_measurement_unit: PropTypes.string,
    weight_group: PropTypes.string,
    subscribed: PropTypes.bool
  }),
  challengeId: PropTypes.number.isRequired,
  limit: PropTypes.number,
  joined: PropTypes.bool
};

export default Leaderboard;
export { I18N_PREFIX };
