import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.scss';

import Controls from '../Controls';

function Footer({
  isExpanded,
  isSubmittable,
  settingCollapsed,
  handleValueSave
}) {
  if (isExpanded && isSubmittable) {
    return (
      <div className={styles.controls}>
        <Controls onCancel={settingCollapsed} onOk={handleValueSave} />
      </div>
    );
  }

  if (!isExpanded) {
    return <div className={styles.expand}>&#xfe40;</div>;
  }

  return null;
}

Footer.propTypes = {
  isSubmittable: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  handleValueSave: PropTypes.func.isRequired,
  settingCollapsed: PropTypes.func.isRequired
};

export default Footer;
