import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Slider, Rail, Handles, Tracks } from 'react-compound-slider';
import Button from '@strava/ui/Button';
import I18n from 'utils/I18n';

import Handle from './Handle';
import Track from './Track';

import styles from './styles.scss';

export function getStartBackValue(value, max, step) {
  let x = value - step;
  if (x < max) {
    x = max;
  }
  return x;
}

export function getStartForwardValue(value, min, step) {
  let x = value + step;
  if (min <= x) {
    x = min - 1;
  }
  return x;
}

export function getEndBackValue(value, max, step) {
  let x = value - step;
  if (x <= max) {
    x = max + 1;
  }
  return x;
}

export function getEndForwardValue(value, min, step) {
  let x = value + step;
  if (min < x) {
    x = min;
  }
  return x;
}

const RangeSlider = ({ step = 1, scopeTo, domain, onUpdate = () => {} }) => {
  const [start, setStart] = useState(scopeTo[0]);
  const [end, setEnd] = useState(scopeTo[1]);

  useEffect(() => {
    onUpdate([start, end]);
  }, [start, end]);

  return (
    <>
      <div className={styles.wrapper}>
        <Slider
          mode={2}
          step={1}
          domain={domain}
          onUpdate={(values) => {
            setStart(values[0]);
            setEnd(values[1]);
          }}
          values={[start, end]}
          className={styles.slider}
        >
          <div className={styles.graphicWrap}>
            <div className={styles.rail} />
          </div>

          <Tracks left={false} right={false}>
            {({ tracks, getTrackProps }) => (
              <div className={styles.graphicWrap}>
                {tracks.map(({ id, source, target }) => (
                  <Track
                    key={id}
                    source={source}
                    target={target}
                    getTrackProps={getTrackProps}
                  />
                ))}
              </div>
            )}
          </Tracks>

          {/*
            whe need to use Rail component here so that we can add getRailProps
            which will add mouse interections
          */}
          <Rail>
            {({ getRailProps }) => (
              <div className={styles.graphicWrap}>
                <div className={styles.railHitarea} {...getRailProps()} />
              </div>
            )}
          </Rail>

          <Handles>
            {({ handles, getHandleProps }) => (
              <div className={styles.graphicWrap}>
                <Handle
                  key={handles[0].id}
                  handle={handles[0]}
                  domain={domain}
                  getHandleProps={getHandleProps}
                  style={{
                    backgroundColor: 'rgb(84, 176, 58)'
                  }}
                />
                <Handle
                  key={handles[1].id}
                  handle={handles[1]}
                  domain={domain}
                  getHandleProps={getHandleProps}
                  style={{
                    backgroundColor: '#f00'
                  }}
                />
              </div>
            )}
          </Handles>
        </Slider>
      </div>

      <div className={styles.control}>
        <div>
          <div className={styles.controlLabelStart}>
            {I18n.t('strava.ui.slider.move_start')}
          </div>
          <Button
            onClick={() => setStart(getStartBackValue(start, 0, step))}
            disabled={start === domain[0]}
          >
            {I18n.t('strava.ui.slider.back')}
          </Button>
          <Button
            onClick={() => setStart(getStartForwardValue(start, end, step))}
          >
            {I18n.t('strava.ui.slider.forward')}
          </Button>
        </div>
        <div>
          <div className={styles.controlLabelEnd}>
            {I18n.t('strava.ui.slider.move_end')}
          </div>
          <Button onClick={() => setEnd(getEndBackValue(end, start, step))}>
            {I18n.t('strava.ui.slider.back')}
          </Button>
          <Button
            onClick={() => setEnd(getEndForwardValue(end, domain[1], step))}
            disabled={end === domain[domain.length - 1]}
          >
            {I18n.t('strava.ui.slider.forward')}
          </Button>
        </div>
      </div>
    </>
  );
};

RangeSlider.propTypes = {
  step: PropTypes.number,
  scopeTo: PropTypes.arrayOf(Number).isRequired,
  domain: PropTypes.arrayOf(Number).isRequired,
  onUpdate: PropTypes.func
};

export default RangeSlider;
