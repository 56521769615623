import React from 'react';
import PropTypes from 'prop-types';

import Video from 'components/Video';

function Poc({ media, onReady = () => {}, options = {}, className }) {
  const { video, large } = media;

  return (
    // TODO: Placeholder for enhanced tag?
    <Video
      options={{
        autoplay: false,
        controls: true,
        responsive: true,
        fluid: true,
        sources: [
          {
            src: video,
            type: 'application/x-mpegURL'
          }
        ],
        poster: large,
        ...options
      }}
      className={className}
      onReady={onReady}
    />
  );
}

Poc.propTypes = {
  media: PropTypes.shape({
    photo_id: PropTypes.string,
    video: PropTypes.string,
    type: PropTypes.string,
    large: PropTypes.string
  }).isRequired,
  onReady: PropTypes.func,
  options: PropTypes.shape({}),
  className: PropTypes.string
};

export default Poc;
