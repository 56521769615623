import React from 'react';
import PropTypes from 'prop-types';
import Stats from '@strava/ui/Stats';
import Stat from '@strava/ui/Stat';

import I18n from 'utils/I18n';
import WeekChart from './WeekChart';

import styles from './styles.scss';

const I18nPrefix = 'strava.relative_effort.week_insight_stats';

const WeekInsightStats = ({ cumulativeScore, dailyScores, buckets }) => (
  <div className={styles.container}>
    <WeekChart dailyScores={dailyScores} />
    <Stats listClassName={styles.stats}>
      <Stat
        value={cumulativeScore}
        label={I18n.t(`${I18nPrefix}.score`)}
        wrapperClassName={styles.stat}
        valueClassName={styles.statValue}
        labelClassName={styles.statLabel}
      />
      <Stat
        value={`${Math.round(buckets[0])}-${Math.round(buckets[2])}`}
        label={I18n.t(`${I18nPrefix}.range`)}
        wrapperClassName={styles.stat}
        valueClassName={styles.statValue}
        labelClassName={styles.statLabel}
      />
    </Stats>
  </div>
);

WeekInsightStats.propTypes = {
  cumulativeScore: PropTypes.number.isRequired,
  dailyScores: PropTypes.arrayOf(PropTypes.number).isRequired,
  buckets: PropTypes.arrayOf(PropTypes.number).isRequired
};

export default WeekInsightStats;
