import React, { useState } from 'react';
import I18n from 'utils/I18n';
import PropTypes from 'prop-types';
import ActionsEditNormalSmall from '@strava/icons/ActionsEditNormalSmall';
import Popup from '@strava/ui/Popup';
import Button from '@strava/ui/Button';

import styles from './EditActivityButton.scss';

function EditActivityButton({ link, title, ariaLabel, viewFeatureEdData }) {
  const [showFeatureEdPopup, setShowFeatureEdPopup] = useState(true);
  const { count } = viewFeatureEdData;
  const canViewFeatureEd = count < 4;

  return (
    <Popup
      contents={
        <>
          <div className={styles.container}>
            <div className={styles.text}>
              {I18n.t(`templates.activities.edit.feature_education.new_media`)}
            </div>
            <Button
              type="submit"
              variant="primary"
              id="got-it-button"
              onClick={() => {
                setShowFeatureEdPopup(false);
              }}
              className={styles.gotItButton}
            >
              {I18n.t(`templates.activities.edit.feature_education.got_it`)}
            </Button>
          </div>
        </>
      }
      className={styles.popup}
      contentsClassName={styles.contents}
      arrowClassName={styles.arrow}
      position="bottom"
      isOpenOverride={canViewFeatureEd ? showFeatureEdPopup : false}
      canClick={false}
      offset={[38, 0]}
      flip={false}
    >
      <a
        href={link}
        className={styles.button}
        aria-label={ariaLabel}
        title={title}
      >
        <ActionsEditNormalSmall />
      </a>
    </Popup>
  );
}

EditActivityButton.propTypes = {
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  viewFeatureEdData: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
export default EditActivityButton;
