import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'utils/I18n';
import Popup from '@strava/ui/Popup';
import Analytics from 'utils/analytics';

import Button from '@strava/ui/Button';
import styles from './styles.scss';

const trackClick = (id, element) => {
  Analytics.trackV2({
    action: 'click',
    element,
    category: 'side_bar',
    page: 'segment_details',
    properties: { segment_id: id }
  });
};

// This is hack to reuse the legacy modal for setting goals.
const onClickSetGoal = (segmentId) => {
  trackClick(segmentId, 'set_goal');
  document.getElementById('set_goal_button').click();
};

const urlLabelWithCaret = (label) => {
  return (
    <div className={styles.linkWithCaret}>
      <div>{label}</div>
      <div
        className={`${styles.urlCaret} app-icon icon-caret-right icon-dark icon-sm`}
      />
    </div>
  );
};

const upcomingGoalDescription = (upcomingGoal, segmentId) => {
  return (
    <div>
      <a
        className={styles.urlLabel}
        href="/athlete/goals"
        onClick={() => {
          trackClick(segmentId, 'all_goals');
        }}
      >
        {urlLabelWithCaret(
          I18n.t('strava.performance_goals.upcoming_segment_goal')
        )}
      </a>
      <div className={styles.upcomingSegmentGoal}>
        <a
          href={`/goals/segment/${upcomingGoal.id}`}
          onClick={() => {
            trackClick(segmentId, 'upcoming_goal');
          }}
        >
          {I18n.t('templates.performance_goals.upcoming_segment_goal.details', {
            target_time: upcomingGoal.targetTime,
            segment_name: upcomingGoal.segmentName
          })}
        </a>
        <span className="subtle"> {upcomingGoal.endDate}</span>
      </div>
    </div>
  );
};

const sectionUrlAndLabel = (entry) => {
  let content;
  if (entry.useHamlGoalCreationModule) {
    if (entry.athleteRestricted) {
      content = (
        <Popup
          position="top"
          contents={
            <>
              <div className="tooltip-block centered text-center">
                <div className="app-icon icon-badge-premium icon-sm mb-xs" />
                <p>
                  {I18n.t(
                    'templates.segments.analysis.side_bar.subscribe_copy'
                  )}
                </p>
                <Button
                  variant="primary"
                  onClick={() => {
                    trackClick(entry.segmentId, 'subscribe');
                    window.location.href = '/subscribe?origin=segment_goal';
                    return false;
                  }}
                >
                  {I18n.t('templates.segments.analysis.side_bar.subscribe')}
                </Button>
              </div>
            </>
          }
        >
          <div className={`${styles.urlLabel} ${styles.notAllowed}`}>
            {urlLabelWithCaret(entry.urlLabel)}
          </div>
        </Popup>
      );
    } else {
      content = (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          className={styles.urlLabel}
          href="#"
          onClick={() => onClickSetGoal(entry.segmentId)}
        >
          {urlLabelWithCaret(entry.urlLabel)}
        </a>
      );
    }
  } else {
    content = (
      <a
        className={styles.urlLabel}
        href={entry.url}
        onClick={() => {
          trackClick(entry.segmentId, entry.section);
        }}
      >
        {urlLabelWithCaret(entry.urlLabel)}
      </a>
    );
  }
  return content;
};

const CardActionsSection = ({ entry }) => {
  if (entry.segmentNotEligible) {
    return (
      <div
        className="light"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: entry.segmentNotEligible }}
      />
    );
  }
  if (entry.upcomingGoal) {
    return upcomingGoalDescription(entry.upcomingGoal, entry.segmentId);
  }
  return sectionUrlAndLabel(entry);
};

CardActionsSection.propTypes = {
  entry: PropTypes.shape({}).isRequired
};

export default CardActionsSection;
