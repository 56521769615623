import React from 'react';

import I18n from 'utils/I18n';
import InfoModal from '../../../components/InfoModal/InfoModal';

import styles from './styles.scss';

const HideStatsInfo = () => {
  return (
    <InfoModal>
      <div className={styles.modalHeader}>
        {I18n.t('templates.activities.edit.hide_stats.modal.title_v2')}
      </div>
      <p>{I18n.t('templates.activities.edit.hide_stats.modal.text_1')}</p>
      <p>{I18n.t('templates.activities.edit.hide_stats.modal.text_2')}</p>
    </InfoModal>
  );
};

export default HideStatsInfo;
