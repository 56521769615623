import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import ButtonCTAWithCancel from 'components/ButtonCTAWithCancel';
import OptionList from '../OptionList';

import styles from './styles.scss';

const SINGLE_SELECT = 'single_select';
const MULTI_SELECT = 'multi_select';

function SurveyStep({
  survey_key: surveyKey,
  screen_name: screenName,
  title,
  subtitle,
  questions,
  question_type: questionType,
  button: ctaText,
  freeform_input: freeformInput,
  isSubmitting = false,
  handleCancel: handleCancelProp,
  handleNext
}) {
  const [selectedValues, setSelectedValues] = useState([]);
  const [freeformValue, setFreeformValue] = useState('');
  const canSubmit =
    selectedValues.length !== 0 || freeformValue.trim().length !== 0;

  const selections = useMemo(() => {
    const obj = {};
    questions.forEach((q) => {
      obj[q.type] = selectedValues.indexOf(q.type) !== -1;
    });
    return obj;
  }, [selectedValues]);

  const handleCTA = useCallback(() => {
    const data = { surveyKey, selections };

    if (freeformValue.length !== 0) {
      data.freeformText = freeformValue;
    }

    handleNext(data);
  }, [selections, freeformValue]);

  const handleCancel = useCallback(() => {
    const data = { surveyKey, selections };

    if (freeformValue.length !== 0) {
      data.freeformText = freeformValue;
    }

    handleCancelProp(data);
  }, [selections, freeformValue]);

  return (
    <>
      <h2 className="text-title1 text-book mb-0">{screenName}</h2>
      <hr className="mt-lg mb-lg" style={{ borderColor: '#d5d5d5' }} />
      <h3 className="text-title2 mt-0 mb-lg">{title}</h3>
      {subtitle && <p className="text-footnote mb-lg">{subtitle}</p>}

      <OptionList
        type={questionType}
        options={questions.map(({ type, text }) => ({
          value: type,
          text
        }))}
        selectedValues={selectedValues}
        disabled={isSubmitting}
        onChange={(values) => setSelectedValues(values)}
      />

      {freeformInput && (
        <div className={styles.freeformInput}>
          <div>
            <label htmlFor="freeform-input" className="text-book">
              {freeformInput.title}
            </label>
          </div>
          <textarea
            id="freeform-input"
            placeholder={freeformInput.placeholder}
            maxLength={freeformInput.max_characters}
            rows={5}
            value={freeformValue}
            onChange={(e) => setFreeformValue(e.target.value)}
          />
        </div>
      )}

      <div className="mt-xl" style={{ display: 'inline-block' }}>
        <ButtonCTAWithCancel
          onCancel={handleCancel}
          onCTA={handleCTA}
          ctaVariant="custom"
          ctaText={ctaText}
          ctaDisabled={!canSubmit}
          disabled={isSubmitting}
        />
      </div>
    </>
  );
}

SurveyStep.propTypes = {
  survey_key: PropTypes.string.isRequired,
  screen_name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    })
  ).isRequired,
  question_type: PropTypes.oneOf([SINGLE_SELECT, MULTI_SELECT]).isRequired,
  button: PropTypes.string.isRequired,
  freeform_input: PropTypes.shape({
    max_characters: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired
  }),

  isSubmitting: PropTypes.bool,
  handleCancel: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired
};

export default SurveyStep;
