import React from 'react';
import PropTypes from 'prop-types';
import { scaleLinear } from 'd3-scale';
import { max } from 'd3-array';
import Cldr from 'utils/Cldr';

import styles from './styles.scss';

const weekdayNames = (key) => {
  return Cldr.calendar.weekdays({
    format: 'stand-alone',
    names_form: 'narrow'
  })[key];
};

const WEEKDAY_KEYS = {
  0: 'mon',
  1: 'tue',
  2: 'wed',
  3: 'thu',
  4: 'fri',
  5: 'sat',
  6: 'sun'
};

const WeekChart = ({ dailyScores }) => {
  const m = max(dailyScores);
  const scale = scaleLinear()
    .domain([0, m === 0 ? 100 : m])
    .range([0, 100]);

  const barData = dailyScores.map((v, i) => ({
    x: v,
    w: weekdayNames(WEEKDAY_KEYS[i])
  }));

  return (
    <div className={`mt-xs ${styles.container}`}>
      <div className={styles.barChart}>
        {barData.map((d, i) => (
          <div key={`bar-${WEEKDAY_KEYS[i]}`} className={styles.bar}>
            <span style={{ height: `${scale(d.x)}%` }} />
          </div>
        ))}
      </div>

      <div className={styles.xAxis}>
        {barData.map((d, i) => (
          <div key={`xAxis-${WEEKDAY_KEYS[i]}`} className={styles.tick}>
            {d.w}
          </div>
        ))}
      </div>
    </div>
  );
};

WeekChart.propTypes = {
  dailyScores: PropTypes.arrayOf(PropTypes.number).isRequired
};

export default WeekChart;
