import React from 'react';
import PropTypes from 'prop-types';

import I18n from 'utils/I18n';
import ElevationChart from '@strava/ui/ElevationChart';
import RangeSelectMap from 'components/RangeSelectMap';
import RangeSlider from 'components/RangeSlider';

// Stylesheets
import { fetchStreams } from './api';

class Truncate extends React.Component {
  state = {
    activityLatlng: [],
    cropLatlng: [],
    scopeTo: null,
    altitude: [],
    distance: []
  };

  async componentDidMount() {
    const { activityId, startIndex, endIndex } = this.props;

    try {
      const { altitude, distance, latlng } = await fetchStreams({
        id: activityId
      });

      const scopeTo = [
        startIndex === null ? 0 : startIndex,
        endIndex === null ? parseInt(latlng.length / 2, 10) : endIndex
      ];

      this.setState({
        scopeTo,
        activityLatlng: latlng,
        cropLatlng: latlng.slice(scopeTo[0], scopeTo[1] + 1),
        altitude,
        distance
      });
    } catch (error) {
      // TODO: show some error to the user
      console.error(error);
    }
  }

  handleRangeSliderChange = (values) => {
    const { activityLatlng } = this.state;
    const [startIndex, endIndex] = values;

    document.getElementById('start-index').value = startIndex;
    document.getElementById('end-index').value = endIndex;

    this.setState({
      scopeTo: values,
      cropLatlng: activityLatlng.slice(startIndex, endIndex + 1)
    });
  };

  render() {
    const { unitSystem, privacyZones = [] } = this.props;
    const {
      scopeTo,
      activityLatlng,
      cropLatlng,
      altitude,
      distance
    } = this.state;

    if (activityLatlng.length === 0) {
      return null;
    }

    return (
      <>
        <div className="mb-md">
          <RangeSlider
            domain={[0, activityLatlng.length - 1]}
            scopeTo={scopeTo}
            onUpdate={this.handleRangeSliderChange}
          />
        </div>
        <div className="mb-md">
          <RangeSelectMap
            primaryLatLng={activityLatlng}
            secondaryLatlng={cropLatlng}
            fitBounds={cropLatlng}
            privacyZones={privacyZones}
            checkLatlngChanges={true}
          />
        </div>
        <div>
          <ElevationChart
            height={220}
            units={unitSystem}
            elevation={altitude}
            distance={distance}
            scopeTo={scopeTo}
            scopeColor="#fc5200"
            formatters={{
              ElevationFormatter(units) {
                return I18n.elevationFormatter(units);
              },
              DistanceFormatter(units) {
                return I18n.distanceFormatter(units);
              },
              PercentFormatter(units) {
                return I18n.percentFormatter(units);
              }
            }}
          />
        </div>
      </>
    );
  }
}

Truncate.propTypes = {
  activityId: PropTypes.number.isRequired,
  unitSystem: PropTypes.string.isRequired,
  privacyZones: PropTypes.arrayOf(
    PropTypes.shape({
      position: PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired
      }).isRequired,
      radius: PropTypes.number.isRequired
    })
  ),
  startIndex: PropTypes.number,
  endIndex: PropTypes.number
};

export default Truncate;
