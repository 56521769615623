import createNetworkingClient from 'utils/networking-client';

export const types = {
  VALUE_CHANGED: 'VALUE_CHANGED',
  VALUE_SAVED_FAILURE: 'VALUE_SAVED_FAILURE',
  VALUE_SAVED_REQUESTED: 'VALUE_SAVED_REQUESTED',
  VALUE_SAVED_SUCCESS: 'VALUE_SAVED_SUCCESS'
};

const valueSavedFailure = (partnerName, partnerType) => ({
  type: types.VALUE_SAVED_FAILURE,
  partnerName,
  partnerType
});

const valueSavedRequested = (partnerName, partnerType) => ({
  type: types.VALUE_SAVED_REQUESTED,
  partnerName,
  partnerType
});

const valueSavedSuccess = (partnerName, partnerType) => ({
  type: types.VALUE_SAVED_SUCCESS,
  partnerName,
  partnerType
});

export const valueChanged = ({
  athleteId,
  optOutName,
  partnerName,
  partnerType,
  value
}) => (dispatch) => {
  dispatch(valueSavedRequested(partnerName, partnerType));
  const url = `/athletes/${athleteId}`;
  const payload = {
    athlete: {
      partner_opt_outs: [
        {
          opt_out_name: optOutName,
          value
        }
      ]
    }
  };

  const instance = createNetworkingClient();
  return instance
    .put(url, payload, { headers: { Accept: 'text/javascript' } })
    .then(() => dispatch(valueSavedSuccess(partnerName, partnerType)))
    .catch(() => dispatch(valueSavedFailure(partnerName, partnerType)));
};

export default types;
