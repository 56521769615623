import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import I18n from 'utils/I18n';
import ButtonCTAWithCancel from 'components/ButtonCTAWithCancel';

import styles from './index.scss';

const I18nPrefix = 'strava.settings.edit_past_activities.';
const I18nKey = {
  title: `${I18nPrefix}summary.title`,
  detailHtml: `${I18nPrefix}summary.detail_html`,
  updating: `${I18nPrefix}summary.updating`
};

export function Summary({
  isSubmitted,
  isSubmitting,
  handleCancel,
  handleSubmit,
  summary,
  trackScreenEnter,
  trackScreenExit,
  history
}) {
  useEffect(() => {
    trackScreenEnter();
    return trackScreenExit;
  }, [history]);

  const attributes = Object.keys(summary);

  if (attributes.length === 0) {
    handleCancel();
    return null;
  }

  return (
    <>
      <h2>{I18n.t(I18nKey.title)}</h2>
      <p>{I18n.t_html(I18nKey.detailHtml)}</p>
      <hr />
      <table className={styles.summaryTable}>
        <tbody>
          {attributes.map((attr) => (
            <tr key={attr}>
              <td className={styles.summaryField}>{summary[attr].title}</td>
              <td className={styles.summaryUpdate}>
                {isSubmitted ? I18n.t(I18nKey.updating) : summary[attr].changes}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <hr />
      {!isSubmitted && (
        <ButtonCTAWithCancel
          onCancel={handleCancel}
          onCTA={handleSubmit}
          ctaDisabled={isSubmitting}
          ctaVariant="next"
        />
      )}
    </>
  );
}

Summary.propTypes = {
  isSubmitted: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  trackScreenEnter: PropTypes.func.isRequired,
  trackScreenExit: PropTypes.func.isRequired,
  summary: PropTypes.objectOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      changes: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
        .isRequired
    })
  ).isRequired,

  // withRouter
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(Summary);
