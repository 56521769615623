import createNetworkingClient from 'utils/networking-client';
import { logError } from '../../../utils/sentry';
import { ADD_KUDO } from '../KudosCommentsAndAchievements/kudosAndComments.actions';

const onClickKudo = ({
  entityEndpoint,
  entityId,
  dispatch,
  onClickKudoSuccessful
}) => {
  createNetworkingClient()
    .post(`/feed/${entityEndpoint}/${entityId}/kudo`)
    .then((response) => {
      if (response && response.status === 200) {
        onClickKudoSuccessful();
      }
    })
    .then(() => {
      dispatch({ type: ADD_KUDO });
    })
    .catch((e) => {
      logError(e);
    });
};

export default onClickKudo;
