import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import cx from '@strava/ui/clsx';
import ActionsVideoThumbnailNormalSmall from '@strava/icons/ActionsVideoThumbnailNormalSmall';

import StravaMediaLightbox from 'components/StravaMediaLightbox';
import { trackV2 } from 'utils/analytics';

import styles from './AthleteProfileHeaderMediaGrid.scss';

function AthleteProfileHeaderMediaGrid({
  isLoggedIn,
  items = [],
  profileType
}) {
  const [lightboxState, setLightboxState] = useState({
    isOpen: false,
    selectedIndex: 0
  });

  // Analytics for screen enter/exit
  useEffect(() => {
    if (items.length === 0) {
      return;
    }
    const firstItem = items[0];
    const data = {
      category: 'profile',
      page: profileType,
      element: 'header_media',
      properties: {
        athlete_id: firstItem.viewing_athlete_id,
        requested_athlete_id: firstItem.owner_id
      }
    };

    trackV2({
      ...data,
      action: 'screen_enter'
    });

    window.addEventListener('beforeunload', function() {
      trackV2({
        ...data,
        action: 'screen_exit'
      });
    });
  }, []);

  function handleItemClick(index) {
    setLightboxState({ isOpen: true, selectedIndex: index });
  }

  function getButtonProps(index) {
    if (isLoggedIn) {
      return {
        role: 'button',
        className: styles.button,
        onClick: () => handleItemClick(index),
        onKeyPress: () => handleItemClick(index),
        tabIndex: '0'
      };
    }

    return {
      className: styles.buttonLoggedOut
    };
  }

  const listClassName = (() => {
    if (items.length < 4) {
      return styles.threeUp;
    }
    if (items.length < 5) {
      return styles.fourUp;
    }
    if (items.length < 6) {
      return styles.fiveUp;
    }
    return styles.sixUp;
  })();

  const onMediaClick = (media) => {
    const data = {
      category: 'profile',
      page: profileType,
      action: 'click',
      properties: {
        athlete_id: media.viewing_athlete_id,
        requested_athlete_id: media.owner_id
      }
    };

    const isImage = media.media_type === 1;
    if (isImage) {
      data.properties.image_id = media.photo_id;
    } else {
      data.properties.video_id = media.photo_id;
    }

    trackV2({
      ...data,
      action: 'click',
      element: media.media_type === 1 ? 'header_image' : 'header_video'
    });
  };

  if (items.length === 0) {
    return null;
  }

  return (
    <>
      <ul className={cx(styles.list, listClassName)}>
        {items.map((item, index) => (
          <li key={item.photo_id} className={styles.listItem}>
            <div {...getButtonProps(index)}>
              <div
                className={styles.image}
                style={{ backgroundImage: `url(${item.large})` }}
              />
              {item.media_type === 2 ? (
                <div
                  className={styles.videoIndicator}
                  data-testid="video-indicator"
                >
                  <ActionsVideoThumbnailNormalSmall />
                </div>
              ) : null}
            </div>
          </li>
        ))}
      </ul>

      {lightboxState.isOpen ? (
        <StravaMediaLightbox
          selectedPhotoIndex={lightboxState.selectedIndex}
          photoList={items}
          onMount={() => {
            onMediaClick(items[lightboxState.selectedIndex]);
          }}
          onCloseRequest={() => {
            setLightboxState({ isOpen: false });
          }}
          analyticProps={{
            page: profileType,
            feedType: profileType,
            entityId: items[lightboxState.selectedIndex].activity_id
          }}
        />
      ) : null}
    </>
  );
}

AthleteProfileHeaderMediaGrid.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      photo_id: PropTypes.string.isRequired,
      large: PropTypes.string.isRequired,
      thumbnail: PropTypes.string.isRequired,
      viewing_athlete_id: PropTypes.string.isRequired,
      owner_id: PropTypes.string.isRequired
    })
  ),
  profileType: PropTypes.string.isRequired
};

export default AthleteProfileHeaderMediaGrid;
