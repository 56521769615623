import React from 'react';
import PropTypes from 'prop-types';

import I18n from 'utils/I18n';
import createNetworkingClient from 'utils/networking-client';

import styles from './styles.scss';
import LoadingState from './components/LoadingState';
import Disclaimer from './components/Disclaimer';
import NoAccess from './components/NoAccess';
import LoadingError from './components/LoadingError';
import WeeklySummary from './components/WeeklySummary';

export const I18nPrefix = 'strava.relative_effort';

class ProgressGoalsRelativeEffortTab extends React.Component {
  state = {
    isLoading: true,
    hasLoadingError: false,
    disclaimerAgreed: false,
    weeklyScores: [],
    defaultWeekIndex: -1,
    selectedWeekIndex: -1
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { athleteId } = this.props;

    createNetworkingClient()
      .get(`/athletes/${athleteId}/relative_effort`, {
        params: {
          num_weeks: 7
        }
      })
      .then((response) => {
        if (response && response.status === 200) {
          this.handleFetchComplete(response.data);
        } else {
          this.handleFetchError();
        }
      })
      .catch(() => {
        this.handleFetchError();
      });
  };

  handleFetchError = () => {
    this.setState({ hasLoadingError: true });
  };

  handleFetchComplete = (data) => {
    const weeklyScores = data.weekly_scores.reverse();
    const defaultWeekIndex = weeklyScores.length - data.selected_week_index - 1;

    this.setState({
      isLoading: false,
      hasLoadingError: false,
      disclaimerAgreed: data.disclaimer_agreed,
      weeklyScores,
      defaultWeekIndex,
      selectedWeekIndex: defaultWeekIndex
    });
  };

  handleFetchRetry = () => {
    this.setState({ hasLoadingError: false, isLoading: true }, () => {
      this.fetchData();
    });
  };

  handleDisclaimerContinueClick = () => {
    const { athleteId } = this.props;

    createNetworkingClient().post(
      `/athletes/${athleteId}/relative_effort_disclaimer_agreed`
    );

    this.setState({ disclaimerAgreed: true });
  };

  handleSelectedWeekIndexUpdate = (index) => {
    this.setState({ selectedWeekIndex: index });
  };

  renderContent = () => {
    const {
      disclaimerAgreed,
      weeklyScores,
      defaultWeekIndex,
      selectedWeekIndex,
      isLoading
    } = this.state;
    const { hasAccess, subscriptionUrl, isTrialEligible } = this.props;

    if (isLoading) {
      return <LoadingState hasAccess={hasAccess} />;
    }

    if (!disclaimerAgreed) {
      return (
        <Disclaimer onContinueClick={this.handleDisclaimerContinueClick} />
      );
    }

    if (hasAccess) {
      return (
        <WeeklySummary
          weeklyScores={weeklyScores}
          defaultWeekIndex={defaultWeekIndex}
          selectedWeekIndex={selectedWeekIndex}
          onSelectedWeekIndexUpdate={this.handleSelectedWeekIndexUpdate}
        />
      );
    }

    // Grab previous weeks data, this is per spec and same as on mobile apps
    const data = weeklyScores[weeklyScores.length - 2];

    return (
      <NoAccess
        cumulativeScore={data.cumulative_score}
        buckets={data.buckets}
        subscriptionUrl={subscriptionUrl}
        isTrialEligible={isTrialEligible}
      />
    );
  };

  render() {
    const { hasLoadingError } = this.state;

    if (hasLoadingError) {
      return (
        <div className={styles.container}>
          <LoadingError handleRetry={this.handleFetchRetry} />
        </div>
      );
    }

    return (
      <div className={styles.container}>
        <div className={styles.head}>
          <div className="text-caption2">
            <div className="app-icon-wrapper">
              <div className={`app-icon icon-premium ${styles.summitIcon}`} />
            </div>
            {I18n.t(`${I18nPrefix}.title_uppercase`)}
          </div>
          <div
            // .help.new-version here is needed here so that jQuery code can
            // pickup the click event and show the glossary modal
            className="help new-version"
            data-glossary-term="definition-suffer-score"
          >
            <span className="app-icon-wrapper">
              <span
                className={`app-icon icon-info-xsmall ${styles.infoIcon}`}
              />
            </span>
          </div>
        </div>
        {this.renderContent()}
      </div>
    );
  }
}

ProgressGoalsRelativeEffortTab.propTypes = {
  hasAccess: PropTypes.bool.isRequired,
  athleteId: PropTypes.number.isRequired,
  subscriptionUrl: PropTypes.string.isRequired,
  isTrialEligible: PropTypes.bool.isRequired
};

export default ProgressGoalsRelativeEffortTab;
