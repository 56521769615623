import createNetworkingClient from 'utils/networking-client';

const SOURCE_TYPES = {
  ACTIVITY: 'activity',
  SEGMENT: 'segment'
};

export async function fetchStreams({ source, id }) {
  let uri;

  if (source === SOURCE_TYPES.ACTIVITY) {
    uri = `/activities/${id}/streams`;
  } else if (source === SOURCE_TYPES.SEGMENT) {
    uri = `/stream/segments/${id}?backing=true`;
  }

  if (!uri) {
    throw new Error(`Source: ${source} is not supported`);
  }

  const response = await createNetworkingClient({ timeout: 30 * 1000 }).get(
    uri,
    {
      params: {
        stream_types: ['latlng', 'altitude', 'distance']
      }
    }
  );

  if (!response || response.status !== 200) {
    throw new Error('Request failed');
  }

  return response.data;
}

export async function checkSegment({ origin, id, startIndex, endIndex }) {
  const response = await createNetworkingClient({ timeout: 30 * 1000 }).get(
    '/publishes/check_segment',
    {
      params: {
        origin,
        id,
        startIndex,
        endIndex
      }
    }
  );

  if (!response || response.status !== 200) {
    throw new Error('Request failed');
  }

  return response.data;
}

export async function getSegmentStreamById(id) {
  const response = await createNetworkingClient().get(
    `/stream/segments/${id}?map`
  );

  if (!response || response.status !== 200) {
    throw new Error('Request failed');
  }

  return response.data;
}

export default {
  fetchStreams
};
