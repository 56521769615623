const calculateProgressFill = (progress, limit, min = 0.0, max = 1.0) => {
  // If a goal is near but not completed, cap the maximum percentage
  // displayed to the `incompleteProgressLimit` to provide obvious visual
  // cue that a goal is incomplete
  if (progress > limit && progress > min && progress < max) {
    return limit;
  }
  return Math.max(Math.min(progress, max), min);
};

export default calculateProgressFill;
