import React from 'react';
import PropTypes from 'prop-types';
import Modal, { ModalBody, ModalActions } from '@strava/ui/Modal';
import Button from '@strava/ui/Button';
import createNetworkingClient from 'utils/networking-client';
import I18n from 'utils/I18n';
import { logError } from '../../../../utils/sentry';

const prefix = 'templates.discussions';

const EntityDeleteAlertDialog = ({
  entityId,
  isDeleteModalOpen,
  setIsDeleteModalOpen,
  entityParentType,
  clubOrAthleteId
}) => {
  const redirectionURL =
    entityParentType === 'Club'
      ? `/clubs/${clubOrAthleteId}/discussion`
      : `/athletes/${clubOrAthleteId}`;

  // We only allow posts to be deleted for now
  const deletePost = () => {
    createNetworkingClient()
      .delete(`/posts/${entityId}`)
      .then(() => {
        setIsDeleteModalOpen(false);
        window.location.assign(redirectionURL);
      })
      .catch((e) => {
        logError(e);
      });
  };

  return (
    <>
      <Modal
        isOpen={isDeleteModalOpen}
        onDismiss={() => setIsDeleteModalOpen(false)}
        aria-label={I18n.t(`${prefix}.post_view.confirm_delete_post`)}
      >
        <ModalBody>
          {I18n.t(`${prefix}.post_view.confirm_delete_post`)}
        </ModalBody>
        <ModalActions>
          <Button
            onClick={() => setIsDeleteModalOpen(false)}
            data-testid="cancel-delete-post-btn"
            type="button"
          >
            {I18n.t('components.button_cta_with_cancel.cancel')}
          </Button>
          <Button
            variant="primary"
            onClick={deletePost}
            type="button"
            data-testid="confirm-delete-post-btn"
          >
            {I18n.t('components.button_cta_with_cancel.ok')}
          </Button>
        </ModalActions>
      </Modal>
    </>
  );
};

EntityDeleteAlertDialog.propTypes = {
  entityId: PropTypes.string.isRequired,
  isDeleteModalOpen: PropTypes.bool.isRequired,
  setIsDeleteModalOpen: PropTypes.func.isRequired,
  entityParentType: PropTypes.string.isRequired,
  clubOrAthleteId: PropTypes.number.isRequired
};

export default EntityDeleteAlertDialog;
