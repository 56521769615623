import React from 'react';
import PropTypes from 'prop-types';
import { arc } from 'd3-shape';
import styles from './styles.scss';
import calculateProgressFill from '../utils';

const CircularProgressChart = ({
  children,
  progress = 0.0,
  canvasWidth,
  canvasHeight,
  iconProportion = 0.4,
  outerCircleProportion = 0.48,
  innerCircleProportion = 0.45, // 0.9375 * outerCircleProportion
  incompleteProgressLimit = 0.98
}) => {
  const tau = 2 * Math.PI;
  const outerRadius = canvasWidth * outerCircleProportion;
  const innerRadius = canvasWidth * innerCircleProportion;

  const hasProgress = progress > 0.0;

  const iconWidth = canvasWidth * iconProportion;
  const iconHeight = canvasHeight * iconProportion;

  const progressAngle = Math.max(
    calculateProgressFill(progress, incompleteProgressLimit) * tau,
    1.0
  );

  const backgroundArc = arc()
    .innerRadius(innerRadius)
    .outerRadius(outerRadius)
    .startAngle(0)
    .endAngle(tau);

  const progressArc = arc()
    .innerRadius(innerRadius)
    .outerRadius(outerRadius)
    .startAngle(0)
    .endAngle(progressAngle);

  const icon = React.cloneElement(React.Children.only(children), {
    x: -iconWidth / 2.0,
    y: -iconHeight / 2.0,
    height: iconHeight,
    width: iconWidth
  });

  return (
    <div>
      <svg width={canvasWidth} height={canvasHeight}>
        <g transform={`translate(${canvasWidth / 2},${canvasHeight / 2})`}>
          <path
            d={backgroundArc()}
            className={styles.progressCircleBackground}
          />
          {icon}
          {hasProgress && (
            <path d={progressArc()} className={styles.progressCircleFilled} />
          )}
        </g>
      </svg>
    </div>
  );
};

CircularProgressChart.propTypes = {
  progress: PropTypes.number,
  canvasWidth: PropTypes.number.isRequired,
  canvasHeight: PropTypes.number.isRequired,
  children: PropTypes.element.isRequired,
  outerCircleProportion: PropTypes.number,
  innerCircleProportion: PropTypes.number,
  iconProportion: PropTypes.number,
  incompleteProgressLimit: PropTypes.number
};

export default CircularProgressChart;
