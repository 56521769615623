import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ProgressBar from '../../../shared/components/ProgressBar';
import StreaksCalendar from '../../../shared/components/StreaksCalendar';

export const Progress = ({ streaksCalendar, data }) => {
  if (!Object.keys(data).length) {
    return null;
  }

  return (
    <div className="mb-xl">
      {streaksCalendar ? (
        <StreaksCalendar {...data} />
      ) : (
        <ProgressBar {...data} />
      )}
    </div>
  );
};

Progress.propTypes = {
  streaksCalendar: PropTypes.bool.isRequired,
  data: PropTypes.shape({}).isRequired
};

export default connect(({ progress }) => ({
  data: progress.data,
  streaksCalendar: progress.streaksCalendar
}))(Progress);
