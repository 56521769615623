import React from 'react';
import PropTypes from 'prop-types';

import I18n from 'utils/I18n';

import styles from './styles.scss';

const I18nPrefix = 'strava.challenges.challenge_detail';

const Achievements = ({
  className = '',
  headlineAchievement,
  otherAchievements
}) => (
  <div className={`${className} ${styles.container}`}>
    <h2 className={`text-headline ${styles.header}`}>
      {I18n.t(`${I18nPrefix}.achievements_earned`)}
    </h2>
    <div className={styles.headlineAchievement}>
      <img src={headlineAchievement.url} alt={headlineAchievement.title} />
    </div>
    {otherAchievements.length > 0 && (
      <div className={styles.otherAchievements}>
        <div className={styles.achievements}>
          {otherAchievements.map((achievement) => (
            <img
              key={achievement.title}
              src={achievement.url}
              alt={achievement.title}
            />
          ))}
        </div>
      </div>
    )}
  </div>
);

Achievements.propTypes = {
  className: PropTypes.string,
  headlineAchievement: PropTypes.shape({
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired,
  otherAchievements: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    })
  ).isRequired
};

export default Achievements;
