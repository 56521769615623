import React from 'react';
import PropTypes from 'prop-types';
import { XAxis, BarChart, Bar } from 'recharts';
import Cldr from 'utils/Cldr';

const weekdayNames = (key) => {
  return Cldr.calendar.weekdays({
    format: 'stand-alone',
    names_form: 'narrow'
  })[key];
};

const WEEKDAY_KEYS = {
  0: 'mon',
  1: 'tue',
  2: 'wed',
  3: 'thu',
  4: 'fri',
  5: 'sat',
  6: 'sun'
};

const WeekChart = ({ dailyScores }) => {
  const tickTextStyle = {
    fill: '#6b6b76',
    fontSize: '11px'
  };

  const barData = dailyScores.map((v, i) => ({
    x: v,
    w: weekdayNames(WEEKDAY_KEYS[i])
  }));

  return (
    <BarChart data={barData} height={120} width={150}>
      <XAxis
        dataKey="w"
        tick={tickTextStyle}
        stroke="none"
        minTickGap={0}
        dy={3}
      />
      <Bar dataKey="x" fill="#494950" barSize={11} minPointSize={2} />
    </BarChart>
  );
};

WeekChart.propTypes = {
  dailyScores: PropTypes.arrayOf(PropTypes.number).isRequired
};

export default WeekChart;
