import React, { Component } from 'react';
import CreatableSelect from 'react-select/creatable';
import PropTypes from 'prop-types';

import { validateEmail } from 'utils/validators';
import I18n from 'utils/I18n';

import styles from './styles.scss';

const I18nPrefix = 'strava.challenges.challenge_detail';

const createOption = (label) => {
  const email = label.trim().replace(',', '');

  return { label: email, value: email };
};

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: '100%',
    flex: 1,
    minHeight: 40
  }),
  control: (provided) => ({
    ...provided,
    border: 'solid 1px #ccccd0',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.11)',
    borderRadius: 2,
    minHeight: 40
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: 0,
    borderRadius: '0 0 2px 2px',
    border: 'solid 1px #ccccd0',
    borderTop: 'none',
    boxShadow: '0px 1px 3px 2px rgba(0,0,0,.1)'
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#F0F0F5'
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    color: 'inherit',
    ':hover': {
      backgroundColor: '#6D6D78',
      color: '#fff'
    }
  })
};

class EmailInput extends Component {
  state = {
    inputValue: '',
    invalidInput: false,
    value: []
  };

  handleChange = (value) => {
    const { handleAddressesChange } = this.props;

    this.setState({ value });
    handleAddressesChange(value);
  };

  handleInputChange = (inputValue) => {
    this.setState({ inputValue });
  };

  validateInput = () => {
    const { inputValue } = this.state;

    return validateEmail(inputValue);
  };

  updateValue = () => {
    const { inputValue, value } = this.state;
    const { handleAddressesChange } = this.props;
    let newValue;

    if (!this.validateInput(inputValue)) {
      this.setState({ inputValue: '', invalidInput: true });
      return;
    }
    if (!value) {
      newValue = [createOption(inputValue)];
    } else {
      newValue = [...value, createOption(inputValue)];
    }
    this.setState({
      inputValue: '',
      value: newValue,
      invalidInput: false
    });
    handleAddressesChange(newValue);
  };

  handleKeyDown = (event) => {
    const { inputValue, value } = this.state;

    if (!inputValue) return;
    if (value && value.length >= 4) return;
    // eslint-disable-next-line default-case
    switch (event.key) {
      case 'Enter':
      case ',':
      case ' ':
        this.updateValue();
        event.preventDefault();
    }
  };

  handleBlur = () => {
    const { inputValue } = this.state;

    if (!inputValue) return;
    this.updateValue();
  };

  render() {
    const { inputValue, value, invalidInput } = this.state;
    return (
      <div className={styles.container}>
        <CreatableSelect
          components={{ DropdownIndicator: null }}
          inputValue={inputValue}
          delimiter=","
          isClearable={true}
          isMulti={true}
          menuIsOpen={false}
          onChange={this.handleChange}
          onInputChange={this.handleInputChange}
          onKeyDown={this.handleKeyDown}
          onBlur={this.handleBlur}
          placeholder={I18n.t(`${I18nPrefix}.enter_emails`)}
          value={value}
          styles={customStyles}
        />
        {invalidInput && (
          <div className={styles.error}>
            {I18n.t(`${I18nPrefix}.invalid_email`)}
          </div>
        )}
      </div>
    );
  }
}

EmailInput.propTypes = {
  handleAddressesChange: PropTypes.func.isRequired
};

export default EmailInput;
