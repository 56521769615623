import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toggleSegmentStar } from '../../actions';
import styles from './index.scss';

export class StarToggleComponent extends React.PureComponent {
  handleClick = () => {
    // get both the state (isActive and segmentId) and the action function from
    // props. The toggleSegmentStarAction is set up in the mapDispatchToProps
    // function below, and is defined in the actions.js file in the main
    // StarredSegment component directory.
    const { isActive, segmentId, toggleSegmentStarAction } = this.props;

    // this function is a wrapped version of the toggleSegmentStar function
    // in the actions.js file
    toggleSegmentStarAction(segmentId, isActive);
  };

  render() {
    const { isActive } = this.props;

    return (
      <button
        className={`${styles.star} starred-segment starred ${
          isActive ? 'active' : ''
        } ${isActive ? styles.active : ''}`}
        onClick={this.handleClick}
      >
        <span className="app-icon-wrapper">
          <span className="app-icon icon-star icon-sm icon-dark" />
        </span>
      </button>
    );
  }
}

StarToggleComponent.propTypes = {
  segmentId: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired,
  toggleSegmentStarAction: PropTypes.func.isRequired
};

//
// Redux mapping methods
//

function mapStateToProps(state) {
  return {
    segmentId: state.segmentId,
    isActive: state.isActive
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleSegmentStarAction: bindActionCreators(toggleSegmentStar, dispatch)
  };
}

// wire up the state and the dispatch and export the resulting component
const wrappedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(StarToggleComponent);

export default wrappedComponent;
