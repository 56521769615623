import React from 'react';
import PropTypes from 'prop-types';
import Polyline from '@strava/ui/Map/Polyline';
import { color } from '@strava/ui-tokens/js';

const PrivacyZonePolylines = ({
  polyline,
  privacyZoneFromStartIndex,
  privacyZoneFromEndIndex
}) => {
  const privacyZonePolylineStyle = {
    lineColor: color.colorExtendedNeutralN2,
    outlineWidth: 5
  };

  return (
    <>
      <Polyline
        latLng={[...polyline.slice(0, privacyZoneFromStartIndex)]}
        id="privacy-polyline-start"
        style={privacyZonePolylineStyle}
      />

      <Polyline
        latLng={[
          ...polyline.slice(
            polyline.length - privacyZoneFromEndIndex,
            polyline.length - 1
          )
        ]}
        id="privacy-polyline-end"
        style={privacyZonePolylineStyle}
      />
    </>
  );
};

PrivacyZonePolylines.propTypes = {
  polyline: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
  privacyZoneFromStartIndex: PropTypes.number.isRequired,
  privacyZoneFromEndIndex: PropTypes.number.isRequired
};

export default PrivacyZonePolylines;
