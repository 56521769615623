import React from 'react';
import PropTypes from 'prop-types';

import { TabPanel as ReactTabPanel } from 'react-tabs';

const TabPanel = ({ children, className = '', selected  = false, ...props }) => (
  <ReactTabPanel
    className={selected ? className : ''}
    selected={selected}
    {...props}
  >
    {children}
  </ReactTabPanel>
);

TabPanel.tabsRole = 'TabPanel';

TabPanel.propTypes = {
  selected: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default TabPanel;
