import React from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import reducer from './reducers';

import PartnerIntegrationsList from './components/PartnerIntegrationsList';

class PartnerIntegrations extends React.Component {
  store = createStore(
    reducer,
    { ...this.props, selected: null, disabled: false },
    applyMiddleware(thunk)
  );

  render() {
    return (
      <Provider store={this.store}>
        <PartnerIntegrationsList />
      </Provider>
    );
  }
}

export default PartnerIntegrations;
