import createNetworkingClient from 'utils/networking-client';

export const types = {
  TOGGLE_MODAL: 'TOGGLE_MODAL',
  HIDE_INTERSTITIAL: 'HIDE_INTERSTITIAL',
  INVITE_SUBMITTING: 'INVITE_SUBMITTING',
  INVITE_COMPLETE: 'INVITE_COMPLETE',
  INVITE_ERROR: 'INVITE_ERROR',
  FOLLOWERS_LOADING: 'FOLLOWERS_LOADING',
  FOLLOWERS_COMPLETE: 'FOLLOWERS_COMPLETE',
  FOLLOWERS_ERROR: 'FOLLOWERS_ERROR'
};

export const toggleModal = () => ({
  type: types.TOGGLE_MODAL
});

export const hideInterstitial = () => ({
  type: types.HIDE_INTERSTITIAL
});

const inviteSubmitting = (athleteId) => ({
  type: types.INVITE_SUBMITTING,
  athleteId
});

const inviteComplete = (athleteId) => ({
  type: types.INVITE_COMPLETE,
  athleteId
});

const inviteError = (athleteId) => ({
  type: types.INVITE_ERROR,
  athleteId
});

export const invite = (athleteId, activityId) => (dispatch) => {
  dispatch(inviteSubmitting(athleteId));

  createNetworkingClient()
    .post(`/activities/${activityId}/invite_friend`, {
      invitedAthleteId: athleteId
    })
    .then((response) => {
      if (response && response.status === 200) {
        dispatch(inviteComplete(athleteId));
      } else {
        dispatch(inviteError(athleteId));
      }
    })
    .catch(() => {
      dispatch(inviteError(athleteId));
    });
};

const followersLoading = () => ({
  type: types.FOLLOWERS_LOADING
});

const followersComplete = (followers) => ({
  type: types.FOLLOWERS_COMPLETE,
  followers
});

const followersError = (error) => ({
  type: types.FOLLOWERS_ERROR,
  error: error.message
});

export const fetchFollowers = (input) => (dispatch) => {
  const searchTerm = input === undefined ? '' : input;
  dispatch(followersLoading());
  return createNetworkingClient()
    .get(`/athlete/followers/${searchTerm}`)
    .then((response) => {
      if (response && response.status === 200) {
        dispatch(followersComplete(response.data.results.followers));
      } else {
        dispatch(
          followersError(
            'An error has occured fetching your followers, please try again later.'
          )
        );
      }
    })
    .catch((e) => {
      dispatch(followersError(e));
    });
};

export default {
  types,
  toggleModal,
  hideInterstitial,
  invite,
  fetchFollowers
};
