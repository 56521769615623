import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import createNetworkingClient from 'utils/networking-client';
import ActivityDetailsMap from './ActivityDetailsMap';

const ActivityDetailsMapWrapper = (props) => {
  const [streamData, setStreamData] = useState(null);
  const { activityId } = props;

  useEffect(() => {
    const fetchStreams = async () => {
      const response = await createNetworkingClient().get(
        `/activities/${activityId}/streams`,
        {
          params: {
            stream_types: ['latlng', 'privacy']
          }
        }
      );

      // TODO wrap this in a try/catch
      if (!response || response.status !== 200) {
        console.error('Request failed');
      }

      setStreamData(response.data);
    };

    if (!streamData) {
      fetchStreams();
    }
  }, [activityId, streamData]);

  if (!streamData) {
    return null;
  }

  return <ActivityDetailsMap {...props} streamData={streamData} />;
};

ActivityDetailsMapWrapper.propTypes = {
  activityId: PropTypes.number.isRequired
};

export default ActivityDetailsMapWrapper;
