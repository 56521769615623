import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import ButtonCTAWithCancel from 'components/ButtonCTAWithCancel';
import OptionList from './components/OptionList';

export function Step({
  name,
  title,
  flow,
  isSubmitted,
  config,
  currentValue = null,
  handleValueChange,
  handleCancel,
  trackNext,
  trackScreenEnter,
  trackScreenExit,
  history
}) {
  useEffect(() => {
    trackScreenEnter();
    return trackScreenExit;
  }, [history]);

  if (isSubmitted) {
    handleCancel();
    return null;
  }

  const nextStep = flow[flow.indexOf(name) + 1];
  const handleNext = () => {
    trackNext();
    history.push(nextStep);
  };

  return (
    <>
      <h2>{title}</h2>
      <OptionList
        {...config}
        {...{ currentValue, handleNext, handleValueChange }}
      />
      <ButtonCTAWithCancel
        onCancel={handleCancel}
        onCTA={handleNext}
        ctaVariant="next"
        ctaDisabled={currentValue === null}
      />
    </>
  );
}

Step.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['OptionList']).isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  flow: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  handleValueChange: PropTypes.func.isRequired,
  config: PropTypes.shape({}).isRequired,
  handleCancel: PropTypes.func.isRequired,
  trackNext: PropTypes.func.isRequired,
  trackScreenEnter: PropTypes.func.isRequired,
  trackScreenExit: PropTypes.func.isRequired,

  // withRouter
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(Step);
