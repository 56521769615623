const COLORS = {
  NO_ZONE: {
    PRIMARY: 'rgba(190, 190, 190, 1)',
    SECONDARY: 'rgba(190, 190, 190, 0.3)'
  },
  LOW_ZONE: {
    PRIMARY: 'rgba(174, 157, 255, 1)',
    SECONDARY: 'rgba(174, 157, 255, 0.3)'
  },
  OPTIMUM_ZONE: {
    PRIMARY: 'rgba(176, 60, 255, 1)',
    SECONDARY: 'rgba(176, 60, 255, 0.3)'
  },
  HIGH_ZONE: {
    PRIMARY: 'rgba(189, 12, 15, 1)',
    SECONDARY: 'rgba(189, 12, 15, 0.3)'
  }
};

const ZONES = {
  NONE: 'NONE',
  LOW: 'LOW',
  OPTIMUM: 'OPTIMUM',
  STEADY_PROGRESS: 'STEADY_PROGRESS',
  HIGH: 'HIGH',
  VERY_HIGH: 'VERY_HIGH'
};

const TRAINING_IMPACT = {
  LOWER_RATIO: 0.33,
  MID_RATIO: 0.5,
  UPPER_RATIO: 0.66
};

export const CONSTS = {
  COLORS,
  ZONES,
  TRAINING_IMPACT
};

/**
 * @param {Number} value RE score
 * @param {Array} buckets RE buckets
 * @return {String} zone
 */
export function getZone(value, buckets) {
  switch (true) {
    case value === 0:
      return ZONES.NONE;
    case value < buckets[0]:
      return ZONES.LOW;
    case buckets[0] <= value && value < buckets[1]:
      return ZONES.OPTIMUM;
    case buckets[1] <= value && value < buckets[2]:
      return ZONES.STEADY_PROGRESS;
    case buckets[2] <= value && value < buckets[3]:
      return ZONES.HIGH;
    case buckets[3] <= value:
      return ZONES.VERY_HIGH;
    default:
      return ZONES.NONE;
  }
}

/**
 * @param {Number} value RE score
 * @param {Array} buckets RE buckets
 * @return {String} rgba color
 */
export function getPrimaryColor(value, buckets) {
  const zone = getZone(value, buckets);

  switch (zone) {
    case ZONES.NONE:
      return COLORS.NO_ZONE.PRIMARY;

    case ZONES.LOW:
      return COLORS.LOW_ZONE.PRIMARY;

    case ZONES.OPTIMUM:
    case ZONES.STEADY_PROGRESS:
      return COLORS.OPTIMUM_ZONE.PRIMARY;

    case ZONES.HIGH:
    case ZONES.VERY_HIGH:
      return COLORS.HIGH_ZONE.PRIMARY;

    default:
      return '';
  }
}

/**
 * @param {Number} value RE score
 * @param {Array} buckets RE buckets
 * @return {String} rgba color
 */
export function getSecondaryColor(value, buckets) {
  const zone = getZone(value, buckets);

  switch (zone) {
    case ZONES.NONE:
      return COLORS.NO_ZONE.SECONDARY;

    case ZONES.LOW:
      return COLORS.LOW_ZONE.SECONDARY;

    case ZONES.OPTIMUM:
    case ZONES.STEADY_PROGRESS:
      return COLORS.OPTIMUM_ZONE.SECONDARY;

    case ZONES.HIGH:
    case ZONES.VERY_HIGH:
      return COLORS.HIGH_ZONE.SECONDARY;

    default:
      return '';
  }
}

/**
 * @param {Number} ratio float number between 0.0 and 1.0
 * @return {String} rgba color
 */
export function getTrainingImpactColor(ratio) {
  let color = COLORS.LOW_ZONE;

  if (ratio < TRAINING_IMPACT.LOWER_RATIO) {
    color = COLORS.LOW_ZONE.PRIMARY;
  } else if (ratio < TRAINING_IMPACT.UPPER_RATIO) {
    color = COLORS.OPTIMUM_ZONE.PRIMARY;
  } else {
    color = COLORS.HIGH_ZONE.PRIMARY;
  }

  return color;
}

/**
 * Return Monday for a given year and ISO 8601 calendar week (where a week number
 * is counted based on the Thursday of that week). This matches the Ruby cweek
 * implementation.
 * @param {Number} year
 * @param {Number} week
 * @return {Date}
 */
export function getDateFromYearWeek(year, week) {
  const simple = new Date(year, 0, 1 + (week - 1) * 7);
  const dow = simple.getDay();
  const ISOweekStart = simple;
  if (dow <= 4) {
    ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
  } else {
    ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
  }

  return ISOweekStart;
}

/**
 * Returns first day of a week for a give date
 * @param {Date} date
 * @return {Date}
 */
export function getFirstDayOfWeekFromDate(date) {
  return date.getDate() - date.getDay();
}

/**
 * Check if date is current week
 * @param {Date} week
 * @return {Bool}
 */
export function isCurrentWeek(week) {
  const now = new Date();
  now.setHours(0, 0, 0, 0);

  const firstDayOfWeek = getFirstDayOfWeekFromDate(now);
  const monday = new Date(now.setDate(firstDayOfWeek + 1));
  const sunday = new Date(now.setDate(firstDayOfWeek + 7));

  return monday <= week && week <= sunday;
}

export default {
  CONSTS,
  getZone,
  getPrimaryColor,
  getSecondaryColor,
  getTrainingImpactColor,
  getDateFromYearWeek,
  getFirstDayOfWeekFromDate,
  isCurrentWeek
};
