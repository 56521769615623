import React from 'react';
import PropTypes from 'prop-types';
import Stats from '@strava/ui/Stats';
import Stat from '@strava/ui/Stat';

import Cldr from 'utils/Cldr';

import styles from './styles.scss';

const DataGrid = ({ dataGrid, containerClass }) => {
  const formatValue = (value) =>
    typeof value === 'number' ? Cldr.formatDecimal(value) : value;

  const addStat = (row) => (
    <Stat
      key={row.title}
      value={formatValue(row.value)}
      label={row.title}
      wrapperClassName={`text-caption2 ${styles.gridItem}`}
      labelClassName={styles.title}
      valueClassName="text-body"
    />
  );

  return (
    <Stats listClassName={`${containerClass} ${styles.row}`}>
      {dataGrid.map((row) => addStat(row))}
    </Stats>
  );
};

DataGrid.propTypes = {
  dataGrid: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired
    })
  ).isRequired,
  containerClass: PropTypes.string
};

export default DataGrid;
