// Setting style rules to `null` will prevent them from being rendered
// This allows for standard styling using CSS without the need to use !important

// important - input height and highlighter height needs to be the same in order
// for highlighted mentions overlay to scroll together with the input text.

export const setMentionInputStyles = ({ inputHeight }) => ({
  // Container
  control: {
    height: null,
    width: null
  },

  // Mentioned Name Overlay
  highlighter: {
    lineHeight: null,
    border: '1px solid transparent',
    height: inputHeight,
    padding: null,
    color: null,
    width: null,
    pointerEvents: 'none',
    overflow: 'hidden',
    boxSizing: 'border-box'
  },

  // <textarea> tag
  input: {
    backgroundColor: null,
    boxSizing: null,
    display: null,
    fontFamily: null,
    fontSize: null,
    height: inputHeight,
    resize: null,
    width: null,
    overflow: 'auto'
  },

  // Mentioned Popover
  suggestions: {
    backgroundColor: null,
    left: 0,
    marginTop: null,
    minWidth: null,
    position: 'absolute',
    right: 0,
    top: null,
    width: null,
    zIndex: 50,
    // <ul> element
    list: {
      listStyleType: null,
      margin: null,
      padding: null
    },
    // <li> element
    item: {
      cursor: null,

      '&focused': {
        backgroundColor: '#e6e6e6'
      }
    }
  }
});

export default { setMentionInputStyles };
