import { types } from './actions';

export default function reducer(state = {}, action) {
  const [numParticipants, ...remaining] = state.participantCount;

  switch (action.type) {
    case types.FETCH_CHALLENGE_PROGRESS_SUCCESS:
      return {
        ...state,
        progress: { ...state.progress, data: action.data }
      };
    case types.JOIN_CHALLENGE_FAILURE:
      return {
        ...state,
        challengeJoinRequested: false
      };
    case types.JOIN_CHALLENGE_REQUESTED:
      return {
        ...state,
        challengeJoinRequested: true
      };
    case types.JOIN_CHALLENGE_SUCCESS:
      return {
        ...state,
        joined: action.data.joined,
        participantCount: [
          { ...numParticipants, value: numParticipants.value + 1 },
          ...remaining
        ],
        challengeJoinRequested: false
      };
    case types.LEAVE_CHALLENGE_FAILURE:
      return {
        ...state,
        challengeLeaveRequested: false
      };
    case types.LEAVE_CHALLENGE_REQUESTED:
      return {
        ...state,
        challengeLeaveRequested: true
      };
    case types.LEAVE_CHALLENGE_SUCCESS:
      return {
        ...state,
        joined: false,
        participantCount: [
          { ...numParticipants, value: numParticipants.value - 1 },
          ...remaining
        ],
        challengeLeaveRequested: false,
        progress: { ...state.progress, data: {} }
      };
    case types.JOIN_CLUB_FAILURE:
      return {
        ...state,
        clubJoinRequested: false
      };
    case types.JOIN_CLUB_REQUESTED:
      return {
        ...state,
        clubJoinRequested: true
      };
    case types.JOIN_CLUB_SUCCESS:
      return {
        ...state,
        clubJoinRequested: false,
        club: {
          ...state.club,
          memberCount: action.data.memberCount,
          isClubMember: action.data.isClubMember
        }
      };

    case types.MODAL_OPEN:
      return {
        ...state,
        gatingModal: {
          gatingConditions: action.data.gatingConditions,
          joinChallengeFn: action.data.joinChallengeFn,
          ageGate: action.data.dateSelected,
          requestInProgress: false
        }
      };

    case types.MODAL_CLOSE:
      return {
        ...state,
        gatingModal: null
      };

    case types.MODAL_CHANGE_DATE:
      return {
        ...state,
        gatingModal: {
          ...state.gatingModal,
          ageGate: {
            ...state.gatingModal.ageGate,
            dateSelected: action.date
          }
        }
      };

    case types.DOB_UPDATE_REQUESTED:
      return {
        ...state,
        gatingModal: {
          ...state.gatingModal,
          requestInProgress: true
        }
      };

    case types.DOB_UPDATE_FAILED:
      return {
        ...state,
        gatingModal: {
          ...state.gatingModal,
          requestInProgress: false
        }
      };

    case types.DOB_UPDATE_SUCCESS:
      if (action.date) {
        return {
          ...state,
          currentAthlete: {
            ...state.currentAthlete,
            dateOfBirth: [
              action.date.getFullYear(),
              action.date.getMonth() + 1,
              action.date.getDate()
            ].join('-')
          },
          gatingModal: null
        };
      }
      return state;

    default:
      return state;
  }
}
