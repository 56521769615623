import React from 'react';
import PropTypes from 'prop-types';

import I18n from 'utils/I18n';
import { CONSTS, getZone, getPrimaryColor } from 'utils/relative-effort';

import styles from './styles.scss';

import lowEffortImage from './assets/low.png';
import moderateEffortImage from './assets/moderate.png';
import highEffortImage from './assets/high.png';

export const I18nPrefix = 'strava.relative_effort';

function getGraphicSrc(value, buckets) {
  const zone = getZone(value, buckets);

  switch (zone) {
    case CONSTS.ZONES.NONE:
    case CONSTS.ZONES.LOW:
      return lowEffortImage;

    case CONSTS.ZONES.OPTIMUM:
    case CONSTS.ZONES.STEADY_PROGRESS:
      return moderateEffortImage;

    case CONSTS.ZONES.HIGH:
    case CONSTS.ZONES.VERY_HIGH:
      return highEffortImage;

    default:
      return moderateEffortImage;
  }
}

function getIntensityKey(value, buckets) {
  const zone = getZone(value, buckets);

  switch (zone) {
    case CONSTS.ZONES.NONE:
    case CONSTS.ZONES.LOW:
      return 'low';

    case CONSTS.ZONES.OPTIMUM:
    case CONSTS.ZONES.STEADY_PROGRESS:
      return 'moderate';

    case CONSTS.ZONES.HIGH:
    case CONSTS.ZONES.VERY_HIGH:
      return 'high';

    default:
      return 'moderate';
  }
}

const NoAccess = ({
  cumulativeScore,
  buckets,
  subscriptionUrl,
  isTrialEligible
}) => {
  const IntensityKey = getIntensityKey(cumulativeScore, buckets);

  return (
    <>
      <div className={`text-caption2 ${styles.lastWeek}`}>
        {I18n.t(`${I18nPrefix}.last_week_uppercase`)}
      </div>
      <div
        className="text-headline text-bold mb-xs"
        style={{ color: getPrimaryColor(cumulativeScore, buckets) }}
      >
        {I18n.t(`${I18nPrefix}.upsell.insight_week.titles.${IntensityKey}`)}
      </div>
      <div className="text-footnote mb-sm">
        {I18n.t(
          `${I18nPrefix}.upsell.insight_week.descriptions.${IntensityKey}`
        )}
      </div>

      <img
        src={getGraphicSrc(cumulativeScore, buckets)}
        className={styles.graphic}
        alt=""
      />

      <div className={`media media-middle mb-md ${styles.description}`}>
        <div className="media-content">
          <div className="media-body">
            {isTrialEligible
              ? I18n.t(`${I18nPrefix}.upsell.description_v2`)
              : I18n.t(`${I18nPrefix}.upsell.description_v3`)}
          </div>
        </div>
      </div>
      <a
        href={subscriptionUrl}
        className="btn btn-default btn-block mt-md text-footnote text-heavy"
      >
        {isTrialEligible
          ? I18n.t(`${I18nPrefix}.upsell.cta.start_trial`)
          : I18n.t(`${I18nPrefix}.upsell.cta.subscribe`)}
      </a>
    </>
  );
};

NoAccess.propTypes = {
  cumulativeScore: PropTypes.number.isRequired,
  buckets: PropTypes.arrayOf(Number).isRequired,
  subscriptionUrl: PropTypes.string.isRequired,
  isTrialEligible: PropTypes.bool.isRequired
};

export default NoAccess;
