import React from 'react';
import PropTypes from 'prop-types';
import Cldr from 'utils/Cldr';
import I18n from 'utils/I18n';
import reUtils, {
  CONSTS,
  getZone,
  getPrimaryColor,
  getDateFromYearWeek,
  getFirstDayOfWeekFromDate
} from 'utils/relative-effort';

import styles from './styles.scss';

export const I18nPrefix = 'strava.relative_effort.insight_week';

class WeekInsight extends React.Component {
  getTitleText = (isCurrentWeek) => {
    const { cumulativeScore, buckets } = this.props;
    const zone = getZone(cumulativeScore, buckets);
    const titles = `${I18nPrefix}.${isCurrentWeek ? 'current' : 'past'}.titles`;

    switch (zone) {
      case CONSTS.ZONES.NONE:
        return I18n.t(`${titles}.none`);
      case CONSTS.ZONES.LOW:
        return I18n.t(`${titles}.low`);
      case CONSTS.ZONES.OPTIMUM:
        return I18n.t(`${titles}.optimum`);
      case CONSTS.ZONES.STEADY_PROGRESS:
        return I18n.t(`${titles}.steady_progress`);
      case CONSTS.ZONES.HIGH:
        return I18n.t(`${titles}.high`);
      case CONSTS.ZONES.VERY_HIGH:
        return I18n.t(`${titles}.very_high`);
      default:
        return '';
    }
  };

  getDescriptionText = (isCurrentWeek) => {
    const { cumulativeScore, buckets } = this.props;
    const zone = getZone(cumulativeScore, buckets);
    if (isCurrentWeek) {
      const descriptions = `${I18nPrefix}.current.descriptions`;
      switch (zone) {
        case CONSTS.ZONES.NONE:
          return I18n.t(`${descriptions}.none`);
        case CONSTS.ZONES.LOW:
          return I18n.t(`${descriptions}.low`, {
            value: parseInt(buckets[0], 10)
          });
        case CONSTS.ZONES.OPTIMUM:
          return I18n.t(`${descriptions}.optimum`);
        case CONSTS.ZONES.STEADY_PROGRESS:
          return I18n.t(`${descriptions}.steady_progress`, {
            value: parseInt(buckets[2] - cumulativeScore, 10)
          });
        case CONSTS.ZONES.HIGH:
          return I18n.t(`${descriptions}.high`);
        case CONSTS.ZONES.VERY_HIGH:
          return I18n.t(`${descriptions}.very_high`);
        default:
          return '';
      }
    } else {
      const descriptions = `${I18nPrefix}.past.descriptions`;
      switch (zone) {
        case CONSTS.ZONES.NONE:
          return I18n.t(`${descriptions}.none`);
        case CONSTS.ZONES.LOW:
          return I18n.t(`${descriptions}.low_v2`, {
            value: parseInt(buckets[0], 10)
          });
        case CONSTS.ZONES.OPTIMUM:
          return I18n.t(`${descriptions}.optimum_v2`);
        case CONSTS.ZONES.STEADY_PROGRESS:
          return I18n.t(`${descriptions}.steady_progress_v2`, {
            value: parseInt(buckets[2] - cumulativeScore, 10)
          });
        case CONSTS.ZONES.HIGH:
          return I18n.t(`${descriptions}.high_v2`);
        case CONSTS.ZONES.VERY_HIGH:
          return I18n.t(`${descriptions}.very_high_v2`);
        default:
          return '';
      }
    }
  };

  dateRange = (activityWeek) => {
    const date = new Date(activityWeek);
    const firstDayOfWeek = getFirstDayOfWeekFromDate(date);

    const monday = Cldr.formatDateTime(
      new Date(date.setDate(firstDayOfWeek + 1)),
      {
        format: 'additional',
        type: 'yMMMd'
      }
    );
    const sunday = Cldr.formatDateTime(
      new Date(date.setDate(firstDayOfWeek + 7)),
      {
        format: 'additional',
        type: 'yMMMd'
      }
    );

    return `${monday} - ${sunday}`;
  };

  render() {
    const { year, week, cumulativeScore, buckets } = this.props;
    const activityWeek = getDateFromYearWeek(year, week);
    const isCurrentWeek = reUtils.isCurrentWeek(activityWeek);
    const cumulativeScoreColor = getPrimaryColor(cumulativeScore, buckets);

    return (
      <div className={styles.container}>
        <div className={`text-footnote ${styles.dateRange}`}>
          {this.dateRange(activityWeek)}
        </div>
        <div
          className={`text-display1 ${styles.cumulativeScore}`}
          style={{ color: cumulativeScoreColor }}
        >
          {cumulativeScore}
        </div>
        <div className={`text-callout ${styles.title}`}>
          {this.getTitleText(isCurrentWeek)}
        </div>
        <div className={`text-caption1 ${styles.description}`}>
          {this.getDescriptionText(isCurrentWeek)}
        </div>
      </div>
    );
  }
}

WeekInsight.propTypes = {
  year: PropTypes.number.isRequired,
  week: PropTypes.number.isRequired,
  cumulativeScore: PropTypes.number.isRequired,
  buckets: PropTypes.arrayOf(PropTypes.number).isRequired
};

export default WeekInsight;
