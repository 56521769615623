import {
  OPEN_MODAL_COMMENTS_TAB,
  OPEN_MODAL_KUDOS_TAB
} from '../KudosCommentsAndAchievements/kudosAndComments.actions';

export const handleKudosAndCommentsNotificationLinkNavigation = ({
  entityId,
  dispatch,
  url = window.location.href
}) => {
  // Renders the modal if url includes #comments or #kudos
  // url comes from notification links
  if (url.includes('#comments')) {
    dispatch({
      type: OPEN_MODAL_COMMENTS_TAB,
      payload: { element: 'comment', selectedEntityId: entityId }
    });
  } else if (url.includes('#kudos')) {
    dispatch({
      type: OPEN_MODAL_KUDOS_TAB,
      payload: {
        element: 'kudo_icon',
        selectedEntityId: entityId
      }
    });
  }
};

export default { handleKudosAndCommentsNotificationLinkNavigation };
