import React, { useState } from 'react';
import Button from '@strava/ui/Button';
import PropTypes from 'prop-types';
import I18n from 'utils/I18n';
import clsx from '@strava/ui/clsx';

import Modal, { ModalBody } from '@strava/ui/Modal';
import NavigationInformationNormalXsmall from '@strava/icons/NavigationInformationNormalXsmall';

import styles from './styles.scss';

const InfoModal = ({ children, className = '' }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModalOpen = () => {
    setIsModalOpen(!isModalOpen);
  };

  const ContentModal = (
    <Modal isOpen={isModalOpen} onDismiss={toggleModalOpen} hasClose={true}>
      <ModalBody>{children}</ModalBody>
    </Modal>
  );

  return (
    <>
      <Button
        className={clsx(styles.infoButton, className)}
        variant="icon"
        type="button"
        onClick={toggleModalOpen}
        aria-label={I18n.t('strava.info.modal.open_modal')}
      >
        <NavigationInformationNormalXsmall color="grey" aria-hidden={true} />
      </Button>
      {ContentModal}
    </>
  );
};

InfoModal.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string
};

export default InfoModal;
