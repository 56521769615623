import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const Tick = ({ tick }) => (
  <div
    className={styles.tick}
    style={{
      left: `${tick.percent}%`
    }}
  />
);

Tick.propTypes = {
  tick: PropTypes.shape({
    percent: PropTypes.number.isRequired
  }).isRequired
};

export default Tick;
