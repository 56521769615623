import createNetworkingClient from 'utils/networking-client';
import { createFFmpeg } from '@ffmpeg/ffmpeg';
import exifr from 'exifr';

// Prepopulated files have unique_id
// while uploaded files have uuid, so we want to return one of them
export const normalizeId = (file) => file.unique_id || file.uuid;

// Active has snake_case while React files use camelCase
export const normalizeMediaType = (file) => file.media_type || file.mediaType;

// Check if media is an Image or a Video
export const getMediaType = (file) => (/video/.test(file.type) ? 2 : 1);

// TODO: Look into crypto UUID potentially
// For each new file, create a uuid for it that will be used as unique_id
export const getUuid = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line no-bitwise
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

export const getVideoMetadata = (file) =>
  new Promise((resolve) => {
    const url = URL.createObjectURL(file);

    const video = document.createElement('video');
    video.muted = true;
    video.src = url;
    video.preload = 'metadata';
    video.playsInline = true;

    video.addEventListener('loadeddata', function() {
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas
        .getContext('2d')
        .drawImage(video, 0, 0, canvas.width, canvas.height);

      canvas.toBlob(function(blob) {
        resolve({
          width: video.width,
          height: video.height,
          duration: video.duration,
          previewUrl: URL.createObjectURL(blob)
        });
      }, 'image/png');
      video.pause();
    });
    video.play();
  });

// Check if file is of type HEIC
export const isHEICFile = (file) => {
  return file.type.includes('heic');
};

// Convert HEIC File to blob to convert to a new file to ultimately
// convert to a JPEG file for thumbnail and storage in backend
export const convertHEICFileToBlob = async (file) => {
  const blobURL = URL.createObjectURL(file);

  // convert "fetch" the new blob url
  const blobRes = await fetch(blobURL);

  // convert response to blob
  const blob = await blobRes.blob();

  // Only load this big library when needed here
  const heic2any = (await import('heic2any')).default;

  // return a converted blob
  return heic2any({ blob });
};

// Process file and create thumbnail from the video drawn on a canvas
// Returns a preview url
export const getThumbnailPreviewUrl = (file) =>
  new Promise((resolve) => {
    const url = URL.createObjectURL(file);
    const mediaType = getMediaType(file);

    // Image
    if (mediaType === 1) {
      resolve(url);
      return;
    }
    // Video
    getVideoMetadata(file).then(({ previewUrl }) => resolve(previewUrl));
  });

const loadFfmpeg = async () => {
  const ffmpeg = createFFmpeg({
    mainName: 'main',
    // TODO: Figure out how to link this to our package.json
    // so it is not hardcoded
    corePath: 'https://unpkg.com/@ffmpeg/core-st@0.11.0/dist/ffmpeg-core.js'
  });

  // Load ffmpeg.wasm-core script
  if (!ffmpeg.isLoaded()) {
    await ffmpeg.load();
  }

  return ffmpeg;
};

// Process video using ffmpeg to get the metadata that is not easily accessible from the video itself
// Used to get location data from video files
const extractVideoHiddenMetadata = async (file) => {
  const ffmpeg = await loadFfmpeg();

  // Load video file in ffmpeg lib
  const arrayBuffer = await file.arrayBuffer();
  const locationFileName = `video-${new Date().getTime()}.txt`;
  ffmpeg.FS('writeFile', file.name, new Uint8Array(arrayBuffer));

  // Prepare command to get metadata of video file
  //
  // https://www.ffmpeg.org/ffmpeg-formats.html#:~:text=ffmpeg%20%2Di%20INPUT%20%2Df%20ffmetadata%20FFMETADATAFILE
  //
  // ffmetadata: metadata
  //
  const metadataCommand = `-f ffmetadata`.split(' ');

  await ffmpeg.run('-i', file.name, ...metadataCommand, locationFileName);

  const data = ffmpeg.FS('readFile', locationFileName);
  const blob = new Blob([data.buffer]);

  return new File([blob], file.name);
};

// Takes a txt file and returns an array of each line
export const parseTextFile = (file) =>
  new Promise((resolve) => {
    const fileReader = new FileReader();
    fileReader.readAsText(file);
    fileReader.onload = () => {
      const stringFileArray = fileReader.result.split(/\r?\n/);
      resolve(stringFileArray);
    };
  });

// Regex iphone location string
// Example - com.apple.quicktime.location.ISO6709=+34.1502-118.2578+181.324/
export function iphoneRegex(input) {
  const regex = /com\.apple\.quicktime\.location\.ISO6709=/i;
  return regex.test(input);
}

// Regex android location string
// Example - location=+40.7930-111.9344/
export function androidRegex(input) {
  const regex = /location=/i;
  return regex.test(input);
}

// Find string location data and return formatted location object
export const extractLocationData = (stringFileArray) => {
  const locationLine = stringFileArray.find(
    (str) => iphoneRegex(str) || androidRegex(str)
  );
  if (locationLine === undefined) {
    return null;
  }
  const splitStr = locationLine.split('+');
  const latLongSplit = splitStr[1].split('-');
  const locationData = {
    lat: parseFloat(latLongSplit[0]),
    lng: parseFloat(latLongSplit[1].replace('/', ''))
  };
  return locationData;
};

// Return URL that will specify where to store the media
export const getMediaUrl = async (file, uuid, athleteId) => {
  const path = `/photos/metadata`;
  const mediaType = getMediaType(file);

  let location;
  // Image
  if (mediaType === 1) {
    const data = await exifr.gps(file);
    location = data
      ? {
          lat: data.latitude,
          lng: data.longitude
        }
      : null;
  }
  // Video
  else {
    const additionalVideoMetadata = await extractVideoHiddenMetadata(file);
    const parsedTextArray = await parseTextFile(additionalVideoMetadata);
    const locationData = await extractLocationData(parsedTextArray);
    location = locationData;
  }

  try {
    const result = await createNetworkingClient().put(path, {
      athlete_id: athleteId,
      uuid,
      taken_at: file.lastModified,
      media_type: mediaType,
      location
    });

    return result;
  } catch (error) {
    return {
      error: {
        message: `${error.name}: ${error.message}`,
        debug: {
          path
        }
      }
    };
  }
};

// Store media at given URL
export const storeMedia = async (data, file, onProgress) => {
  const { uri, header } = data;

  try {
    const result = await createNetworkingClient().put(uri, file, {
      headers: { 'Content-Type': header['Content-Type'] },
      onUploadProgress: (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        onProgress(progress);
      },
      timeout: 300000 // 5 mins
    });
    return result;
  } catch (error) {
    return {
      error: {
        message: `${error.name}: ${error.message}`,
        debug: {
          uri
        }
      }
    };
  }
};

export const trimVideo = async (file) => {
  // Limit to the first 30 seconds.  Needs 31 to be from 0 - 30
  const trimDuration = 31;
  const { duration } = await getVideoMetadata(file);

  if (duration <= trimDuration) {
    return file;
  }

  const ffmpeg = await loadFfmpeg();

  // Load video file in ffmpeg lib
  const arrayBuffer = await file.arrayBuffer();
  const trimFileName = `trim-${new Date().getTime()}-${file.name}`;
  ffmpeg.FS('writeFile', file.name, new Uint8Array(arrayBuffer));

  // Prepare command to trim video
  //
  // https://shotstack.io/learn/use-ffmpeg-to-trim-video/
  //
  // -ss: starting position
  // -t: duration from starting position
  //
  const trimCommand = `-ss 00:00:00 -t 00:00:${trimDuration}`.split(' ');

  // copy the original audio and video without re-encoding
  const copyCommand = '-c:v copy -c:a copy'.split(' ');

  // execute command to trim the video
  await ffmpeg.run(
    '-i',
    file.name,
    ...trimCommand,
    ...copyCommand,
    trimFileName
  );

  // Get data for trimmed video from ffmpeg
  const data = ffmpeg.FS('readFile', trimFileName);
  const blob = new Blob([data.buffer], { type: file.type });

  return new File([blob], trimFileName, { type: file.type });
};

// Count the number of photos and videos added in one drag and drop for analytics
// and return total and type - photo/video/both
export function countPhotoAndVideoFiles(files) {
  let photoCount = 0;
  let videoCount = 0;
  files.forEach((file) => {
    const mediaType = getMediaType(file);
    if (mediaType === 2) {
      videoCount += 1;
    } else {
      photoCount += 1;
    }
  });

  let type;
  if (photoCount > 0 && videoCount > 0) {
    type = 'both';
  } else if (photoCount > 0) {
    type = 'photo';
  } else {
    type = 'video';
  }

  return { total: photoCount + videoCount, type };
}

export default {
  normalizeId,
  normalizeMediaType,
  getMediaType,
  getUuid,
  getThumbnailPreviewUrl,
  getMediaUrl,
  storeMedia,
  convertHEICFileToBlob,
  isHEICFile
};
