import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.scss';

import Option from '../Option';

function handleHeightReport(heightReported, optionIndex, height) {
  return (size) => {
    const newHeight = size.height;

    if (newHeight === height) {
      return;
    }

    if (newHeight === 0) {
      return;
    }

    heightReported(optionIndex, newHeight);
  };
}

function OptionList({
  options,
  selectedOptionIndex,
  heightReported,
  ...props
}) {
  return (
    <ul className={styles.options} id={OptionList.identifier(props.name)}>
      {options.map((option, i) => (
        <Option
          {...props}
          {...option}
          key={option.value}
          optionIndex={i}
          isSelected={i === selectedOptionIndex}
          onSize={handleHeightReport(heightReported, i, option.height)}
        />
      ))}
    </ul>
  );
}

OptionList.identifier = function identifier(settingName) {
  return `${OptionList.name}-${settingName}`;
};

OptionList.propTypes = {
  heightReported: PropTypes.func.isRequired,
  valueChanged: PropTypes.func.isRequired,

  name: PropTypes.string.isRequired,
  selectedOptionIndex: PropTypes.number.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      detail: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired
};

export default OptionList;
