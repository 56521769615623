import React from 'react';
import PropTypes from 'prop-types';

import createNetworkingClient from 'utils/networking-client';
import I18n from 'utils/I18n';

import Facepile from 'components/shared/Facepile';

import FriendsList from '../FriendsList';

import styles from './styles.scss';

const I18nPrefix = 'strava.challenges.challenge_detail';

class Followers extends React.Component {
  state = {
    modalIsOpen: false,
    friendsRequested: false,
    friends: null
  };

  onModalOpen = () => {
    const { challengeId } = this.props;
    const { friends } = this.state;

    if (!friends) {
      this.setState({ friendsRequested: true });
      const instance = createNetworkingClient();

      instance
        .get(`/challenges/${challengeId}/friends`)
        .then((response) => {
          if (response && response.status === 200) {
            this.onSuccess(response.data);
          } else {
            this.onFail();
          }
        })
        .catch(() => {
          this.onFail();
        });
    }
  };

  onSuccess = (data) => {
    this.setState({ friendsRequested: false, friends: data });
  };

  onFail = () => {
    this.setState({ friendsRequested: false });
    throw new Error('Something went wrong...');
  };

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  render() {
    const { modalIsOpen, friendsRequested, friends } = this.state;
    const {
      followersCount,
      followers,
      challengeLogo,
      challengeName,
      size = 'small',
      className
    } = this.props;

    return (
      <div>
        <div className={styles.facepileWrapper}>
          <Facepile
            athletes={followers}
            message={I18n.t(`${I18nPrefix}.joined_followers`, {
              count: followersCount
            })}
            size={size}
            onClick={this.openModal}
            className={`${styles.facepile} ${className}`}
          />
        </div>
        <FriendsList
          challengeLogo={challengeLogo}
          challengeName={challengeName}
          modalIsOpen={modalIsOpen}
          friendsRequested={friendsRequested}
          friends={friends}
          closeModal={this.closeModal}
          onModalOpen={this.onModalOpen}
        />
      </div>
    );
  }
}

Followers.propTypes = {
  challengeId: PropTypes.number.isRequired,
  challengeLogo: PropTypes.string.isRequired,
  challengeName: PropTypes.string.isRequired,
  followersCount: PropTypes.number.isRequired,
  followers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  size: PropTypes.string,
  className: PropTypes.string
};

export default Followers;
