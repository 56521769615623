import '../globalLibraries';
import '../coreLibraries';

// Strava
//
// This is the main entry point for react components. If you create a new
// component you will need to register in here.
//
// adding random string to force recompile:
// A998D3152224818D225A880A9B627F1FB4484DD7E408EACA1B243326ED4AA01B
import ReactComponentMounter from '../utils/react-component-mounter';

// Activity pages
import ActivityEditMapVisibilityApp from '../apps/activities/EditMapVisibilityApp';
import ActivityTagging from '../apps/activities/ActivityTagging';
import ActivityTruncate from '../apps/activities/Truncate';
import RelativeEffort from '../apps/activities/RelativeEffort';
import ExcludedEfforts from '../apps/activities/ExcludedEfforts';
import CorrectDistance from '../apps/activities/CorrectDistance/CorrectDistance';
import CorrectElevation from '../apps/activities/CorrectElevation/CorrectElevation';
import MapTypeInfo from '../apps/activities/MapTypeInfo/MapTypeInfo';
import HideStatsInfo from '../apps/activities/HideStatsInfo/HideStatsInfo';
import ActivityDetailsMapWrapper from '../components/ActivityDetailsMap';
// Athlete pages
import LimitedProfileEntry from '../apps/AthleteProfileApp/components/LimitedProfileEntry';
import FollowsList from '../apps/AthleteProfileApp/components/FollowsList';
// Settings pages
import EditPastActivitiesApp from '../apps/settings/EditPastActivitiesApp';
import PartnerIntegrationsApp from '../apps/settings/PartnerIntegrations';
import HideAddressApp from '../apps/settings/privacy/HideAddressApp';
import HideAnywhereApp from '../apps/settings/privacy/HideAnywhereApp';
// Challenge pages
import Show from '../apps/challenges/Show';
// Dashboard pages
import MapVisModal from '../apps/dashboard/components/MapVisModal';
// Other pages
import SegmentDetailsSideBar from '../apps/segments/SegmentDetailsSideBar';
import StarredSegment from '../components/StarredSegment';
import VerifiedSegmentModal from '../components/VerifiedSegmentModal';
import VisibilitySetting from '../components/VisibilitySetting';
import MapVisibilityControls from '../components/MapVisibilityControls';
import MentionableComment from '../components/MentionableComment';
import PerceivedExertionInput from '../components/PerceivedExertionInput';
import ProgressGoalsRelativeEffortTab from '../components/ProgressGoalsRelativeEffortTab';
import CreateSegmentApp from '../apps/publishes/CreateSegment';
import FeedbackApp from '../apps/FeedbackApp';
import Microfrontend from '../apps/Microfrontend';
import LcLBespokeSegmentProgress from '../apps/website_v2/LcLBespokeSegmentProgress';
import ProgressGoalsSidebarTabContainer from '../components/ProgressGoals/SidebarTabContainer';
import ActivityDescriptionEdit from '../components/ActivityDescriptionEdit';
import MediaThumbnailList from '../components/MediaThumbnailList';
import AthleteProfileHeaderMediaGrid from '../components/AthleteProfileHeaderMediaGrid';
import EmbedModalWrapper from '../components/EmbedModal/EmbedModalWrapper';
import MediaUploader from '../components/MediaUploader';
import EditActivityButton from '../components/EditActivityButton';
// Shared
import CommentReactionList from '../components/shared/KudosCommentsAndAchievements/CommentReactionList/CommentReactionList';
// Onboarding
import AthleteName from '../apps/Onboarding/components/AthleteName';
import ADPKudosAndComments from '../apps/activities/ADPKudosAndComments/ADPKudosAndComments';
import PostsKudosAndComments from '../apps/posts/PostsKudosAndComments/PostsKudosAndComments';

ReactComponentMounter.register({
  ActivityDescriptionEdit,
  ActivityEditMapVisibilityApp,
  ActivityTagging,
  ActivityTruncate,
  RelativeEffort,
  ExcludedEfforts,
  CorrectDistance,
  CorrectElevation,
  MapTypeInfo,
  HideStatsInfo,
  EditPastActivitiesApp,
  EmbedModalWrapper,
  PartnerIntegrationsApp,
  HideAddressApp,
  HideAnywhereApp,
  SegmentDetailsSideBar,
  StarredSegment,
  VerifiedSegmentModal,
  VisibilitySetting,
  ActivityDetailsMapWrapper,
  MapVisibilityControls,
  MapVisModal,
  MentionableComment,
  PerceivedExertionInput,
  ProgressGoalsRelativeEffortTab,
  CreateSegmentApp,
  Show,
  FeedbackApp,
  LimitedProfileEntry,
  FollowsList,
  Microfrontend,
  LcLBespokeSegmentProgress,
  ProgressGoalsSidebarTabContainer,
  MediaThumbnailList,
  AthleteProfileHeaderMediaGrid,
  CommentReactionList,
  AthleteName,
  ADPKudosAndComments,
  MediaUploader,
  PostsKudosAndComments,
  EditActivityButton
});

export { Microfrontend, ReactComponentMounter };
