import { types } from './actions';
import { followerStatus } from './const';

function prepareFollowerItem(follower) {
  return {
    ...follower,
    status: followerStatus.READY
  };
}

function updateFollowerStatus(state, action, status) {
  return state.followers.map((follower) => {
    if (follower.athleteId === action.athleteId) {
      return {
        ...follower,
        status
      };
    }
    return follower;
  });
}

export default function reducer(state = {}, action) {
  switch (action.type) {
    case types.TOGGLE_MODAL:
      return {
        ...state,
        isModalOpen: !state.isModalOpen
      };
    case types.HIDE_INTERSTITIAL:
      return {
        ...state,
        showInterstitial: false
      };
    case types.INVITE_SUBMITTING:
      return {
        ...state,
        followers: updateFollowerStatus(
          state,
          action,
          followerStatus.SUBMITTING
        )
      };
    case types.INVITE_COMPLETE:
      return {
        ...state,
        followers: updateFollowerStatus(state, action, followerStatus.COMPLETE)
      };
    case types.INVITE_ERROR:
      return {
        ...state,
        followers: updateFollowerStatus(state, action, followerStatus.ERROR)
      };
    case types.FOLLOWERS_LOADING:
      return {
        ...state,
        loading: true,
        error: ''
      };
    case types.FOLLOWERS_COMPLETE:
      return {
        ...state,
        followers: action.followers.map(prepareFollowerItem),
        loading: false,
        error: ''
      };
    case types.FOLLOWERS_ERROR:
      return {
        ...state,
        loading: true,
        error: action.error
      };
    default:
      return state;
  }
}
