import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'utils/I18n';
import { CONSTS, getTrainingImpactColor } from 'utils/relative-effort';

import styles from './styles.scss';

export const I18nPrefix = 'strava.relative_effort';

function getTrainingImpactText(trendingRatio) {
  let textKey = 'low';

  if (trendingRatio < CONSTS.TRAINING_IMPACT.LOWER_RATIO) {
    textKey = 'low';
  } else if (trendingRatio < CONSTS.TRAINING_IMPACT.UPPER_RATIO) {
    textKey = 'moderate';
  } else {
    textKey = 'high';
  }

  return I18n.t(`${I18nPrefix}.impact.${textKey}`);
}

const ActivitySummary = ({ trendingRatio, summary }) => {
  const trainingImpactColor = getTrainingImpactColor(trendingRatio);

  return (
    <div className={styles.base}>
      <h2 className={`text-title2 ${styles.title}`}>
        {I18n.t(`${I18nPrefix}.title`)}
        <span
          // .help.new-version here is needed here so that jQuery code can
          // pickup the click event and show the glossary modal
          className="help new-version"
          data-glossary-term="definition-suffer-score"
        >
          <span className="app-icon-wrapper">
            <span className={`app-icon icon-info-xsmall ${styles.infoIcon}`} />
          </span>
        </span>
      </h2>

      {summary && (
        <>
          <div className={styles.intensity}>
            <span
              data-testid="score"
              // TODO: remove text-demi when we fully roll out spandex
              className="text-display5 text-demi"
              style={{ color: trainingImpactColor }}
            >
              {summary.score}
            </span>
            {summary.label && (
              <span
                className={`text-display1 ${styles.bucket}`}
                style={{ color: trainingImpactColor }}
              >
                {summary.label}
              </span>
            )}
          </div>

          <div className={`text-callout ${styles.impact}`}>
            {getTrainingImpactText(trendingRatio)}
          </div>
        </>
      )}
    </div>
  );
};

ActivitySummary.propTypes = {
  trendingRatio: PropTypes.number.isRequired,
  summary: PropTypes.shape({
    score: PropTypes.number.isRequired,
    label: PropTypes.string
  })
};

export default ActivitySummary;
