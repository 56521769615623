import React from 'react';
import PropTypes from 'prop-types';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  Line,
  Area,
  ReferenceLine,
  ReferenceDot
} from 'recharts';
import Cldr from 'utils/Cldr';
import {
  getPrimaryColor,
  getSecondaryColor,
  getDateFromYearWeek,
  getFirstDayOfWeekFromDate
} from 'utils/relative-effort';

import styles from './styles.scss';

// Returns Monday date for a given year and week
function getDate(year, week) {
  const date = getDateFromYearWeek(year, week);
  const firstDayOfWeek = getFirstDayOfWeekFromDate(date);

  // Shift date to be the first day of the week (Monday)
  return new Date(date.setDate(firstDayOfWeek + 1));
}

class WeeklyGraph extends React.Component {
  constructor(props) {
    super(props);

    const { weeklyScores } = props;

    const rangeData = weeklyScores.map((d) => ({
      date: getDate(d.year, d.week).getTime(),
      score: d.cumulative_score,
      range: [d.buckets[0], d.buckets[2]]
    }));

    const scores = weeklyScores.map((w) => w.cumulative_score);
    const maxScore = Math.max(...scores);

    this.state = {
      rangeData,
      maxScore
    };
  }

  onMouseMove = (d) => {
    const { activeTooltipIndex, isTooltipActive } = d;
    const { selectedWeekIndex, snapToPoint } = this.props;

    if (!isTooltipActive) {
      return;
    }

    if (activeTooltipIndex === selectedWeekIndex) {
      return;
    }

    snapToPoint(activeTooltipIndex);
  };

  formatXAxisTick = (d) =>
    Cldr.formatDateTime(new Date(d), {
      format: 'additional',
      type: 'MMMd'
    });

  render() {
    const { weeklyScores, selectedWeekIndex, defaultWeekIndex } = this.props;
    const { rangeData, maxScore } = this.state;

    const tickColor = 'transparent';
    const tickTextStyle = {
      fill: '#6b6b76',
      fontSize: '7px'
    };

    const activeDotFillColor = getPrimaryColor(
      weeklyScores[selectedWeekIndex].cumulative_score,
      weeklyScores[selectedWeekIndex].buckets
    );
    const activeDotStrokeColor = getSecondaryColor(
      weeklyScores[selectedWeekIndex].cumulative_score,
      weeklyScores[selectedWeekIndex].buckets
    );

    const showCurrentWeekIndicator = defaultWeekIndex !== selectedWeekIndex;

    return (
      <div className={styles.container}>
        <ResponsiveContainer width="100%" height={110}>
          <ComposedChart
            data={rangeData}
            margin={{ top: 0, right: -28, left: -20 }}
            onMouseMove={this.onMouseMove}
          >
            <CartesianGrid horizontal={false} stroke="#fff" />
            <XAxis
              dy={2}
              dataKey="date"
              scale="point"
              tick={tickTextStyle}
              tickLine={{ stroke: tickColor }}
              stroke="none"
              tickFormatter={this.formatXAxisTick}
              minTickGap={0}
              interval={0}
            />
            <YAxis
              dx={2}
              orientation="right"
              tick={tickTextStyle}
              tickLine={{ stroke: tickColor }}
              stroke="none"
              type="number"
              domain={[0, (dataMax) => dataMax * 1.25]}
              ticks={[parseInt(maxScore * 0.5, 10), maxScore]}
            />
            <Area
              isAnimationActive={false}
              dataKey="range"
              stroke="#fff"
              fill="#fff"
              fillOpacity={1}
              activeDot={false}
            />
            <Line
              isAnimationActive={false}
              dataKey="score"
              stroke="none"
              dot={{
                fill: '#fff',
                stroke: '#000',
                strokeWidth: 1,
                r: 2.5
              }}
              activeDot={false}
            />
            <Tooltip content={() => {}} cursor={false} />
            {showCurrentWeekIndicator && (
              <ReferenceLine
                x={rangeData[defaultWeekIndex].date}
                stroke="#000"
                strokeWidth={1}
              />
            )}
            {showCurrentWeekIndicator && (
              <ReferenceDot
                x={rangeData[defaultWeekIndex].date}
                y={rangeData[defaultWeekIndex].score}
                fill="#000"
                stroke="none"
                r={3.5}
              />
            )}
            <ReferenceLine
              x={rangeData[selectedWeekIndex].date}
              stroke={activeDotFillColor}
              strokeWidth={1}
            />
            <ReferenceDot
              x={rangeData[selectedWeekIndex].date}
              y={rangeData[selectedWeekIndex].score}
              fill={activeDotFillColor}
              stroke={activeDotStrokeColor}
              strokeWidth={7}
              strokeOpacity={0.4}
              r={3.5}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

WeeklyGraph.propTypes = {
  defaultWeekIndex: PropTypes.number.isRequired,
  selectedWeekIndex: PropTypes.number.isRequired,
  weeklyScores: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  snapToPoint: PropTypes.func.isRequired
};

export default WeeklyGraph;
