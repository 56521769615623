import React from 'react';
import PropTypes from 'prop-types';

import { TabList as ReactTabList } from 'react-tabs';

import styles from './styles.scss';

const TabList = ({ children, className = '', ...props }) => (
  <ReactTabList className={`${styles.tabList} ${className}`} {...props}>
    {children}
  </ReactTabList>
);

TabList.tabsRole = 'TabList';

TabList.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default TabList;
