import React from 'react';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

import Tagging from './components/Tagging';
import reducer from './reducers';

class ActivityTagging extends React.Component {
  store = createStore(
    reducer,
    {
      ...this.props,
      followers: [],
      isModalOpen: false,
      showInterstitial: true,
      loading: false,
      error: ''
    },
    applyMiddleware(thunk)
  );

  render() {
    return (
      <Provider store={this.store}>
        <Tagging />
      </Provider>
    );
  }
}

export default ActivityTagging;
