// eslint-disable-next-line import/prefer-default-export
export const FEED_ENTRY_TYPES = Object.freeze({
  activity: 'activity',
  post: 'post',
  fancyPromo: 'fancy_promo',
  performanceGoalCreated: 'performance_goal_created',
  challenge: 'challenge',
  simplePromo: 'simple_promo',
  groupActivity: 'group_activity',
  suggestedActivity: 'suggested_activity',
  club: 'club'
});
