import { dateYearsAgo } from 'utils/generalUtils';

const GatingHelper = {};

GatingHelper.dateFromString = (str) => new Date(str);
GatingHelper.dateYearsAgo = (years) => {
  const d = new Date(new Date().toDateString());
  d.setFullYear(d.getFullYear() - years);
  return d;
};

GatingHelper.shouldShowAgeGateModal = (
  loggedIn,
  joined,
  dateOfBirth,
  gatingConditions
) => {
  if (joined) {
    return false;
  }

  if (!loggedIn) {
    return false;
  }

  const minimumAge = gatingConditions && gatingConditions.minimumAge;
  if (!minimumAge) {
    // no gating conditions
    return false;
  }

  const dob = dateOfBirth && GatingHelper.dateFromString(dateOfBirth);

  if (dob && dob <= dateYearsAgo(minimumAge)) {
    // old enough to join
    return false;
  }

  return true;
};

export default GatingHelper;
