export const YOUNGEST_AGE_ON_PLATFORM = 13;

export const YOUNGEST_AGE_FOR_HEALTH_AND_PROMO = 16;

export const GENDER = Object.freeze({
  man: 'man',
  woman: 'woman',
  preferNotSay: 'prefer_not_say',
  nonBinary: 'nonbinary'
});

export const LOCALE = Object.freeze({
  enUS: 'en-US',
  frFR: 'fr-FR',
  deDE: 'de-DE',
  esES: 'es-ES',
  ptBR: 'pt-BR',
  ruRU: 'ru-RU',
  itIT: 'it-IT',
  jaJP: 'ja-JP',
  es419: 'es-419',
  nlNL: 'nl-NL',
  zhTW: 'zh-TW',
  ptPT: 'pt-PT',
  zhCN: 'zh-CN'
});

export default {
  YOUNGEST_AGE_ON_PLATFORM,
  YOUNGEST_AGE_FOR_HEALTH_AND_PROMO,
  GENDER,
  LOCALE
};
