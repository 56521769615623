import React from 'react';

import I18n from 'utils/I18n';

import styles from './styles.scss';
import InfoModal from '../../../components/InfoModal/InfoModal';

const MapTypeInfo = () => {
  const MapTypeModal = (
    <>
      <div className={styles.modalHeader}>
        {I18n.t('templates.activities.edit.map_types.modal.title')}
      </div>
      <p>{I18n.t('templates.activities.edit.map_types.modal.text_1')}</p>
      <p>
        {I18n.t_html('templates.activities.edit.map_types.modal.text_2_html')}
      </p>
    </>
  );

  return <InfoModal> {MapTypeModal} </InfoModal>;
};

export default MapTypeInfo;
