import React from 'react';
import PropTypes from 'prop-types';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  Line,
  Area,
  ReferenceLine,
  ReferenceDot
} from 'recharts';
import Cldr from 'utils/Cldr';
import {
  getPrimaryColor,
  getSecondaryColor,
  getDateFromYearWeek,
  getFirstDayOfWeekFromDate
} from 'utils/relative-effort';

// Returns Monday date for a given year and week
function getDate(year, week) {
  const date = getDateFromYearWeek(year, week);
  const firstDayOfWeek = getFirstDayOfWeekFromDate(date);

  // Shift date to be the first day of the week (Monday)
  return new Date(date.setDate(firstDayOfWeek + 1));
}

class WeeklyGraph extends React.Component {
  constructor(props) {
    super(props);

    const { weeklyScores } = props;

    const rangeData = weeklyScores.map((d) => ({
      date: getDate(d.year, d.week).getTime(),
      score: d.cumulative_score,
      range: [d.buckets[0], d.buckets[2]]
    }));

    const scores = weeklyScores.map((w) => w.cumulative_score);
    const maxScore = Math.max(...scores);

    this.state = {
      rangeData,
      maxScore
    };
  }

  onMouseMove = (d) => {
    const { activeTooltipIndex, isTooltipActive } = d;
    const { selectedWeekIndex, snapToPoint } = this.props;

    if (!isTooltipActive) {
      return;
    }

    if (activeTooltipIndex === selectedWeekIndex) {
      return;
    }

    snapToPoint(activeTooltipIndex);
  };

  formatXAxisTick = (d) =>
    Cldr.formatDateTime(new Date(d), {
      format: 'additional',
      type: 'MMMd'
    });

  render() {
    const { weeklyScores, selectedWeekIndex, currentWeekIndex } = this.props;
    const { rangeData, maxScore } = this.state;

    const tickColor = '#efefef';
    const tickTextStyle = {
      fill: '#6b6b76',
      fontSize: '9px'
    };

    const activeDotFillColor = getPrimaryColor(
      weeklyScores[selectedWeekIndex].cumulative_score,
      weeklyScores[selectedWeekIndex].buckets
    );
    const activeDotStrokeColor = getSecondaryColor(
      weeklyScores[selectedWeekIndex].cumulative_score,
      weeklyScores[selectedWeekIndex].buckets
    );

    const showCurrentWeekIndicator = currentWeekIndex !== selectedWeekIndex;

    return (
      <ResponsiveContainer width="100%" height={160}>
        <ComposedChart
          data={rangeData}
          margin={{ top: 0, right: -20, left: 30 }}
          onMouseMove={this.onMouseMove}
        >
          <CartesianGrid horizontal={false} stroke={tickColor} />
          <XAxis
            dy={8}
            dataKey="date"
            scale="point"
            tick={tickTextStyle}
            tickLine={{ stroke: tickColor }}
            stroke="none"
            tickFormatter={this.formatXAxisTick}
            minTickGap={0}
          />
          <YAxis
            dx={4}
            orientation="right"
            tick={tickTextStyle}
            tickLine={{ stroke: tickColor }}
            stroke="none"
            type="number"
            domain={[0, (dataMax) => dataMax * 1.25]}
            ticks={[parseInt(maxScore * 0.5, 10), maxScore]}
          />
          <Area
            isAnimationActive={false}
            dataKey="range"
            stroke="#efefef"
            fill="#efefef"
            fillOpacity={1}
            activeDot={false}
          />
          <Line
            isAnimationActive={false}
            dataKey="score"
            stroke="none"
            dot={{
              fill: '#fff',
              stroke: '#000',
              strokeWidth: 1.4,
              r: 3
            }}
            activeDot={false}
          />
          <Tooltip content={() => {}} cursor={false} />
          {showCurrentWeekIndicator && (
            <ReferenceLine
              x={rangeData[currentWeekIndex].date}
              stroke="#000"
              strokeWidth={1}
            />
          )}
          {showCurrentWeekIndicator && (
            <ReferenceDot
              x={rangeData[currentWeekIndex].date}
              y={rangeData[currentWeekIndex].score}
              fill="#000"
              stroke="none"
              r={4}
            />
          )}
          <ReferenceLine
            x={rangeData[selectedWeekIndex].date}
            stroke={activeDotFillColor}
            strokeWidth={1}
          />
          <ReferenceDot
            x={rangeData[selectedWeekIndex].date}
            y={rangeData[selectedWeekIndex].score}
            fill={activeDotFillColor}
            stroke={activeDotStrokeColor}
            strokeWidth={12}
            strokeOpacity={0.4}
            r={5}
          />
        </ComposedChart>
      </ResponsiveContainer>
    );
  }
}

WeeklyGraph.propTypes = {
  currentWeekIndex: PropTypes.number.isRequired,
  selectedWeekIndex: PropTypes.number.isRequired,
  weeklyScores: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  snapToPoint: PropTypes.func.isRequired
};

export default WeeklyGraph;
