import types from './actions';

export default function reducer(state = {}, action) {
  switch (action.type) {
    case types.HEIGHT_REPORTED:
      return {
        ...state,
        options: [
          ...state.options.slice(0, action.optionIndex),
          { ...state.options[action.optionIndex], height: action.optionHeight },
          ...state.options.slice(action.optionIndex + 1)
        ]
      };

    case types.SETTING_COLLAPSED:
      return {
        ...state,
        isExpanded: false,
        selectedOptionIndex: state.savedOptionIndex
      };

    case types.SETTING_EXPANDED:
      return {
        ...state,
        isExpanded: true,
        selectedOptionHeight: state.options[state.selectedOptionIndex].height
      };

    case types.VALUE_CHANGED:
      if (state.isSubmittable) {
        return {
          ...state,
          selectedOptionIndex: action.optionIndex
        };
      }

      return {
        ...state,
        savedOptionIndex: action.optionIndex,
        selectedOptionIndex: action.optionIndex,
        isExpanded: false
      };

    case types.VALUE_SAVED_FAILURE:
      return state;

    case types.VALUE_SAVED_SUCCESS:
      return {
        ...state,
        isExpanded: false,
        savedOptionIndex: action.optionIndex
      };

    default:
      return state;
  }
}
