import React from 'react';
import Stats from '@strava/ui/Stats';
import Stat from '@strava/ui/Stat';

import I18n from 'utils/I18n';

import WeekChart from '../../WeeklySummary/WeekInsightStats/WeekChart';
import Placeholder from '../Placeholder';

import styles from './styles.scss';

const I18nPrefix = 'strava.relative_effort.week_insight_stats';

const HasAccess = () => (
  <>
    <div className={styles.head}>
      <Placeholder height={20} width="80%" className={styles.title} />

      <Placeholder height={13} className={styles.body} />
      <Placeholder height={13} width="90%" />
    </div>

    <div className="mb-md">
      <Placeholder height={16} width="30%" />
    </div>

    <div className={`${styles.statsContainer} mb-lg`}>
      <WeekChart dailyScores={[0, 0, 0, 0, 0, 0, 0]} />
      <Stats listClassName={styles.stats}>
        <Stat
          value={<Placeholder height={18} width={24} />}
          label={I18n.t(`${I18nPrefix}.score`)}
          wrapperClassName={styles.stat}
          valueClassName={styles.statValue}
          labelClassName={styles.statLabel}
        />
        <Stat
          value={<Placeholder height={18} width={76} />}
          label={I18n.t(`${I18nPrefix}.range`)}
          wrapperClassName={styles.stat}
          valueClassName={styles.statValue}
          labelClassName={styles.statLabel}
        />
      </Stats>
    </div>

    <Placeholder height={113} />
  </>
);

export default HasAccess;
