import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { color } from '@strava/ui-tokens/js';
import cx from '@strava/ui/clsx';
import Popup from '@strava/ui/Popup';
import ActionsVideoThumbnailNormalXsmall from '@strava/icons/ActionsVideoThumbnailNormalXsmall';
import ActionsVideoThumbnailNormalSmall from '@strava/icons/ActionsVideoThumbnailNormalSmall';
import ActionsPhotoNormalSmall from '@strava/icons/ActionsPhotoNormalSmall';
import NavigationWarningNormalSmall from '@strava/icons/NavigationWarningNormalSmall';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useDrag, useDrop } from 'react-dnd';
import Spinner from '@strava/ui/Spinner';
import styles from './Thumbnail.scss';
import PopupContents from './PopupContents';

function Thumbnail({
  file,
  index,
  highlightMediaId,
  setHighlightMedia,
  remove,
  loadingStates,
  uuid,
  mediaType,
  moveThumbnail
}) {
  const [portraitOrientation, setPortraitOrientation] = useState();
  const [isPreviewError, setIsPreviewError] = useState(false);

  const isPlaceHolderImage = (preview) =>
    preview === 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=';
  const [isPlaceHolder, setIsPlaceHolder] = useState(
    isPlaceHolderImage(file.preview)
  );

  const isPortrait = (img) => {
    const w = img.naturalWidth || img.width;
    const h = img.naturalHeight || img.height;
    return h > w;
  };

  // Get orientation of the thumbnail
  useEffect(() => {
    setIsPlaceHolder(isPlaceHolderImage(file.preview));
  }, [file.preview]);

  // Get orientation of the thumbnail
  useEffect(() => {
    const img = new Image();
    img.src = file.preview;
    img.onload = () => {
      setPortraitOrientation(isPortrait(img));
    };
  }, [file]);

  const ItemTypes = {
    THUMBNAIL: 'thumbnail'
  };

  const hasFailedToUpload = loadingStates[uuid] === -1;

  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.THUMBNAIL,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      const dragElement = document.getElementById(monitor.getItem().uuid);

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const dragBoundingRect = dragElement.getBoundingClientRect();

      // Get hovered image vertical middle position
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Get hovered image horizontal middle position
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Get pixels to the left
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;

      // Are we dragging right or left?
      const dragRight = dragIndex === hoverIndex - 1;
      const dragLeft = dragIndex === hoverIndex + 1;

      // Are we dragging up or down?
      const dragUp = dragBoundingRect.top > hoverBoundingRect.top;
      const dragDown = dragBoundingRect.bottom < hoverBoundingRect.bottom;

      // Only perform the move when the mouse has crossed half of the items height/width
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging right
      if (dragRight && dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return;
      }
      // Dragging left
      if (dragLeft && dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return;
      }
      // Dragging upwards
      if (dragUp && dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Dragging downwards
      if (dragDown && dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Change the state in MediaUploader
      moveThumbnail(dragIndex, hoverIndex);

      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    }
  });
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.THUMBNAIL,
    item: () => {
      return { uuid, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });
  drag(drop(ref));

  const videoIcon = () => {
    // Icon for Videos
    if (mediaType === 2 && !isPreviewError) {
      return (
        <ActionsVideoThumbnailNormalXsmall
          className={cx(
            styles.icon,
            portraitOrientation
              ? styles.portraitPlayIcon
              : styles.landscapePlayIcon
          )}
        />
      );
    }
    return null;
  };

  const thumbnailInnerIcon = () => {
    // Processing media file
    if (file && loadingStates[uuid] === 0) {
      return (
        <div
          className={cx(
            styles.icon,
            portraitOrientation ? '' : styles.landscapeIcon,
            isPreviewError || isPlaceHolder ? styles.noThumbnail : ''
          )}
        >
          <Spinner
            size={24}
            color={isPreviewError || isPlaceHolder ? 'default' : 'white'}
          />
        </div>
      );
    }

    // Only show the progress as it is loading
    if (
      file &&
      loadingStates[uuid] !== 100 &&
      !hasFailedToUpload &&
      loadingStates[uuid] > 0
    ) {
      return (
        <CircularProgressbar
          value={loadingStates[uuid]}
          text=""
          className={cx(
            styles.circleProgressBar,
            portraitOrientation ? '' : styles.landscapeIcon,
            isPreviewError || isPlaceHolder ? styles.noThumbnail : ''
          )}
          styles={buildStyles({
            pathColor:
              isPreviewError || isPlaceHolder
                ? color.colorCoreAsphalt
                : color.colorCoreWhite
          })}
        />
      );
    }

    // File Upload fails
    if (file && hasFailedToUpload) {
      return (
        <NavigationWarningNormalSmall
          className={cx(
            styles.icon,
            portraitOrientation ? '' : styles.landscapeIcon,
            isPreviewError || isPlaceHolder ? styles.noLandscape : ''
          )}
        />
      );
    }

    // Show media type icon when thumbnail is not generated
    if (isPreviewError && mediaType === 1 && loadingStates[uuid] === 100) {
      return (
        <ActionsPhotoNormalSmall
          className={cx(
            styles.icon,
            portraitOrientation ? '' : styles.landscapeIcon,
            isPreviewError || isPlaceHolder ? styles.noThumbnail : ''
          )}
        />
      );
    }
    if (isPreviewError && mediaType === 2 && loadingStates[uuid] === 100) {
      return (
        <ActionsVideoThumbnailNormalSmall
          className={cx(
            styles.icon,
            portraitOrientation ? '' : styles.landscapeIcon,
            isPreviewError || isPlaceHolder ? styles.noThumbnail : ''
          )}
        />
      );
    }
    return null;
  };

  return (
    <button
      className={cx(styles.thumbnailContainer)}
      key={file.name}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onKeyDown={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div className={styles.thumbnail}>
        <div
          className={cx(
            styles.spinnerContainer,
            isPreviewError || isPlaceHolder ? styles.noThumbnail : ''
          )}
        >
          <Popup
            className={isDragging ? styles.noShow : ''}
            contentsClassName={cx(
              styles.popupContents,
              hasFailedToUpload ? styles.singleButtonContents : ''
            )}
            contents={
              <PopupContents
                hasFailedToUpload={hasFailedToUpload}
                mediaType={mediaType}
                remove={remove}
                uuid={uuid}
                index={index}
                setHighlightMedia={setHighlightMedia}
                highlightMediaId={highlightMediaId}
              />
            }
            position="top"
            flip={false}
          >
            <div
              className={cx(
                portraitOrientation ? '' : styles.landscapeContainer,
                isPreviewError || isPlaceHolder ? styles.noLandscape : '',
                isPreviewError && hasFailedToUpload
                  ? styles.failedToUploadNoPreview
                  : '',
                !isPreviewError && hasFailedToUpload
                  ? styles.failedToUploadWithPreview
                  : ''
              )}
            >
              <img
                key={file.name}
                src={file.preview}
                className={cx(
                  styles.thumbnailImage,
                  highlightMediaId === uuid ? styles.highlight : '',
                  portraitOrientation ? styles.portrait : styles.landscape,
                  isPreviewError ? styles.noImage : '',
                  !isPreviewError && hasFailedToUpload
                    ? styles.failedToUploadWithPreviewOpacity
                    : '',
                  isPlaceHolder ? styles.placeholderImage : ''
                )}
                // Revoke data uri after image is loaded
                alt={file.name}
                data-handler-id={handlerId}
                ref={ref}
                id={uuid}
                onError={() => setIsPreviewError(true)}
              />
            </div>
          </Popup>
          {videoIcon()}
          {thumbnailInnerIcon()}
        </div>
      </div>
      {/* Needed to pass values to update activity call */}
      <input type="hidden" name={`photos[${uuid}][rank]`} value={index} />
      <input
        type="hidden"
        name={`photos[${uuid}][media_type]`}
        value={mediaType}
      />
      <input
        type="hidden"
        name={`photos[${uuid}][caption]`}
        value={file.caption}
      />
    </button>
  );
}

Thumbnail.propTypes = {
  file: PropTypes.shape({
    preview: PropTypes.string,
    name: PropTypes.string,
    caption: PropTypes.string
  }).isRequired,
  index: PropTypes.number.isRequired,
  highlightMediaId: PropTypes.string.isRequired,
  setHighlightMedia: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  loadingStates: PropTypes.shape({}).isRequired,
  uuid: PropTypes.string.isRequired,
  mediaType: PropTypes.number.isRequired,
  moveThumbnail: PropTypes.func.isRequired
};

export default Thumbnail;
