import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@strava/ui/Avatar';

import I18n from 'utils/I18n';
import createNetworkingClient from 'utils/networking-client';

import Button from '@strava/ui/Button';
import { snakeCase } from 'lodash-es';

import styles from './mentionable_comment.scss';
import {
  entitiesWithKudosAndComments,
  viewingAthleteProps
} from '../../Feed/props/feed-prop-types';
import { useKudosAndCommentsContext } from '../KudosCommentsAndAchievements/KudosAndCommentsContext';
import {
  ADD_COMMENT_FAILED,
  ADD_COMMENT_START,
  ADD_COMMENT_COMPLETE
} from '../KudosCommentsAndAchievements/kudosAndComments.actions';
import { FEED_ENTRY_TYPES } from '../../Feed/constants';
import StravaMentionsInput from '../../StravaMentionsInput';
import { logError } from '../../../utils/sentry';

// Remaps the given surface to the correct surface
// type for the fetchMentionableEntities endpoint
export const remapSurfaceType = (surface) => {
  if (surface === 'post') {
    return 'post_comment';
  }
  if (surface === 'activity') {
    return 'comment';
  }
  return null;
};

const MentionableCommentForm = ({
  viewingAthlete,
  entity,
  entityId,
  placeHolder,
  buttonText,
  clubMentionsEnabled,
  suggestionLimit = 5,
  autoFocus = false,
  parent = 'feed_card'
}) => {
  const [comment, setComment] = useState('');
  const [clearInputToggle, setClearInputToggle] = useState(false);

  const placeHolderProp =
    placeHolder || I18n.t('templates.feed.comment_box.add_a_comment');
  const buttonTextProp =
    buttonText || I18n.t('templates.feed.comment_box.post');

  const { avatarUrl, name, id: viewingAthleteId, memberType } = viewingAthlete;

  const {
    kudosAndCommentsEntityState,
    dispatch
  } = useKudosAndCommentsContext();

  // group activities use the same endpoint as activities
  const entityEndpoint =
    entity === FEED_ENTRY_TYPES.groupActivity
      ? FEED_ENTRY_TYPES.activity
      : snakeCase(entity);

  // Parses comment tokens into a string that can be shown to the user
  // example input:
  //     [{
  //         "type": "mention_token",
  //         "text": "Athlete Name",
  //         "path": "/athletes/10",
  //         "athlete_id": 1
  //     },
  //     {
  //         "type": "raw_token",
  //         "text": " text"
  //     }]
  //
  // output: <a href=/athletes/10>Athlete Name</a><span> text</span>
  const parseComment = (data) => {
    if (typeof data === 'string') {
      return data;
    }
    const formattedTokens = data.map((token) => {
      if (token.type === 'mention_token') {
        return `<a href=${token.path}>${token.text}</a>`;
      }
      return `<span>${token.text}</span>`;
    });
    return formattedTokens.join('');
  };

  const handleSubmitCommentComplete = (data) => {
    setComment('');
    setClearInputToggle(!clearInputToggle);

    const commentEntry = { ...data };
    commentEntry.comment = parseComment(data.comment);
    dispatch({
      type: ADD_COMMENT_COMPLETE,
      payload: { comment: commentEntry, entityId }
    });
  };

  const handleSubmitCommentFail = (error) => {
    logError(error);
    dispatch({ type: ADD_COMMENT_FAILED });
  };

  const submitComment = (e) => {
    kudosAndCommentsEntityState.trackAnalytics({
      action: 'click',
      element: `post_comment_btn-on-${parent}`
    });
    e.preventDefault();
    dispatch({ type: ADD_COMMENT_START });
    createNetworkingClient()
      .post(`/feed/${entityEndpoint}/${entityId}/comment`, {
        comment
      })
      .then((response) => {
        if (response && response.status === 200) {
          handleSubmitCommentComplete(response.data);
        } else {
          handleSubmitCommentFail();
        }
      })
      .catch((error) => {
        handleSubmitCommentFail(error);
      });
  };

  const handleChange = ({ newValue }) => {
    setComment(newValue);
  };

  return (
    <div
      className={styles.mentionableCommentWrapper}
      data-testid="comments-form"
    >
      <div className={styles.avatar}>
        <Avatar
          src={avatarUrl}
          type="athlete"
          name={name}
          href={`/athletes/${viewingAthleteId}`}
          badge={memberType}
          size="xsmall"
          onClick={() => {
            kudosAndCommentsEntityState.trackAnalytics({
              action: 'click',
              element: 'profile_picture',
              properties: {
                element_source: `comments-form-viewing-athlete-on-${parent}`
              }
            });
          }}
        />
      </div>
      <StravaMentionsInput
        autoFocus={autoFocus}
        entity={entity}
        entityId={entityId}
        onInputChange={handleChange}
        surfaceType={remapSurfaceType(entity)}
        placeHolder={placeHolderProp}
        clubMentionsEnabled={clubMentionsEnabled}
        suggestionLimit={suggestionLimit}
        trackAnalytics={kudosAndCommentsEntityState.trackAnalytics}
        viewingAthleteId={viewingAthleteId}
        clearInputToggle={clearInputToggle}
      />
      <div className={styles.postCommentBtn}>
        <Button
          type="button"
          data-testid="post-comment-btn"
          variant="text"
          disabled={
            kudosAndCommentsEntityState.isSubmittingComment ||
            comment.length === 0
          }
          onClick={submitComment}
        >
          {buttonTextProp}
        </Button>
      </div>
    </div>
  );
};

MentionableCommentForm.propTypes = {
  entity: entitiesWithKudosAndComments.isRequired,
  entityId: PropTypes.string.isRequired,
  placeHolder: PropTypes.string,
  clubMentionsEnabled: PropTypes.bool.isRequired,
  buttonText: PropTypes.string,
  suggestionLimit: PropTypes.number,
  autoFocus: PropTypes.bool,
  viewingAthlete: viewingAthleteProps.isRequired,
  parent: PropTypes.string
};

export default MentionableCommentForm;
