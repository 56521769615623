import { split } from 'lodash-es';

export { timeout } from './devUtils';

export const generateRandomId = () =>
  Math.random()
    .toString(36)
    .substring(2);

// Capitalizes all the words and replaces some characters in the string to create a nicer looking title.
// eg. 'man from the boondocks' => 'Man From The Boondocks'
const titleize = (str) =>
  str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );

export const displayName = (
  { firstName = '', lastName = '' },
  options = { forceAnonymize: false, maxLength: 100 }
) => {
  const { maxLength, forceAnonymize } = options;
  const fullName = `${firstName} ${lastName}`;

  if (forceAnonymize || fullName.length > maxLength) {
    if (firstName.length > maxLength - 3) {
      return `${titleize(
        firstName.substring(0, maxLength - 4)
      )}... ${lastName.charAt(0).toUpperCase()}.`;
    }
    return `${titleize(firstName)} ${lastName.charAt(0).toUpperCase()}.`;
  }
  return fullName.trim();
};

export const isMobile = () => window.navigator.userAgent.includes('Mobile');

export const dateYearsAgo = (years) => {
  const d = new Date(new Date().toDateString());
  d.setFullYear(d.getFullYear() - years);
  return d;
};

export const parseServerDateString = (date) => {
  const dobArray = split(date, '-');
  return {
    year: parseInt(dobArray[0], 10),
    month: parseInt(dobArray[1], 10),
    day: parseInt(dobArray[2], 10)
  };
};

// eg. given,
//  "urls": {
//     "100": "https://photo_url_1",
//     "1800": "https://photo_url_2"
// }
// returns https://photo_url_2
export const getPhotoWithMaxDimension = (photoUrlHash) => {
  const key = Object.keys(photoUrlHash).reduce((a, b) => (a > b ? a : b));
  return photoUrlHash[key];
};

export default {
  displayName,
  generateRandomId,
  isMobile,
  dateYearsAgo,
  parseServerDateString,
  getPhotoWithMaxDimension
};
