import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, TabList, Tab } from '@strava/ui/Tabs';
import DropdownSelect from '@strava/ui/DropdownSelect';
import BadgesMulticolorSummitXsmall from '@strava/icons/BadgesMulticolorSummitXsmall';
import styles from './styles.scss';

const CategoryTabs = ({
  currentCategory,
  currentSubcategory,
  categories = { empty: { title: 'warning - no categories specified' } },
  onTabChangeCallback = () => {},
  onFilterChangeCallback = () => {}
}) => {
  const currentCategoryProps = categories[currentCategory];

  const currentCategoryHasFilters =
    currentSubcategory &&
    currentCategoryProps &&
    currentCategoryProps.subCategories;

  let filterOptions;
  if (currentCategoryHasFilters) {
    const { subCategories } = currentCategoryProps;
    filterOptions = Object.keys(subCategories).map((key) => ({
      value: key,
      label: subCategories[key]
    }));
  }

  return (
    <Tabs onChange={onTabChangeCallback}>
      <div className={styles.container}>
        <div className={styles.tabs}>
          <TabList>
            {Object.keys(categories).map((tabKey) => (
              <Tab key={tabKey} className="text-caption1">
                <div className={styles.tabLabel}>
                  {categories[tabKey].premium && (
                    <div className={styles.badge} data-testid="premium">
                      <BadgesMulticolorSummitXsmall />
                    </div>
                  )}
                  <div data-testid="tab">{categories[tabKey].title}</div>
                </div>
              </Tab>
            ))}
          </TabList>
        </div>
        <div
          className={styles.subCategoryFilter}
          data-testid="filter-container"
        >
          {currentCategoryHasFilters && (
            <DropdownSelect
              options={filterOptions}
              onChange={(option) => onFilterChangeCallback(option.value)}
              value={
                filterOptions.filter((o) => o.value === currentSubcategory)[0]
              }
              ariaLabel="filter"
            />
          )}
        </div>
      </div>
    </Tabs>
  );
};

CategoryTabs.propTypes = {
  categories: PropTypes.shape({}),
  onFilterChangeCallback: PropTypes.func,
  onTabChangeCallback: PropTypes.func,
  currentCategory: PropTypes.string,
  currentSubcategory: PropTypes.string
};

export default CategoryTabs;
