import React from 'react';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

import reducer from './reducers';
import StarToggle from './components/StarToggle';

// this component wraps the StarToggle component with redux
class StarredSegment extends React.Component {
  store = createStore(
    reducer,
    {
      ...this.props,
      isActive: true
    },
    applyMiddleware(thunk)
  );

  render() {
    return (
      <Provider store={this.store}>
        <StarToggle />
      </Provider>
    );
  }
}

export default StarredSegment;
