import React from 'react';
import PropTypes from 'prop-types';
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider';

import Handle from './Handle';
import Track from './Track';
import Tick from './Tick';

import styles from './styles.scss';

const RPESlider = ({
  entityId,
  domain,
  values,
  disabled = false,
  onUpdate = () => {},
  onChange = () => {}
}) => (
  <Slider
    disabled={disabled}
    mode={1}
    step={1}
    domain={domain}
    onChange={onChange}
    onUpdate={onUpdate}
    values={values}
    className={styles.slider}
  >
    <div className={styles.graphicWrap}>
      <div className={styles.rail} />
    </div>

    <Tracks left={false}>
      {({ tracks }) => (
        <div className={styles.graphicWrap}>
          {tracks.map(({ id, source, target }) => (
            <Track key={id} source={source} target={target} />
          ))}
        </div>
      )}
    </Tracks>

    <Ticks count={domain[1]}>
      {({ ticks }) => (
        <div className={`${styles.graphicWrap} ${styles.ticks}`}>
          {ticks.map((tick) => (
            <Tick key={tick.id} tick={tick} count={ticks.length} />
          ))}
        </div>
      )}
    </Ticks>

    {/*
      whe need to use Rail component here so that we can add getRailProps
      which will add mouse interections
    */}
    <Rail>
      {({ getRailProps }) => (
        <div className={styles.graphicWrap}>
          <div className={styles.railHitarea} {...getRailProps()} />
        </div>
      )}
    </Rail>

    <Handles>
      {({ handles, getHandleProps }) => (
        <div className={styles.graphicWrap}>
          {handles.map((handle) => (
            <Handle
              entityId={entityId}
              key={handle.id}
              handle={handle}
              domain={domain}
              getHandleProps={getHandleProps}
            />
          ))}
        </div>
      )}
    </Handles>
  </Slider>
);

RPESlider.propTypes = {
  entityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  disabled: PropTypes.bool,
  domain: PropTypes.arrayOf(Number).isRequired,
  values: PropTypes.arrayOf(Number).isRequired,
  onUpdate: PropTypes.func,
  onChange: PropTypes.func
};

export default RPESlider;
