import React from 'react';
import PropTypes from 'prop-types';
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider';
import { trackV2 } from 'utils/analytics';

import Handle from './Handle';
import Track from './Track';
import Tick from './Tick';
import styles from './styles.scss';

const StreamPrivacySlider = ({
  defaultStreamPrivacy,
  domain,
  onChange,
  numTicks
}) => {
  return (
    <div className={styles.wrapper}>
      <Slider
        mode={1}
        step={200}
        domain={domain}
        onSlideEnd={(values) => {
          onChange(values[0]);
          trackV2({
            category: 'privacy_settings',
            page: 'hide_any_start_end',
            action: 'click',
            element: 'slider',
            properties: { distance: values[0] }
          });
        }}
        values={[defaultStreamPrivacy.meters_value]}
        className={styles.slider}
      >
        <div className={styles.graphicWrap}>
          <div className={styles.rail} />
        </div>

        <Tracks right={false}>
          {({ tracks }) => (
            <div className={styles.graphicWrap}>
              {tracks.map(({ id, source, target }) => (
                <Track key={id} source={source} target={target} />
              ))}
            </div>
          )}
        </Tracks>

        <Ticks count={numTicks}>
          {({ ticks }) => (
            <div className={`${styles.graphicWrap} ${styles.ticks}`}>
              {ticks.map((tick) => (
                <Tick key={tick.id} tick={tick} count={ticks.length} />
              ))}
            </div>
          )}
        </Ticks>

        {/*
          whe need to use Rail component here so that we can add getRailProps
          which will add mouse interactions
        */}
        <Rail>
          {({ getRailProps }) => (
            <div className={styles.graphicWrap}>
              <div className={styles.railHitarea} {...getRailProps()} />
            </div>
          )}
        </Rail>

        <Handles>
          {({ handles, getHandleProps }) => (
            <div className={`${styles.graphicWrap} ${styles.handle}`}>
              {handles.map((handle) => (
                <Handle
                  key={handle.id}
                  handle={handle}
                  domain={domain}
                  getHandleProps={getHandleProps}
                />
              ))}
            </div>
          )}
        </Handles>
      </Slider>
    </div>
  );
};

StreamPrivacySlider.propTypes = {
  defaultStreamPrivacy: PropTypes.shape({
    meters_value: PropTypes.number,
    luggage_value: PropTypes.string
  }).isRequired,
  domain: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChange: PropTypes.func.isRequired,
  numTicks: PropTypes.number.isRequired
};

export default StreamPrivacySlider;
