import React, { useEffect, useState } from 'react';
import Button from '@strava/ui/Button';
import PropTypes from 'prop-types';
import createNetworkingClient from 'utils/networking-client';
import I18n from 'utils/I18n';
import { trackV2 } from 'utils/analytics';
import { useKudosAndCommentsContext } from '../KudosAndCommentsContext';
import {
  ADD_COMMENT_REACTION,
  OPEN_COMMENT_REACTION_LIST,
  UNDO_COMMENT_REACTION
} from '../kudosAndComments.actions';
import styles from './styles.scss';
import CommentReactionIcon from '../CommentReactionIcons/CommentReactionIcon';
import { logError } from '../../../../utils/sentry';

const CommentReaction = ({
  hasReacted,
  commentReactionCount,
  commentId,
  entityId,
  analyticsProps
}) => {
  const {
    dispatch,
    kudosAndCommentsEntityState
  } = useKudosAndCommentsContext();
  const [canCommentReactStatus, setCanCommentReactStatus] = useState(
    !hasReacted
  );
  const [reactionCount, setReactionCount] = useState(commentReactionCount);

  useEffect(() => {
    if (kudosAndCommentsEntityState.recentlyReactedCommentId === commentId) {
      setCanCommentReactStatus(false);
    }

    if (kudosAndCommentsEntityState.recentlyUnReactedCommentId === commentId) {
      setCanCommentReactStatus(true);
    }
  }, [
    commentId,
    dispatch,
    kudosAndCommentsEntityState.recentlyReactedCommentId,
    kudosAndCommentsEntityState.recentlyUnReactedCommentId
  ]);

  const prefix = 'components.feed.social';

  const onClickViewCommentReactionList = () => {
    trackV2({
      category: analyticsProps.category,
      page: analyticsProps.page,
      action: 'click',
      element: analyticsProps.listElement,
      properties: { comment_id: commentId, activity_id: entityId }
    });
    dispatch({
      type: OPEN_COMMENT_REACTION_LIST,
      payload: { commentId, selectedEntityId: entityId }
    });
  };

  const undoCommentReaction = () => {
    createNetworkingClient()
      .delete(`/comments/${commentId}/reactions`)
      .then((response) => {
        if (response && response.status === 204) {
          setReactionCount((prevReactionCount) => prevReactionCount - 1);
          trackV2({
            category: analyticsProps.category,
            page: analyticsProps.page,
            action: 'click',
            element: analyticsProps.buttonElement,
            properties: {
              comment_id: commentId,
              activity_id: entityId,
              has_reacted: false
            }
          });
          setCanCommentReactStatus(true);
        }
        dispatch({
          type: UNDO_COMMENT_REACTION,
          payload: { commentId, entityId, reactionCount }
        });
      })
      .catch((error) => {
        logError(error);
      });
  };

  const postCommentReaction = () => {
    createNetworkingClient()
      .post(`/comments/${commentId}/reactions`)
      .then(() => {
        setReactionCount((prevReactionCount) => prevReactionCount + 1);
        trackV2({
          category: analyticsProps.category,
          page: analyticsProps.page,
          action: 'click',
          element: analyticsProps.buttonElement,
          properties: {
            comment_id: commentId,
            activity_id: entityId,
            has_reacted: true
          }
        });
        dispatch({
          type: ADD_COMMENT_REACTION,
          payload: { commentId, entityId, reactionCount }
        });
        setCanCommentReactStatus(false);
      })
      .catch((error) => {
        logError(error);
      });
  };

  return (
    <div className={styles.commentReactionContainer}>
      <Button
        aria-label="comment reaction icon"
        type="button"
        data-testid="comment-reaction-btn"
        className={styles.commentReactionButton}
        onClick={() =>
          canCommentReactStatus ? postCommentReaction() : undoCommentReaction()
        }
      >
        <CommentReactionIcon canCommentReactStatus={canCommentReactStatus} />
      </Button>
      {reactionCount > 0 && (
        <span className={styles.commentReactCounter}>
          <Button
            type="button"
            variant="text"
            data-testid="comment-reaction-count-btn"
            onClick={() => onClickViewCommentReactionList()}
            className={styles.viewCommentReactionsButton}
          >
            {I18n.t(`${prefix}.reaction_count`, {
              count: reactionCount
            })}
          </Button>
        </span>
      )}
    </div>
  );
};

CommentReaction.propTypes = {
  hasReacted: PropTypes.bool.isRequired,
  commentReactionCount: PropTypes.number.isRequired,
  commentId: PropTypes.number.isRequired,
  entityId: PropTypes.string.isRequired,
  analyticsProps: PropTypes.shape({
    category: PropTypes.string.isRequired,
    page: PropTypes.string.isRequired,
    buttonElement: PropTypes.string.isRequired,
    listElement: PropTypes.string.isRequired
  }).isRequired
};

export default CommentReaction;
