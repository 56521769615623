import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Markup } from 'interweave';
import { useHistory } from 'react-router-dom';

import I18n from 'utils/I18n';
import ButtonCTAWithCancel from 'components/ButtonCTAWithCancel';
import OptionList from '../OptionList';

function SurveyLanding({
  screen_name: screenName,
  title,
  subtitle,
  euWebLink,
  questions,
  handleCancel: handleCancelProp,
  handleNext
}) {
  const [selectedValue, setSelectedValues] = useState(null);
  const history = useHistory();
  const selections = useMemo(() => {
    const obj = {};
    questions.forEach((q) => {
      obj[q.type] = selectedValue === q.type;
    });
    return obj;
  }, [selectedValue]);

  const handleCTA = useCallback(() => {
    const { externalPath } = questions.find((o) => o.type === selectedValue);

    handleNext({ selections });

    if (externalPath) {
      window.location.href = externalPath;
    } else {
      history.push(selectedValue);
    }
  }, [selections]);

  const handleCancel = useCallback(() => {
    handleCancelProp({ selections });
  }, [selections]);

  return (
    <>
      <h2 className="text-title1 text-book mb-0">{screenName}</h2>
      <hr className="mt-lg mb-lg" style={{ borderColor: '#d5d5d5' }} />
      <h3 className="text-title2 mt-0 mb-lg">{title}</h3>
      {subtitle && <p className="mb-lg">{subtitle}</p>}
      {euWebLink && <Markup tagName="p" className="mb-lg" content={euWebLink} />}

      <OptionList
        type="single_select"
        options={questions.map(({ type, text }) => ({
          value: type,
          text
        }))}
        selectedValues={[selectedValue]}
        onChange={(values) => setSelectedValues(values[0])}
      />

      <Markup
        tagName="p"
        content={
          I18n.t('strava.feedback.additional_information', {
            url: 'https://support.strava.com/hc/en-us/articles/216917777-How-Do-I-Contact-Strava-Support'
          })
        }
      />

      <div className="mt-lg" style={{ display: 'inline-block' }}>
        <ButtonCTAWithCancel
          onCancel={handleCancel}
          onCTA={handleCTA}
          ctaVariant="next"
          ctaDisabled={!selectedValue}
        />
      </div>
    </>
  );
}

SurveyLanding.propTypes = {
  screen_name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  euWebLink: PropTypes.string,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      externalPath: PropTypes.string
    })
  ).isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired
};

export default SurveyLanding;
