import { types } from './actions';

export default function segmentStarReducer(state = {}, action) {
  switch (action.type) {
    // if the action was successful, toggle the state of the starred segment
    case types.TOGGLE_SEGMENT_STAR_SUCCESS:
      return {
        ...state,
        isActive: !state.isActive
      };
    // otherwise keep the state the same (we could add error state here to
    // render an error message)
    case types.TOGGLE_SEGMENT_STAR_FAILURE:
      return state;
    default:
      return state;
  }
}
