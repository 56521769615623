import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@strava/ui/Button';
import { color } from '@strava/ui-tokens/js';
import {
  DropdownMenu,
  DropdownButton,
  DropdownList,
  DropdownLink,
  DropdownItem
} from '@strava/ui/Dropdown';
import SvgActionsKudoNormalSmall from '@strava/icons/ActionsKudoNormalSmall';
import SvgActionsKudoHighlightedSmall from '@strava/icons/ActionsKudoHighlightedSmall';
import SvgActionsCommentNormalSmall from '@strava/icons/ActionsCommentNormalSmall';
import SvgNavigationMoreHighlightedSmall from '@strava/icons/NavigationMoreHighlightedSmall';
import Cldr from 'utils/Cldr';
import I18n from 'utils/I18n';
import KudosAndCommentsContext from '../../../components/shared/KudosCommentsAndAchievements/KudosAndCommentsContext';
import {
  OPEN_MODAL_KUDOS_TAB,
  OPEN_MODAL_COMMENTS_TAB
} from '../../../components/shared/KudosCommentsAndAchievements/kudosAndComments.actions';
import { handleKudosAndCommentsNotificationLinkNavigation } from '../../../components/shared/utils/handleKudosAndCommentsNotificationLinkNavigation';
import {
  commentsProps,
  viewingAthleteProps
} from '../../../components/Feed/props/feed-prop-types';
import KudosCommentsAndAchievementsModal from '../../../components/shared/KudosCommentsAndAchievements/KudosCommentsAndAchievementsModal/KudosCommentsAndAchievementsModal';
import useKudosAndCommentsReducer from '../../../hooks/kudosAndComments/useKudosAndCommentsReducer';
import CommentDeleteAlertDialog from '../../../components/shared/KudosCommentsAndAchievements/CommentDeleteAlertDialog';
import onClickKudo from '../../../components/shared/utils/onClickKudo';
import EntityDeleteAlertDialog from '../../../components/Feed/components/shared/EntityDeleteAlertDialog';
import styles from './styles.scss';
import { FEED_ENTRY_TYPES } from '../../../components/Feed/constants';

const PostsKudosAndComments = ({
  canKudo,
  commentsEnabled,
  comments,
  editPostPath,
  kudosCount,
  postEditable,
  entityId,
  viewingAthlete,
  post,
  authorAvatarUrl,
  authorDisplayName,
  ownedByCurrentAthlete,
  isDeletable = false,
  clubMentionsEnabled
}) => {
  const [canKudoStatus, setCanKudoStatus] = useState(canKudo);
  const [currentKudoCount, setCurrentKudoCount] = useState(kudosCount);
  const [hasKudoed, setHasKudoed] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const entity = 'post';

  const commentReactionAnalytics = {
    category: 'post_detail',
    page: FEED_ENTRY_TYPES.post,
    buttonElement: 'like_comment_btn_on_modal',
    listElement: 'like_list_btn_on_modal'
  };

  const { kudosAndCommentsEntityState, dispatch } = useKudosAndCommentsReducer(
    entityId,
    entity,
    comments
  );

  const providerState = {
    kudosAndCommentsEntityState,
    dispatch
  };

  // Using comments from kudosAndCommentsEntityState rather than ruby props
  // allows us to know when a new comment has been posted
  const entityStateComments = kudosAndCommentsEntityState.comments[entityId];

  const onClickKudoSuccessful = useCallback(() => {
    // Not allowed to kudo more than once
    setCanKudoStatus(false);
    setHasKudoed(true);
  }, []);

  useEffect(() => {
    if (kudosAndCommentsEntityState.kudoPosted) {
      setCurrentKudoCount((prev) => prev + 1);
      onClickKudoSuccessful();
    }
  }, [kudosAndCommentsEntityState.kudoPosted, dispatch, onClickKudoSuccessful]);

  useEffect(() => {
    handleKudosAndCommentsNotificationLinkNavigation({ entityId, dispatch });
  }, [dispatch, entityId]);

  const entityOwner = {
    displayImage: authorAvatarUrl,
    name: authorDisplayName,
    profileUrl: `/athletes/${post.athlete_id}`,
    type: post.parent_type === 'Club' ? 'club' : 'athlete'
  };

  return (
    <>
      <KudosAndCommentsContext.Provider value={providerState}>
        <div className={styles.kudosAndCommentsContainer}>
          <div className={styles.kudosContainer}>
            <Button
              data-testid="give-kudos-btn"
              className={styles.kudoIconButton}
              onClick={
                canKudoStatus
                  ? () =>
                      onClickKudo({
                        entityEndpoint: entity,
                        entityId,
                        dispatch,
                        onClickKudoSuccessful
                      })
                  : () =>
                      dispatch({
                        type: OPEN_MODAL_KUDOS_TAB,
                        payload: {
                          element: 'kudo_icon',
                          selectedEntityId: entityId
                        }
                      })
              }
            >
              {canKudoStatus || ownedByCurrentAthlete ? (
                <SvgActionsKudoNormalSmall data-testid="unfilled_kudos" />
              ) : (
                <SvgActionsKudoHighlightedSmall
                  fill={color.colorCoreO3}
                  data-testid="filled_kudos"
                  className={hasKudoed ? styles.kudoedIcon : styles.kudoIcon}
                />
              )}
            </Button>
            <Button
              className={styles.kudoCountBtn}
              onClick={() =>
                dispatch({
                  type: OPEN_MODAL_KUDOS_TAB,
                  payload: {
                    element: 'kudo_icon',
                    selectedEntityId: entityId
                  }
                })
              }
            >
              <span data-testid="kudos_count">
                {Cldr.formatDecimal(currentKudoCount)}
              </span>
            </Button>
          </div>
          {commentsEnabled && (
            <div className={styles.commentsContainer}>
              <Button
                className={styles.commentsButton}
                data-testid="open_comment_modal_button"
                onClick={() =>
                  dispatch({
                    type: OPEN_MODAL_COMMENTS_TAB,
                    payload: { element: 'comment', selectedEntityId: entityId }
                  })
                }
              >
                <SvgActionsCommentNormalSmall
                  className={styles.commentIcon}
                  fill={color.colorExtendedNeutralN4}
                />
                <div
                  className={styles.commentsCount}
                  data-testid="comments_count"
                >
                  {Cldr.formatDecimal(entityStateComments.length)}
                </div>
              </Button>
            </div>
          )}
          <div className={styles.report} data-testid="report-drop-down">
            <DropdownMenu className={styles.optionsMenu}>
              <DropdownButton className={styles.actionBtn}>
                <SvgNavigationMoreHighlightedSmall
                  fill={color.colorExtendedNeutralN4}
                />
              </DropdownButton>
              <DropdownList>
                {postEditable ? (
                  <DropdownLink href={editPostPath}>
                    {I18n.t('components.discussions.posts.edit')}
                  </DropdownLink>
                ) : (
                  <DropdownLink href={`/posts/${post.id}/feedback`}>
                    <span data-testid="report-btn">
                      {I18n.t(`templates.kudos_and_comments_modal.report`)}
                    </span>
                  </DropdownLink>
                )}
                {isDeletable && (
                  <DropdownItem
                    data-testid="delete-btn"
                    onClick={() => setIsDeleteModalOpen(true)}
                  >
                    {I18n.t('templates.generic.delete')}
                  </DropdownItem>
                )}
              </DropdownList>
            </DropdownMenu>
          </div>
          {(kudosAndCommentsEntityState.openKudosCommentModal ||
            kudosAndCommentsEntityState.isViewingCommentReactionsList) &&
            kudosAndCommentsEntityState.selectedEntityId === entityId && (
              <KudosCommentsAndAchievementsModal
                entity={entity}
                entityId={entityId}
                entityOwner={entityOwner}
                hasOtherAthletes={entity === 'group_activity'}
                title={post.summary}
                viewingAthlete={viewingAthlete}
                clubMentionsEnabled={clubMentionsEnabled}
                kudosCount={currentKudoCount}
                commentsEnabled={commentsEnabled}
                segAndBestEffortAchievements={[]}
                commentReactionAnalytics={commentReactionAnalytics}
              />
            )}
          <CommentDeleteAlertDialog />
          <EntityDeleteAlertDialog
            entityId={post.id.toString()}
            isDeleteModalOpen={isDeleteModalOpen}
            setIsDeleteModalOpen={setIsDeleteModalOpen}
            entityParentType={post.parent_type}
            clubOrAthleteId={post.parent_id}
          />
        </div>
      </KudosAndCommentsContext.Provider>
    </>
  );
};

PostsKudosAndComments.propTypes = {
  canKudo: PropTypes.bool.isRequired,
  comments: commentsProps.isRequired,
  kudosCount: PropTypes.number.isRequired,
  entityId: PropTypes.string.isRequired,
  editPostPath: PropTypes.string.isRequired,
  postEditable: PropTypes.bool.isRequired,
  commentsEnabled: PropTypes.bool.isRequired,
  viewingAthlete: viewingAthleteProps.isRequired,
  ownedByCurrentAthlete: PropTypes.bool.isRequired,
  post: PropTypes.shape({
    announcement: PropTypes.bool.isRequired,
    athlete_id: PropTypes.number.isRequired,
    comments_enabled: PropTypes.number.isRequired,
    created_at: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    parent_id: PropTypes.number.isRequired,
    parent_type: PropTypes.string.isRequired,
    post: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired
  }).isRequired,
  authorAvatarUrl: PropTypes.string.isRequired,
  authorDisplayName: PropTypes.string.isRequired,
  isDeletable: PropTypes.bool,
  clubMentionsEnabled: PropTypes.bool.isRequired
};

export default PostsKudosAndComments;
