import React from 'react';
import PropTypes from 'prop-types';
import Spinner from '@strava/ui/Spinner';

import createNetworkingClient from 'utils/networking-client';
import I18n from 'utils/I18n';
import MediaQuery, { breakpoints } from 'utils/media-query';

import Modal from 'components/shared/Modal';
import { Tabs, TabList, Tab, TabPanel } from 'components/shared/tabs';

import Email from '../Email';
import StravaInvite from '../StravaInvite';

import styles from './styles.scss';

export const I18nPrefix = 'strava.challenges.challenge_detail';

class ShareModal extends React.Component {
  state = {
    friends: [],
    friendsRequested: false,
    errorMessage: ''
  };

  onClose = () => {
    const { closeModal } = this.props;

    this.setState({
      friendsRequested: false,
      errorMessage: ''
    });

    closeModal();
  };

  fetchAthletes = () => {
    const { friends } = this.state;

    if (!friends || friends.length < 1) {
      this.setState({ friendsRequested: true });
      const instance = createNetworkingClient();

      instance
        .get('/share/autocomplete_athlete_data')
        .then((response) => {
          if (response && response.status === 200) {
            this.fetchAthletesSuccess(response.data);
          } else {
            this.fetchAthletesFail();
          }
        })
        .catch(() => {
          this.fetchAthletesFail();
        });
    }
  };

  fetchAthletesSuccess = (data) => {
    this.setState({ friendsRequested: false, friends: data, errorMessage: '' });
  };

  fetchAthletesFail = () => {
    this.setState({
      friendsRequested: false,
      errorMessage: I18n.t(`${I18nPrefix}.error_message`)
    });
  };

  renderMessage = () => {
    const { errorMessage } = this.state;

    return errorMessage ? (
      <h2 className={`${styles.message} ${styles.error}`}>{errorMessage}</h2>
    ) : null;
  };

  render() {
    const { friends, friendsRequested } = this.state;

    const {
      modalIsOpen,
      challengeId,
      challengeLogoUrl,
      challengeName,
      currentAthlete
    } = this.props;

    return (
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={this.onClose}
        onAfterOpen={this.fetchAthletes}
        dialogClassName={styles.modalDialog}
      >
        <div className={styles.modalContent}>
          <button
            className={`btn btn-unstyled ${styles.closeButton}`}
            onClick={this.onClose}
          >
            <div className="app-icon icon-close icon-sm" />
          </button>
          <div className={styles.modalHeader}>
            <img
              src={challengeLogoUrl}
              alt={challengeName}
              className={styles.challengeLogo}
            />
            {/* Bigger screens - tablets & desktop */}
            <MediaQuery minWidth={breakpoints.screenXs}>
              <h3 className={`text-title3 ${styles.challengeName}`}>
                {challengeName}
              </h3>
            </MediaQuery>

            {/* Smaller screens - mobile */}
            <MediaQuery maxWidth={breakpoints.screenXs}>
              <h3 className={`text-headline ${styles.challengeName}`}>
                {challengeName}
              </h3>
            </MediaQuery>
          </div>
          <div>
            <Tabs>
              <TabList className={styles.tabList}>
                <Tab
                  className={styles.tab}
                  selectedClassName={styles.activeTab}
                >
                  <span className="app-icon-wrapper">
                    <span
                      className="app-icon icon-strava icon-lg icon-dark"
                      title="strava"
                    >
                      Strava
                    </span>
                  </span>
                  <MediaQuery minWidth={breakpoints.screenXs}>
                    {I18n.t(`${I18nPrefix}.invite_strava_friends`)}
                  </MediaQuery>
                </Tab>
              </TabList>
              <TabPanel className={styles.tabPanel}>
                {this.renderMessage()}
                {friendsRequested ? (
                  <div className={styles.spinner}>
                    <Spinner />
                  </div>
                ) : (
                  <StravaInvite
                    closeModal={this.onClose}
                    currentAthlete={currentAthlete}
                    challengeName={challengeName}
                    challengeId={challengeId}
                    friends={friends}
                  />
                )}
              </TabPanel>
              <TabPanel className={styles.tabPanel}>
                <Email
                  closeModal={this.onClose}
                  currentAthlete={currentAthlete}
                  challengeName={challengeName}
                  challengeId={challengeId}
                />
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </Modal>
    );
  }
}

ShareModal.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  challengeId: PropTypes.number.isRequired,
  challengeLogoUrl: PropTypes.string.isRequired,
  currentAthlete: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  challengeName: PropTypes.string.isRequired
};

export default ShareModal;
