import createNetworkingClient from 'utils/networking-client';

export async function fetchStreams({ id }) {
  const response = await createNetworkingClient({ timeout: 30 * 1000 }).get(
    `/activities/${id}/streams`,
    {
      params: {
        stream_types: ['latlng', 'altitude', 'distance']
      }
    }
  );

  if (!response || response.status !== 200) {
    throw new Error('Request failed');
  }

  return response.data;
}
export default {
  fetchStreams
};
