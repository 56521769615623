import React from 'react';

import Modal, { ModalBody, ModalActions } from '@strava/ui/Modal';
import Button from '@strava/ui/Button';
import createNetworkingClient from 'utils/networking-client';
import I18n from 'utils/I18n';

import { useKudosAndCommentsContext } from '../KudosAndCommentsContext';
import {
  DELETE_COMMENT_CANCEL,
  DELETE_COMMENT_COMPLETE
} from '../kudosAndComments.actions';
import { logError } from '../../../../utils/sentry';

const prefix = 'templates.comment_delete_dialog';

const CommentDeleteAlertDialog = () => {
  const {
    kudosAndCommentsEntityState,
    dispatch
  } = useKudosAndCommentsContext();

  const close = () => dispatch({ type: DELETE_COMMENT_CANCEL });
  const deleteComment = () => {
    const { comment, entityId } = kudosAndCommentsEntityState;
    const commentId = comment.comment_id;
    createNetworkingClient()
      .delete('/feed/comment', {
        data: { id: commentId }
      })
      .then(() => {
        dispatch({
          type: DELETE_COMMENT_COMPLETE,
          payload: { entityId, commentId }
        });
      })
      .catch((e) => {
        logError(e);
      });
  };

  return (
    <>
      <Modal
        isOpen={kudosAndCommentsEntityState.openCommentDeleteDialog}
        onDismiss={close}
        aria-label={I18n.t(`${prefix}.are_you_sure`)}
      >
        <ModalBody>{I18n.t(`${prefix}.are_you_sure`)}</ModalBody>
        <ModalActions>
          <Button
            onClick={close}
            data-testid="cancel-delete-comment-btn"
            type="button"
          >
            {I18n.t(`${prefix}.cancel`)}
          </Button>
          <Button
            variant="primary"
            onClick={deleteComment}
            type="button"
            data-testid="confirm-delete-comment-btn"
          >
            {I18n.t(`${prefix}.ok`)}
          </Button>
        </ModalActions>
      </Modal>
    </>
  );
};

export default CommentDeleteAlertDialog;
