import React from 'react';
import PropTypes from 'prop-types';

import Cldr from 'utils/Cldr';
import I18n from 'utils/I18n';
import reUtils from 'utils/relative-effort';

import styles from './styles.scss';

export const I18nPrefix = 'strava.relative_effort';

const DateRange = ({ date }) => {
  const firstDayOfWeek = reUtils.getFirstDayOfWeekFromDate(date);

  const monday = Cldr.formatDateTime(
    new Date(date.setDate(firstDayOfWeek + 1)),
    {
      format: 'additional',
      type: 'MMMd'
    }
  );
  const sunday = Cldr.formatDateTime(
    new Date(date.setDate(firstDayOfWeek + 7)),
    {
      format: 'additional',
      type: 'MMMd'
    }
  );

  const isCurrentWeek = reUtils.isCurrentWeek(date);

  return (
    <div className={`text-caption2 ${styles.dateRange}`}>
      {isCurrentWeek
        ? I18n.t(`${I18nPrefix}.this_week_uppercase`)
        : `${monday} - ${sunday}`}
    </div>
  );
};

DateRange.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired
};

export default DateRange;
