import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

class ActionsDropdown extends React.Component {
  state = {
    expanded: false,
    actions: this.props.actions
  };

  toggleMenu = () => {
    this.setState((prev) => ({
      expanded: !prev.expanded
    }));
  };

  render() {
    return (
      <div className={styles.dropdownMenu}>
        <div
          role="button"
          aria-label="Actions"
          tabIndex="0"
          className={styles.moreIcon}
          onClick={() => this.toggleMenu()}
          onKeyDown={() => this.toggleMenu()}
        />
        {this.state.expanded && (
          <ul className={styles.dropdownList}>
            {this.state.actions.map((action) => (
              <li key={action.text}>
                <button
                  className={styles.dropdownItem}
                  onClick={() => action.onClick()}
                >
                  {action.text}
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

ActionsDropdown.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default ActionsDropdown;
