import React from 'react';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-textarea-autosize';
import Button from '@strava/ui/Button';

import createNetworkingClient from 'utils/networking-client';
import I18n from 'utils/I18n';

import styles from './styles.scss';

const I18nPrefix = 'components.comments';

class CreateComment extends React.Component {
  state = {
    value: '',
    isSubmitting: false,
    focused: false
  };

  toggleFocus = () => {
    this.setState((prevState) => ({ focused: !prevState.focused }));
  };

  handleChange = (event) => {
    this.setState({ value: event.target.value });
  };

  submitComment = () => {
    const { value } = this.state;
    const { parentId } = this.props;

    const instance = createNetworkingClient();

    const commentPath = `/posts/${parentId}/comments`;

    this.setState({ isSubmitting: true });

    // The only validation we need for discussions is that there is text
    // Might be overkill to need this, though
    if (!value.trim()) {
      this.onSubmitFail();
      return;
    }

    instance
      .post(
        commentPath,
        { text: value },
        { headers: { Accept: 'text/javascript' } }
      )
      .then((response) => {
        if (response && response.status === 200) {
          this.onSubmitSuccess(response.data);
        } else {
          this.onSubmitFail();
        }
      })
      .catch(() => {
        this.onSubmitFail();
      });
  };

  onSubmitSuccess = (data) => {
    const { onCreateSuccess } = this.props;

    this.setState({ value: '', isSubmitting: false });
    onCreateSuccess(data);
  };

  onSubmitFail = () => {
    this.setState({ isSubmitting: false });
    throw new Error('Your comment submission failed. Please try again');
  };

  render() {
    const { value, isSubmitting, focused } = this.state;

    return (
      <div className={styles.container}>
        <div className={styles.formLayout}>
          <div className={styles.inputWrapper}>
            <TextareaAutosize
              id="create-post"
              className={styles.input}
              placeholder={I18n.t(`${I18nPrefix}.create_a_comment`)}
              value={value}
              onChange={this.handleChange}
              onFocus={this.toggleFocus}
              onBlur={this.toggleFocus}
            />
          </div>
        </div>
        <Button
          disabled={isSubmitting || !value.trim()}
          className={`btn btn-sm ${(value.trim() || focused) &&
            styles.showButton}`}
          onClick={this.submitComment}
        >
          {I18n.t(`${I18nPrefix}.reply`)}
        </Button>
      </div>
    );
  }
}

CreateComment.propTypes = {
  onCreateSuccess: PropTypes.func.isRequired,
  parentId: PropTypes.number.isRequired
};

export default CreateComment;
