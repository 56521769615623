import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'utils/I18n';

import styles from './styles.scss';
import WeekInsight from './WeekInsight';
import WeekChart from './WeekChart';
import WeeklyGraph from './WeeklyGraph';
import Activities from './Activities';

const I18nPrefix = 'strava.relative_effort';

class WeeklySummary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedWeekIndex: props.selectedWeekIndex
    };
  }

  snapToPoint = (index) => {
    this.setState({ selectedWeekIndex: index });
  };

  onMouseEnter = () => {
    clearTimeout(this.mouseLeaveTimer);
  };

  onMouseLeave = () => {
    const { selectedWeekIndex: currentWeekIndex } = this.props;

    clearTimeout(this.mouseLeaveTimer);
    this.mouseLeaveTimer = setTimeout(() => {
      this.setState({ selectedWeekIndex: currentWeekIndex });
    }, 250);
  };

  render() {
    const { selectedWeekIndex } = this.state;
    const {
      weeklyScores,
      currentActivityId,
      selectedWeekIndex: currentWeekIndex
    } = this.props;

    const selectedWeek = weeklyScores[selectedWeekIndex];

    return (
      <div className={styles.base}>
        <div className={styles.weekDetails}>
          <div className={styles.weekChart}>
            <h3 className={`text-title3 ${styles.title}`}>
              {I18n.t(`${I18nPrefix}.weekly_effort`)}
            </h3>
            <WeekChart dailyScores={selectedWeek.daily_scores} />
          </div>
          <div className={styles.weekInsight}>
            <WeekInsight
              year={selectedWeek.year}
              week={selectedWeek.week}
              cumulativeScore={selectedWeek.cumulative_score}
              buckets={selectedWeek.buckets}
            />
          </div>
        </div>

        <div className={styles.divider} />

        <div onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
          <div className={styles.weeklyGraph}>
            <WeeklyGraph
              currentWeekIndex={currentWeekIndex}
              selectedWeekIndex={selectedWeekIndex}
              weeklyScores={weeklyScores}
              snapToPoint={this.snapToPoint}
            />
          </div>

          {selectedWeek.activities.length > 0 && (
            <>
              <div className={styles.divider} />
              <Activities
                currentActivityId={currentActivityId}
                activities={selectedWeek.activities}
              />
            </>
          )}
        </div>
      </div>
    );
  }
}

WeeklySummary.propTypes = {
  currentActivityId: PropTypes.string.isRequired,
  weeklyScores: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedWeekIndex: PropTypes.number.isRequired
};

export default WeeklySummary;
