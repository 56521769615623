import * as React from 'react';
import PropTypes from 'prop-types';
import { color as uiToken } from '@strava/ui-tokens/js';
import HeartIconUnfilled from './HeartIconUnfilled';
import HeartIconFilled from './HeartIconFilled';

const CommentReactionIcon = ({ canCommentReactStatus }) => {
  const color = canCommentReactStatus
    ? uiToken.colorExtendedNeutralN2
    : uiToken.colorCoreO3;

  return canCommentReactStatus ? (
    <HeartIconUnfilled color={color} size={16} />
  ) : (
    <HeartIconFilled color={color} size={16} />
  );
};

CommentReactionIcon.propTypes = {
  canCommentReactStatus: PropTypes.bool.isRequired
};

export default CommentReactionIcon;
