import React, { useEffect, useRef } from 'react';
import ReactSelect from 'react-select';
import PropTypes, { number, string } from 'prop-types';
import { isMobile } from 'utils/generalUtils';

import { color } from '@strava/ui-tokens/js';

const StravaDropdownSelect = ({
  name,
  isFocused = false,
  hasError = false,
  value,
  options,
  ariaLabel,
  className,
  disabled = false,
  onChange = () => {},
  placeholder,
  styles = {},
  ...props
}) => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current && isFocused) {
      ref.current.focus();
    }
  }, [isFocused]);

  const controlColor = (provided) =>
    hasError ? color.colorExtendedRedR2 : provided.borderColor;

  const controlBoxShadow = (provided, state) => {
    if (hasError) {
      return '';
    }
    if (state.isFocused) {
      return `0 0 0 1px #${color.colorExtendedNeutralN2}`;
    }
    return provided.boxShadow;
  };

  const customStyles = (() => {
    return {
      indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none'
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        padding: '2px'
      }),
      valueContainer: (provided) => ({
        ...provided,
        padding: '5px',
        height: '50px',
        fontSize: '17px',
        color: controlColor(provided)
      }),
      control: (provided, state) => ({
        ...provided,
        borderColor: controlColor(provided),
        boxShadow: controlBoxShadow(provided, state)
      }),
      ...styles
    };
  })();

  const theme = (provided) => ({
    ...provided,
    colors: {
      ...provided.colors,
      primary25: color.colorExtendedNeutralN5,
      primary: color.colorExtendedNeutralN2,
      danger: color.colorExtendedRedR2
    }
  });

  return (
    <ReactSelect
      classNamePrefix="react-select"
      ref={ref}
      className={className}
      options={options}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      styles={customStyles}
      ariaLabel={ariaLabel}
      data-cy={name}
      name={name}
      isDisabled={disabled}
      isSearchable={!isMobile()}
      theme={theme}
      {...props}
    />
  );
};

const optionProps = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.oneOfType([number, string])
});

StravaDropdownSelect.propTypes = {
  name: PropTypes.string.isRequired,
  isFocused: PropTypes.bool,
  hasError: PropTypes.bool,
  value: optionProps,
  disabled: PropTypes.bool,
  ariaLabel: PropTypes.string.isRequired,
  className: PropTypes.string,
  options: PropTypes.arrayOf(optionProps).isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  styles: PropTypes.shape({})
};

export default StravaDropdownSelect;
