import React from 'react';
import Avatar from '@strava/ui/Avatar';
import PropTypes from 'prop-types';
import I18n from 'utils/I18n';
import styles from './index.scss';

const CommentReactionList = ({ reactionListData = [] }) => {
  const { athletes, reactionCount } = reactionListData;
  const prefix = 'components.feed.social';
  const otherCommentReactors =
    reactionCount > athletes.length ? reactionCount - athletes.length : null;

  if (!athletes.length) {
    return (
      <div
        className={styles.errorText}
        data-testid="comment_reaction_list_error"
      >
        {I18n.t('components.feed.social.comment_reaction_list_error')}
      </div>
    );
  }
  return (
    <div>
      {athletes.map((athlete) => (
        <div key={athlete.id} className={styles.rowContent}>
          <Avatar
            badge={athlete.member_type}
            href={athlete.url}
            name={athlete.name}
            size="small"
            src={athlete.avatar_url}
            type="athlete"
            data-testid="comment_reaction_list_avatar"
          />
          <div className={styles.rowContentAthleteData}>
            <div className={styles.athleteName}>
              <a href={athlete.url}>{athlete.name}</a>
            </div>
            <div className={`text-caption1 ${styles.location}`}>
              {athlete.location}
            </div>
          </div>
        </div>
      ))}
      {otherCommentReactors && (
        <div
          className={styles.commentListOthers}
          data-testid="comment-list-others"
        >
          {I18n.t(`${prefix}.comment_list_others`, {
            count: otherCommentReactors
          })}
        </div>
      )}
    </div>
  );
};

CommentReactionList.propTypes = {
  reactionListData: PropTypes.shape({
    reactionCount: PropTypes.number,
    athletes: PropTypes.arrayOf(
      PropTypes.shape({
        member_type: PropTypes.string,
        name: PropTypes.string.isRequired,
        avatar_url: PropTypes.string,
        location: PropTypes.string,
        url: PropTypes.string,
        id: PropTypes.string.isRequired,
        is_private: PropTypes.bool,
        is_following: PropTypes.bool,
        firstName: PropTypes.string
      })
    )
  })
};

export default CommentReactionList;
