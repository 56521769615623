// Kudos And Comments Modal (with Kudos, Comments, Achievement, Other Athletes tabs)
export const OPEN_MODAL_COMMENTS_TAB = 'OPEN_MODAL_COMMENTS_TAB';
export const OPEN_MODAL_KUDOS_TAB = 'OPEN_MODAL_KUDOS_TAB';
export const OPEN_MODAL_ACHIEVEMENTS_TAB = 'OPEN_MODAL_ACHIEVEMENTS_TAB';
export const OPEN_MODAL_OTHER_ATHLETES_TAB = 'OPEN_MODAL_OTHER_ATHLETES_TAB';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const OPEN_COMMENT_REACTION_LIST = 'OPEN_COMMENT_REACTION_LIST';
export const CLOSE_COMMENT_REACTION_LIST = 'CLOSE_COMMENT_REACTION_LIST';

// Give Kudos (entity card, Kudos And Comments Modal)
export const ADD_KUDO = 'ADD_KUDO';

// Delete Comment
export const DELETE_COMMENT_CANCEL = 'DELETE_COMMENT_CANCEL';
export const DELETE_COMMENT_COMPLETE = 'DELETE_COMMENT_COMPLETE';
export const DELETE_COMMENT_START = 'DELETE_COMMENT_START';

// Add new comment
export const ADD_COMMENT_START = 'ADD_COMMENT_START';
export const ADD_COMMENT_COMPLETE = 'ADD_COMMENT_COMPLETE';
export const ADD_COMMENT_FAILED = 'ADD_COMMENT_FAILED';
export const ADD_COMMENT_REACTION = 'ADD_COMMENT_REACTION';
export const UNDO_COMMENT_REACTION = 'UNDO_COMMENT_REACTION';

export default {
  ADD_KUDO,
  ADD_COMMENT_REACTION,
  ADD_COMMENT_START,
  ADD_COMMENT_COMPLETE,
  ADD_COMMENT_FAILED,
  DELETE_COMMENT_CANCEL,
  DELETE_COMMENT_COMPLETE,
  DELETE_COMMENT_START,
  CLOSE_MODAL,
  OPEN_MODAL_COMMENTS_TAB,
  OPEN_MODAL_KUDOS_TAB,
  OPEN_MODAL_ACHIEVEMENTS_TAB,
  OPEN_MODAL_OTHER_ATHLETES_TAB,
  OPEN_COMMENT_REACTION_LIST,
  CLOSE_COMMENT_REACTION_LIST,
  UNDO_COMMENT_REACTION
};
