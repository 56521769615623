import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Modal, { ModalTitle, ModalBody, ModalActions } from '@strava/ui/Modal';
import Button from '@strava/ui/Button';

import { csrfToken } from 'utils/networking-client';
import I18n from 'utils/I18n';

import styles from './styles.scss';

const CorrectElevation = ({
  activityId,
  activeSource,
  lookupExists,
  adjusting
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModalOpen = () => {
    setIsModalOpen(!isModalOpen);
  };

  const formAction = lookupExists
    ? `/activities/${activityId}/swap_elevation_stream?from_source=${activeSource}`
    : `/activities/${activityId}/fetch_elevation?send_notification=true`;

  const modalContents = (
    <>
      <form action={formAction} method="post">
        <input type="hidden" name="authenticity_token" value={csrfToken()} />
        {lookupExists && <input type="hidden" name="_method" value="put" />}

        <ModalTitle>
          {I18n.t('templates.activities.elevation_correction.elevation_help')}
        </ModalTitle>

        {activeSource === 'lookup' ? (
          <>
            <ModalBody>
              {I18n.t_html(
                'templates.activities.elevation_correction.lookup_revert_v2_html',
                {
                  support_article_url:
                    'https://support.strava.com/hc/en-us/articles/216919447-Elevation-for-Your-Activity'
                }
              )}
            </ModalBody>

            <ModalActions>
              <Button type="submit" variant="default">
                {I18n.t(
                  'templates.activities.elevation_correction.correct_elevation_revert'
                )}
              </Button>
            </ModalActions>
          </>
        ) : (
          <>
            <ModalBody>
              {I18n.t_html(
                'templates.activities.elevation_correction.lookup_v2_html',
                {
                  support_article_url:
                    'https://support.strava.com/hc/en-us/articles/216919447-Elevation-for-Your-Activity'
                }
              )}
            </ModalBody>

            <ModalActions>
              <Button type="submit" variant="primary">
                {I18n.t(
                  'templates.activities.elevation_correction.correct_elevation'
                )}
              </Button>
            </ModalActions>
          </>
        )}
      </form>
    </>
  );

  const CorrectElevationModal = (
    <Modal
      isOpen={isModalOpen}
      onDismiss={toggleModalOpen}
      hasClose={true}
      className={styles.modal}
      aria-labelledby="correct-elevation-modal-title"
    >
      {adjusting ? (
        <>
          <ModalTitle id="correct-elevation-modal-title">
            {I18n.t('templates.activities.elevation_correction.elevation_help')}
          </ModalTitle>
          <ModalBody>
            {I18n.t_html(
              'templates.activities.elevation_correction.adjusting_html'
            )}
          </ModalBody>
        </>
      ) : (
        modalContents
      )}
    </Modal>
  );

  return (
    <>
      {
        // This is needed in order to make the link match all the others in the rails side menu
        // TODO this should be changed to a sidebar of buttons for accesibility once moved out of rails
      }
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a href="#" onClick={toggleModalOpen}>
        {activeSource === 'device'
          ? I18n.t(
              'templates.activities.elevation_correction.correct_elevation'
            )
          : I18n.t(
              'templates.activities.elevation_correction.correct_elevation_revert'
            )}
      </a>
      {CorrectElevationModal}
    </>
  );
};

CorrectElevation.propTypes = {
  activityId: PropTypes.number.isRequired,
  activeSource: PropTypes.string.isRequired,
  lookupExists: PropTypes.bool.isRequired,
  adjusting: PropTypes.bool.isRequired
};

export default CorrectElevation;
