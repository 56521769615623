import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.scss';

function OptionList({ currentValue = null, handleNext, handleValueChange, options }) {
  return (
    <ul className={styles.options}>
      {options.map((option) => (
        <Option
          {...option}
          key={option.value}
          isSelected={currentValue === option.value}
          handleNext={handleNext}
          valueChanged={handleValueChange}
        />
      ))}
    </ul>
  );
}

OptionList.propTypes = {
  currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  handleNext: PropTypes.func.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      detail: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
    })
  ).isRequired
};

function Option({
  value,
  title,
  detail,
  isSelected,
  handleNext,
  valueChanged
}) {
  return (
    <li>
      <label>
        <input
          type="radio"
          value={value}
          checked={isSelected}
          onChange={() => valueChanged(value, title)}
          onKeyPress={(e) => e.key === 'Enter' && isSelected && handleNext()}
          tabIndex={isSelected ? '0' : '-1'}
        />
        <div>
          <span className={styles.title}>{title}</span>
          <span className={styles.detail}>{detail}</span>
        </div>
      </label>
    </li>
  );
}

Option.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  title: PropTypes.string.isRequired,
  detail: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  handleNext: PropTypes.func.isRequired,
  valueChanged: PropTypes.func.isRequired
};

export default OptionList;
