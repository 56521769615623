import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.scss';

const AbbrUnit = ({ data }) => {
  return (
    <>
      {data.value}
      <abbr className={styles.unit} title={data.unit.unit_long}>
        {data.unit.unit_short}
      </abbr>
    </>
  );
};

AbbrUnit.propTypes = {
  data: PropTypes.shape({
    value: PropTypes.string,
    unit: PropTypes.shape({
      unit_long: PropTypes.string,
      unit_short: PropTypes.string
    })
  }).isRequired
};

export default AbbrUnit;
