import {
  OPEN_MODAL_KUDOS_TAB,
  OPEN_MODAL_COMMENTS_TAB,
  OPEN_MODAL_ACHIEVEMENTS_TAB,
  OPEN_MODAL_OTHER_ATHLETES_TAB,
  CLOSE_MODAL,
  ADD_KUDO,
  DELETE_COMMENT_CANCEL,
  DELETE_COMMENT_COMPLETE,
  DELETE_COMMENT_START,
  ADD_COMMENT_REACTION,
  ADD_COMMENT_START,
  ADD_COMMENT_COMPLETE,
  ADD_COMMENT_FAILED,
  OPEN_COMMENT_REACTION_LIST,
  CLOSE_COMMENT_REACTION_LIST,
  UNDO_COMMENT_REACTION
} from './kudosAndComments.actions';

export function initKudosAndCommentsState({
  entity,
  parentEntityId,
  entityId,
  openKudosCommentModal,
  selectedTabIndex,
  kudoPosted,
  newComment,
  openCommentDeleteDialog,
  trackAnalytics,
  comments,
  isViewingCommentReactionsList
}) {
  return {
    entity,
    parentEntityId,
    entityId,
    openKudosCommentModal,
    selectedTabIndex,
    kudoPosted,
    newComment,
    openCommentDeleteDialog,
    trackAnalytics,
    comments,
    isViewingCommentReactionsList
  };
}

export function kudosAndCommentsReducer(state, action) {
  switch (action.type) {
    case OPEN_MODAL_KUDOS_TAB: {
      const { element, selectedEntityId } = action.payload;
      state.trackAnalytics({
        action: 'click',
        element
      });
      return {
        ...state,
        openKudosCommentModal: true,
        selectedTabIndex: 0,
        selectedEntityId
      };
    }
    case OPEN_MODAL_COMMENTS_TAB: {
      const { element, selectedEntityId } = action.payload;
      state.trackAnalytics({
        action: 'click',
        element
      });
      return {
        ...state,
        openKudosCommentModal: true,
        selectedTabIndex: 1,
        selectedEntityId
      };
    }
    case OPEN_MODAL_ACHIEVEMENTS_TAB: {
      const { element, selectedEntityId } = action.payload;
      state.trackAnalytics({
        action: 'click',
        element
      });
      return {
        ...state,
        openKudosCommentModal: true,
        selectedTabIndex: 2,
        selectedEntityId
      };
    }
    case OPEN_MODAL_OTHER_ATHLETES_TAB: {
      const { element, selectedEntityId } = action.payload;
      state.trackAnalytics({
        action: 'click',
        element
      });
      return {
        ...state,
        openKudosCommentModal: true,
        selectedTabIndex: 3,
        selectedEntityId
      };
    }
    case CLOSE_MODAL:
      return {
        ...state,
        openKudosCommentModal: false,
        selectedTabIndex: 0,
        selectedEntityId: null
      };
    case ADD_KUDO:
      return { ...state, kudoPosted: true };
    case DELETE_COMMENT_START: {
      state.trackAnalytics({
        action: 'click',
        element: `delete-btn-on-${action.payload.element}`
      });
      const { entityId, comment } = action.payload;
      return {
        ...state,
        openCommentDeleteDialog: true,
        comment,
        entityId
      };
    }
    case DELETE_COMMENT_CANCEL:
      return {
        ...state,
        openCommentDeleteDialog: false,
        comment: {}
      };
    case DELETE_COMMENT_COMPLETE: {
      const { entityId, commentId } = action.payload;
      const entityComments = state.comments[entityId];
      return {
        ...state,
        openCommentDeleteDialog: false,
        comments: {
          ...state.comments,
          [entityId]: entityComments.filter(
            (comment) => comment.comment_id !== commentId
          )
        }
      };
    }
    case ADD_COMMENT_START: {
      return {
        ...state,
        isSubmittingComment: true
      };
    }
    case ADD_COMMENT_COMPLETE: {
      const { entityId, comment } = action.payload;
      const entityComments = state.comments[entityId];
      return {
        ...state,
        isSubmittingComment: false,
        comments: {
          ...state.comments,
          [entityId]: entityComments.concat(comment)
        }
      };
    }
    case ADD_COMMENT_FAILED:
      return {
        ...state,
        isSubmittingComment: false
      };
    case ADD_COMMENT_REACTION: {
      const { commentId, entityId, reactionCount } = action.payload;
      const entityComments = state.comments[entityId];

      return {
        ...state,
        commentReactionPosted: true,
        recentlyReactedCommentId: commentId,
        recentlyUnReactedCommentId: null,
        comments: {
          ...state.comments,
          [entityId]: entityComments.map((comment) => {
            if (comment.comment_id === commentId) {
              return {
                ...comment,
                reactions_summary: {
                  ...comment.reactions_summary,
                  has_reacted: true,
                  reaction_count: reactionCount + 1
                }
              };
            }
            return comment;
          })
        }
      };
    }
    case UNDO_COMMENT_REACTION: {
      const { commentId, entityId, reactionCount } = action.payload;
      const entityComments = state.comments[entityId];

      return {
        ...state,
        commentReactionPosted: false,
        recentlyUnReactedCommentId: commentId,
        recentlyReactedCommentId: null,
        comments: {
          ...state.comments,
          [entityId]: entityComments.map((comment) => {
            if (comment.comment_id === commentId) {
              return {
                ...comment,
                reactions_summary: {
                  ...comment.reactions_summary,
                  has_reacted: false,
                  reaction_count: reactionCount - 1
                }
              };
            }
            return comment;
          })
        }
      };
    }
    case OPEN_COMMENT_REACTION_LIST: {
      const { commentId, selectedEntityId } = action.payload;
      return {
        ...state,
        isViewingCommentReactionsList: true,
        selectedComment: commentId,
        selectedEntityId
      };
    }
    case CLOSE_COMMENT_REACTION_LIST: {
      const { selectedEntityId } = action.payload;
      return {
        ...state,
        isViewingCommentReactionsList: false,
        selectedEntityId,
        selectedTabIndex: 1,
        openKudosCommentModal: true
      };
    }

    default:
      throw new Error(`${action.type} - not recognised`);
  }
}

export default { initKudosAndCommentsState, kudosAndCommentsReducer };
