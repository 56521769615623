import React from 'react';
import PropTypes from 'prop-types';
import { Markup } from 'interweave';

import Modal, { ModalTitle, ModalBody, ModalActions } from '@strava/ui/Modal';
import Button from '@strava/ui/Button';

import I18n from 'utils/I18n';

import { YOUNGEST_AGE_ON_PLATFORM } from 'constants/appConstants';
import { dateYearsAgo } from 'utils/generalUtils';
import Helper from '../../../Show/components/ChallengeGalleryJoinButton/helper';
import GatingHelper from '../../helpers/gating_helper';
import DropdownDatePicker from '../../../../../components/shared/DropdownDatePicker';

import styles from './styles.scss';

const I18nPromptHeading = 'strava.challenges.age_gate.prompt.heading';
const I18nPromptText = 'strava.challenges.age_gate.prompt.text_html';
const I18nPromptCTA = 'strava.challenges.age_gate.prompt.cta';
const I18nPromptDismiss = 'strava.challenges.age_gate.prompt.dismiss';
const I18nPromptFooter = 'strava.challenges.age_gate.prompt.footer';
const I18nDeniedHeading = 'strava.challenges.age_gate.denied.heading';
const I18nDeniedText = 'strava.challenges.age_gate.denied.text';
const I18nDeniedDismiss = 'strava.challenges.age_gate.denied.dismiss';

const AgeGateModal = ({
  handleModalClose,
  handleDateSelectionChange,
  handleDateUpdateInProgress,
  handleDateUpdateSuccess,
  handleDateUpdateFailure,
  gatingModal,
  currentAthlete,
  locale
}) => {
  if (!gatingModal || !currentAthlete) {
    return null;
  }

  const {
    gatingConditions,
    ageGate,
    joinChallengeFn,
    requestInProgress
  } = gatingModal;

  const { dateOfBirth } = currentAthlete;
  const { minimumAge } = gatingConditions;

  if (!minimumAge) {
    return null;
  }
  if (
    dateOfBirth &&
    GatingHelper.dateFromString(dateOfBirth) > dateYearsAgo(minimumAge)
  ) {
    return (
      <Modal
        className={styles.modal}
        isOpen={true}
        onDismiss={handleModalClose}
        aria-labelledby="age-gate-modal-title"
      >
        <ModalTitle id="age-gate-modal-title">
          {I18n.t(I18nDeniedHeading, gatingConditions)}
        </ModalTitle>
        <ModalBody className={styles.deniedText}>
          {I18n.t(I18nDeniedText, gatingConditions)}
        </ModalBody>
        <ModalActions className={styles.controls}>
          <Button
            name="cancel"
            variant="primary"
            type="button"
            disabled={requestInProgress}
            onClick={handleModalClose}
          >
            {I18n.t(I18nDeniedDismiss)}
          </Button>
        </ModalActions>
      </Modal>
    );
  }

  const handleVerify = () => {
    const { dateSelected } = ageGate;
    const { id } = currentAthlete;

    const url = `/athletes/${id}`;
    const payload = {
      athlete: {
        'dateofbirth(1i)': dateSelected.getFullYear(),
        'dateofbirth(2i)': dateSelected.getMonth() + 1,
        'dateofbirth(3i)': dateSelected.getDate()
      }
    };

    handleDateUpdateInProgress();

    return Helper.instance()
      .put(url, payload, { headers: { Accept: 'text/javascript' } })
      .then(() => {
        handleDateUpdateSuccess(dateSelected);
        if (
          dateSelected > GatingHelper.dateYearsAgo(gatingConditions.minimumAge)
        ) {
          return;
        }
        joinChallengeFn();
      })
      .catch(() => handleDateUpdateFailure());
  };

  const dateSelected = ageGate && ageGate.dateSelected;
  const dateMax = dateYearsAgo(YOUNGEST_AGE_ON_PLATFORM);

  return (
    <Modal
      className={styles.modal}
      isOpen={true}
      onDismiss={handleModalClose}
      aria-labelledby="age-gate-modal-title"
    >
      <ModalTitle id="age-gate-modal-title">
        {I18n.t(I18nPromptHeading, gatingConditions)}
      </ModalTitle>
      <ModalBody className={styles.body}>
        <p className={styles.promptText}>
          <Markup tagName="span" content={I18n.t(I18nPromptText)} />
        </p>

        <div className={styles.controls}>
          <DropdownDatePicker
            className={styles.datepicker}
            disabled={requestInProgress}
            dateSelected={dateSelected}
            dateChangeCallback={(obj) => {
              const { month, day, year } = obj;
              let date;
              if (!month || !day || !year) {
                // Force the athlete DOB to be the minimum required date if a date less than the strava minimum age requirement was entered
                // There is an opportunity to handle this differently here:
                // possible alternatives - show an error message?, make drop-downs not required which would allow them
                // to be cleared in this scenario and we can show error state on them (red border) ...
                // This comment was added on update that changed the date-picker component used without modifying
                // current existing business logic.
                date = dateMax;
              } else {
                date = new Date(year, month - 1, day); // UI Jan => 1
              }
              handleDateSelectionChange(date);
            }}
            locale={locale}
            required={true}
            isSearchable={true}
            showFullMonth={true}
          />
          <Button
            name="verify"
            variant="primary"
            className={styles.btnModalVerify}
            disabled={requestInProgress}
            onClick={handleVerify}
          >
            {I18n.t(I18nPromptCTA)}
          </Button>
          <Button
            name="cancel"
            className={styles.btnModalDismiss}
            disabled={requestInProgress}
            onClick={handleModalClose}
          >
            {I18n.t(I18nPromptDismiss)}
          </Button>
        </div>
        <small>{I18n.t(I18nPromptFooter, gatingConditions)}</small>
      </ModalBody>
    </Modal>
  );
};

AgeGateModal.propTypes = {
  handleModalClose: PropTypes.func.isRequired,
  handleDateSelectionChange: PropTypes.func.isRequired,
  handleDateUpdateInProgress: PropTypes.func.isRequired,
  handleDateUpdateSuccess: PropTypes.func.isRequired,
  handleDateUpdateFailure: PropTypes.func.isRequired,
  gatingModal: PropTypes.shape({
    joinChallengeFn: PropTypes.func.isRequired,
    gatingConditions: PropTypes.shape({
      minimumAge: PropTypes.number
    }).isRequired,
    ageGate: {
      dateSelected: PropTypes.instanceOf(Date)
    }.isRequired,
    requestInProgress: PropTypes.bool.isRequired
  }),
  currentAthlete: PropTypes.shape({
    id: PropTypes.number.isRequired,
    dateOfBirth: PropTypes.string
  }),
  locale: PropTypes.string.isRequired
};

export default AgeGateModal;
