import { useEffect, useState, useRef } from 'react';
import I18n from 'utils/I18n';
import createNetworkingClient from 'utils/networking-client';

import { isEqual } from 'lodash-es';

export const STATUS = {
  idle: 'idle',
  pending: 'pending',
  resolved: 'resolved',
  rejected: 'rejected',
  restricted: 'restricted'
};

export const CATEGORIES = {
  gender: 'gender', // Overall tab has gender filters - overall, men, and women
  following: 'following',
  club: 'club',
  country: 'country',
  age: 'age',
  weight: 'weight'
};

export const PREMIUM_CATEGORIES = ['age', 'weight'];

const I18N_PREFIX = 'strava.challenges.challenge_detail.leaderboard';
const noResultsMsg = () => I18n.t(`${I18N_PREFIX}.no_results_found`);

const unprocessableClubMsg = () => I18n.t(`${I18N_PREFIX}.unprocessable_club`);
const errorState = () => {
  return {
    data: { entries: [], viewingAthletePublicEntry: null },
    status: STATUS.rejected,
    message: I18n.t(`${I18N_PREFIX}.error_message`)
  };
};

function useDeepCompareMemoize(value) {
  const prev = useRef();
  const id = useRef(0);
  if (!isEqual(value, prev.current)) {
    id.current += 1;
    prev.current = value;
  }
  return id.current;
}

const useFetchLeaderboardData = ({
  weightMeasurementUnit,
  challengeId,
  joined,
  subscribed,
  params
}) => {
  const [state, setState] = useState({
    status: STATUS.idle,
    data: {
      entries: [],
      viewingAthletePublicEntry: null
    },
    message: null
  });

  const buildParams = ({
    before,
    after,
    category,
    sub_category: subCategory,
    limit
  }) => {
    let paramCategory = category;
    if (category === 'weight') {
      paramCategory =
        weightMeasurementUnit === 'kg' ? 'metric_weight' : 'imperial_weight';
    }
    const base = {
      category: paramCategory,
      sub_category: subCategory,
      limit
    };

    if (after) {
      return {
        ...base,
        after_athlete_id: after.athlete_id,
        after_rank: after.rank,
        after_value: after.value
      };
    }
    if (before) {
      return {
        ...base,
        before_athlete_id: before.athlete_id,
        before_rank: before.rank,
        before_value: before.value
      };
    }
    return base;
  };

  useEffect(() => {
    if (!subscribed && PREMIUM_CATEGORIES.includes(params.category)) {
      setState({
        data: { entries: [], viewingAthletePublicEntry: null },
        status: STATUS.restricted,
        message: I18n.t(`${I18N_PREFIX}.subscribe_weight_and_age`)
      });
    } else if (
      params.category === CATEGORIES.club &&
      params.sub_category == null
    ) {
      // club subcategory not available, athlete is not in any clubs
      const noClubsState = {
        data: { entries: [], viewingAthletePublicEntry: null },
        status: STATUS.resolved,
        message: noResultsMsg()
      };
      setState(noClubsState);
    } else {
      setState({
        status: STATUS.pending,
        data: state.data
      });
      const url = `/frontend/challenges/${challengeId}/leaderboard`;
      createNetworkingClient()
        .get(url, { params: buildParams(params) })
        .then((response) => {
          if (response && response.status === 200) {
            const { showLeaderboard } = response.data;
            // all responses with leaderboards
            if (showLeaderboard) {
              const successState = {
                data: response.data,
                status: STATUS.resolved,
                message:
                  response.data.entries.length === 0 ? noResultsMsg() : null
              };
              setState(successState);
            }
            // large clubs that do not have leaderboards
            else if (!showLeaderboard && params.category === CATEGORIES.club) {
              const unprocessableClubState = {
                data: {
                  ...response.data,
                  entries: [],
                  viewingAthletePublicEntry: null
                },
                status: STATUS.resolved,
                message: unprocessableClubMsg()
              };
              setState(unprocessableClubState);
            } else {
              // fallback for all other requests that do not have leaderboards
              setState(errorState());
            }
          } else {
            // Response status codes other than 200
            setState(errorState());
          }
        })
        .catch(() => {
          setState(errorState());
        });
    }
  }, [challengeId, joined, useDeepCompareMemoize(params)]);

  return state;
};

export default useFetchLeaderboardData;
