import createNetworkingClient from '../../utils/networking-client';

const types = {
  HEIGHT_REPORTED: 'HEIGHT_REPORTED',
  SETTING_COLLAPSED: 'SETTING_COLLAPSED',
  SETTING_EXPANDED: 'SETTING_EXPANDED',
  VALUE_CHANGED: 'VALUE_CHANGED',
  VALUE_SAVED_FAILURE: 'VALUE_SAVED_FAILURE',
  VALUE_SAVED_REQUESTED: 'VALUE_SAVED_REQUESTED',
  VALUE_SAVED_SUCCESS: 'VALUE_SAVED_SUCCESS'
};

export const heightReported = (optionIndex, optionHeight) => ({
  type: types.HEIGHT_REPORTED,
  optionIndex,
  optionHeight
});

export const settingCollapsed = (analyticsReporter) => {
  analyticsReporter('inner', 'click', 'cancel');
  return { type: types.SETTING_COLLAPSED };
};

export const settingExpanded = (analyticsReporter, settingName) => {
  analyticsReporter('outer', 'click', settingName);
  return { type: types.SETTING_EXPANDED };
};

export const valueChanged = (optionIndex, optionValue, analyticsReporter) => {
  analyticsReporter('inner', 'click', optionValue);
  return {
    type: types.VALUE_CHANGED,
    optionIndex
  };
};

const valueSaveFailure = (optionIndex) => ({
  type: types.VALUE_SAVED_FAILURE,
  optionIndex
});

const valueSaveSuccess = (optionIndex) => ({
  type: types.VALUE_SAVED_SUCCESS,
  optionIndex
});

export const valueSaved = ({
  athleteId,
  settingName,
  optionIndex,
  optionValue,
  analyticsReporter
}) => (dispatch) => {
  dispatch({ type: types.VALUE_SAVED_REQUESTED });

  if (!athleteId) {
    dispatch(valueSaveFailure(optionIndex));
    return null;
  }

  analyticsReporter('inner', 'click', 'ok');

  const url = `/athletes/${athleteId}`;
  const payload = {
    athlete: {
      [settingName]: optionValue
    }
  };

  const instance = createNetworkingClient();
  return instance
    .put(url, payload, { headers: { Accept: 'text/javascript' } })
    .then(() => dispatch(valueSaveSuccess(optionIndex)))
    .catch(() => dispatch(valueSaveFailure(optionIndex)));
};

export default types;
