import createNetworkingClient from 'utils/networking-client';
import Analytics from 'utils/analytics';

export const types = {
  FETCH_CHALLENGE_PROGRESS_REQUESTED: 'FETCH_CHALLENGE_PROGRESS_REQUESTED',
  FETCH_CHALLENGE_PROGRESS_SUCCESS: 'FETCH_CHALLENGE_PROGRESS_SUCCESS',
  JOIN_CHALLENGE_FAILURE: 'JOIN_CHALLENGE_FAILURE',
  JOIN_CHALLENGE_REQUESTED: 'JOIN_CHALLENGE_REQUESTED',
  JOIN_CHALLENGE_SUCCESS: 'JOIN_CHALLENGE_SUCCESS',
  JOIN_CLUB_FAILURE: 'JOIN_CLUB_FAILURE',
  JOIN_CLUB_REQUESTED: 'JOIN_CLUB_REQUESTED',
  JOIN_CLUB_SUCCESS: 'JOIN_CLUB_SUCCESS',
  LEAVE_CHALLENGE_FAILURE: 'LEAVE_CHALLENGE_FAILURE',
  LEAVE_CHALLENGE_REQUESTED: 'LEAVE_CHALLENGE_REQUESTED',
  LEAVE_CHALLENGE_SUCCESS: 'LEAVE_CHALLENGE_SUCCESS',
  MODAL_OPEN: 'MODAL_OPEN',
  MODAL_CLOSE: 'MODAL_CLOSE',
  MODAL_CHANGE_DATE: 'MODAL_CHANGE_DATE',
  DOB_UPDATE_REQUESTED: 'DOB_UPDATE_REQUESTED',
  DOB_UPDATE_SUCCESS: 'DOB_UPDATE_SUCCESS',
  DOB_UPDATE_FAILED: 'DOB_UPDATE_FAILED'
};

const fetchChallengeProgressSuccess = (data) => ({
  type: types.FETCH_CHALLENGE_PROGRESS_SUCCESS,
  data
});

const fetchChallengeProgress = (challengeId) => (dispatch) => {
  dispatch({ type: types.FETCH_CHALLENGE_PROGRESS_REQUESTED });

  const instance = createNetworkingClient();
  return instance
    .get(`/challenges/${challengeId}/progress`)
    .then((response) => {
      const { data } = response;
      dispatch(fetchChallengeProgressSuccess(data));
    })
    .catch(() => window.location.reload());
};

const joinChallengeFailure = () => ({
  type: types.JOIN_CHALLENGE_FAILURE
});

const joinChallengeSuccess = (data) => ({
  type: types.JOIN_CHALLENGE_SUCCESS,
  data
});

export const joinChallenge = (challengeId) => (dispatch) => {
  dispatch({ type: types.JOIN_CHALLENGE_REQUESTED });

  Analytics.trackV2({
    action: 'click',
    element: 'join',
    category: 'challenges',
    page: 'challenge_details',
    properties: { challenge_id: challengeId }
  });

  const redirectUrl = window.location;

  const instance = createNetworkingClient();
  return instance
    .get(
      `/challenges/${challengeId}/login_and_join?redirect_url=${redirectUrl}`
    )
    .then((response) => {
      const { data } = response;
      if (data.redirect && data.redirectUrl) {
        window.location = data.redirectUrl;
      } else {
        dispatch(fetchChallengeProgress(challengeId));
        dispatch(joinChallengeSuccess(data));
      }
    })
    .catch(() => dispatch(joinChallengeFailure()));
};

const leaveChallengeFailure = () => ({
  type: types.LEAVE_CHALLENGE_FAILURE
});

const leaveChallengeSuccess = (data) => ({
  type: types.LEAVE_CHALLENGE_SUCCESS,
  data
});

export const leaveChallenge = (challengeId) => (dispatch) => {
  dispatch({ type: types.LEAVE_CHALLENGE_REQUESTED });

  Analytics.trackV2({
    action: 'click',
    element: 'leave',
    category: 'challenges',
    page: 'challenge_details',
    properties: { challenge_id: challengeId }
  });

  const instance = createNetworkingClient();
  return instance
    .post(`/challenges/${challengeId}/leave`)
    .then((response) => {
      const { data } = response;
      dispatch(leaveChallengeSuccess(data));
    })
    .catch(() => dispatch(leaveChallengeFailure()));
};

const joinClubFailure = () => ({
  type: types.JOIN_CLUB_FAILURE
});

const joinClubSuccess = (data) => ({
  type: types.JOIN_CLUB_SUCCESS,
  data
});

export const joinClub = (destUrl) => (dispatch) => {
  dispatch({ type: types.JOIN_CLUB_REQUESTED });

  const redirectUrl = window.location;

  const instance = createNetworkingClient();
  return instance
    .get(`${destUrl}?redirect_url=${redirectUrl}`)
    .then((response) => {
      const { data } = response;
      if (data.redirect && data.redirectUrl) {
        window.location = data.redirectUrl;
      } else {
        dispatch(joinClubSuccess(data));
      }
    })
    .catch(() => dispatch(joinClubFailure()));
};

export const showAgeModal = (
  gatingConditions,
  joinChallengeFn,
  dateSelected
) => (dispatch) => {
  dispatch({
    type: types.MODAL_OPEN,
    data: {
      gatingConditions,
      joinChallengeFn,
      dateSelected
    }
  });
};

export const handleModalClose = () => (dispatch) =>
  dispatch({ type: types.MODAL_CLOSE });

export const handleDateSelectionChange = (date) => (dispatch) =>
  dispatch({ type: types.MODAL_CHANGE_DATE, date });

export const handleDateUpdateInProgress = () => (dispatch) =>
  dispatch({ type: types.DOB_UPDATE_REQUESTED });

export const handleDateUpdateSuccess = (date) => (dispatch) =>
  dispatch({ type: types.DOB_UPDATE_SUCCESS, date });

export const handleDateUpdateFailure = () => (dispatch) =>
  dispatch({ type: types.DOB_UPDATE_FAILED });

export default types;
