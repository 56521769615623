import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import I18n from 'utils/I18n';
import createNetworkingClient from 'utils/networking-client';
import { trackV2 } from 'utils/analytics';
import ConfirmModal from '../../../../components/ConfirmModal/ConfirmModal';

import StreamPrivacySlider from './components/StreamPrivacySlider';
import styles from './styles.scss';

function HideAnywhereApp({
  defaultStreamPrivacy: streamPrivacyProp,
  streamPrivacyOptions,
  updateSettingsPath,
  isAthleteUnder18
}) {
  const I18nPrefix = 'strava.settings.privacy.hide_anywhere.';
  const distanceUnits =
    I18n.measurementUnits() === 'metric' ? 'meters' : 'miles';
  const [defaultStreamPrivacy, setDefaultStreamPrivacy] = useState(
    streamPrivacyProp
  );
  const newStreamPrivacyRef = useRef();
  const oldStreamPrivacyRef = useRef();

  const sliderLabels = streamPrivacyOptions.filter((s, i) => i % 2 === 0);

  const trackEvent = (action, element = null, properties = null) => {
    trackV2({
      category: 'privacy_settings',
      page: 'hide_any_start_end',
      action,
      ...(element && { element }),
      ...(properties && { properties })
    });
  };

  useEffect(() => {
    trackEvent('screen_enter');
  }, []);

  const [openModal, setOpenModal] = useState(false);

  const applySettingUpdate = () => {
    createNetworkingClient()
      .put(updateSettingsPath, {
        settings: {
          default_stream_privacy: newStreamPrivacyRef.current.luggage_value
        }
      })
      .catch(() => {
        setDefaultStreamPrivacy(oldStreamPrivacyRef.current);
      });
  };

  const handleSettingUpdate = (radius) => {
    oldStreamPrivacyRef.current = { ...defaultStreamPrivacy };
    newStreamPrivacyRef.current = streamPrivacyOptions.find(
      (r) => r.meters_value === radius
    );
    setDefaultStreamPrivacy(newStreamPrivacyRef.current);

    if (
      isAthleteUnder18 &&
      newStreamPrivacyRef.current.meters_value <
        oldStreamPrivacyRef.current.meters_value
    ) {
      setOpenModal(true);
    } else {
      applySettingUpdate();
    }
  };

  const closeModal = () => {
    setDefaultStreamPrivacy(oldStreamPrivacyRef.current);
    setOpenModal(false);
  };

  const confirmSettingUpdate = () => {
    applySettingUpdate();
    setOpenModal(false);
  };

  return (
    <div>
      <div className={styles.detail}>{I18n.t(`${I18nPrefix}detail_v2`)}</div>
      <div className={styles.sliderContainer}>
        <div className={styles.distanceHiddenLabel}>
          {defaultStreamPrivacy.meters_value === 0
            ? I18n.t(
                `strava.settings.privacy.distance.no_${distanceUnits}_hidden`
              )
            : I18n.t_html(
                `strava.settings.privacy.distance.${distanceUnits}_hidden`,
                {
                  distance: I18n.t(defaultStreamPrivacy.label)
                }
              )}
        </div>
        <ConfirmModal
          isOpen={openModal}
          title={I18n.t('strava.settings.privacy.visibility.change_visibility')}
          body={I18n.t(`${I18nPrefix}detail_warning_under_18`)}
          onConfirm={confirmSettingUpdate}
          onDismiss={closeModal}
        />
        <StreamPrivacySlider
          domain={[
            0,
            streamPrivacyOptions[streamPrivacyOptions.length - 1].meters_value
          ]}
          defaultStreamPrivacy={defaultStreamPrivacy}
          onChange={handleSettingUpdate}
          numTicks={streamPrivacyOptions.length}
        />
        <div className={styles.sliderScale}>
          {sliderLabels.map((s) => (
            <div key={s.meters_value}>{I18n.t_html(s.label)}</div>
          ))}
        </div>
      </div>
      <div className={styles.segmentVisibility}>
        {I18n.t_html(
          `${I18nPrefix}segment_visibility_html`,
          {},
          {
            onLinkClick: () =>
              trackEvent('click', 'learn_more', {
                article_id: 115000173384
              })
          }
        )}
      </div>
    </div>
  );
}

HideAnywhereApp.propTypes = {
  defaultStreamPrivacy: PropTypes.shape({
    meters_value: PropTypes.number,
    luggage_value: PropTypes.string,
    label: PropTypes.string
  }).isRequired,
  streamPrivacyOptions: PropTypes.arrayOf(
    PropTypes.shape({
      meters_value: PropTypes.number,
      luggage_value: PropTypes.string,
      label: PropTypes.string
    })
  ).isRequired,
  updateSettingsPath: PropTypes.string.isRequired,
  isAthleteUnder18: PropTypes.bool.isRequired
};

export default HideAnywhereApp;
