import React, { useState, useEffect } from 'react';
import I18n from 'utils/I18n';
import ActionsCancelNormalXsmall from '@strava/icons/ActionsCancelNormalXsmall';
import ActionsVideoThumbnailNormalSmall from '@strava/icons/ActionsVideoThumbnailNormalSmall';
import cx from '@strava/ui/clsx';
import styles from './FeatureEdInfoBox.scss';

function FeatureEdInfoBox() {
  const [isDismissed, setIsDismissed] = useState(false);
  const [showInfoBox, setShowInfoBox] = useState(true);

  useEffect(() => {
    if (isDismissed) {
      setTimeout(() => {
        setShowInfoBox(false);
      }, 500);
    }
  }, [isDismissed]);

  return (
    <>
      {showInfoBox && (
        <div className={cx(styles.modal, isDismissed ? styles.fadeOut : '')}>
          <div className={styles.circleContainer}>
            <div className={styles.circle}>
              <ActionsVideoThumbnailNormalSmall className={styles.videoIcon} />
            </div>
          </div>

          <div className={styles.body}>
            <div className={styles.header}>
              {I18n.t(`templates.activities.edit.feature_education.header`)}
              <ActionsCancelNormalXsmall
                onClick={() => {
                  setIsDismissed(true);
                }}
                className={styles.cancel}
              />
            </div>
            <div className={styles.subheader}>
              {I18n.t(`templates.activities.edit.feature_education.subheader`)}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

FeatureEdInfoBox.propTypes = {};
export default FeatureEdInfoBox;
