import React from 'react';

import Placeholder from '../Placeholder';

import styles from './styles.scss';

const NoAccess = () => (
  <>
    <div className={styles.lastWeek}>
      <Placeholder height={13} width="30%" />
    </div>

    <div className={styles.head}>
      <Placeholder height={20} width="80%" className={styles.title} />

      <Placeholder height={13} className={styles.body} />
      <Placeholder height={13} className={styles.body} />
      <Placeholder height={13} width="90%" />
    </div>

    <div className={styles.graphic}>
      <Placeholder height={113} />
    </div>

    <div className={`media media-middle ${styles.description}`}>
      <div className="media-object">
        <div className="app-icon-wrapper">
          <div className="app-icon icon-premium icon-xl" />
        </div>
      </div>
      <div className="media-content">
        <Placeholder height={64} />
      </div>
    </div>
  </>
);

export default NoAccess;
