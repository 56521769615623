import React from 'react';
import ActionsPhotoNormalMedium from '@strava/icons/ActionsPhotoNormalMedium';
import { color } from '@strava/ui-tokens/js';
import styles from './AddMediaThumbnail.scss';

function AddMediaThumbnail() {
  return (
    <div className={styles.container}>
      <div className={styles.maxWidth}>
        <div className={styles.center}>
          <ActionsPhotoNormalMedium fill={color.colorExtendedNeutralN4} />
        </div>
      </div>
    </div>
  );
}

AddMediaThumbnail.propTypes = {};

export default AddMediaThumbnail;
