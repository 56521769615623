import React from 'react';
import PropTypes from 'prop-types';

import I18n from 'utils/I18n';
import Spinner from '@strava/ui/Spinner';
import StepActions from '../../components/StepActions';

import styles from './styles.scss';

const I18nPrefix = 'publishes.wizard';

function Step1({ hasMinError = false, isLoading = false, onNextBtnClick, onCancelBtnClick }) {
  if (isLoading) {
    return (
      <div className={styles.loading}>
        <Spinner className={styles.spinner} />
        <h2>{I18n.t(`${I18nPrefix}.checking_duplicates`)}</h2>
      </div>
    );
  }

  return (
    <>
      <h2>{I18n.t(`${I18nPrefix}.select_your_segment`)}</h2>
      <p>{I18n.t(`${I18nPrefix}.create_segment_how_to`)}</p>
      {hasMinError && (
        <div className={styles.error}>
          <div className={styles.errorTitle}>
            {I18n.t(`${I18nPrefix}.too_short_header`)}
          </div>
          {I18n.t(`${I18nPrefix}.too_short_body`)}
        </div>
      )}
      <StepActions
        disabled={isLoading}
        actionVariant="next"
        onNext={onNextBtnClick}
        onCancel={onCancelBtnClick}
      />
    </>
  );
}

Step1.propTypes = {
  hasMinError: PropTypes.bool,
  isLoading: PropTypes.bool,
  onNextBtnClick: PropTypes.func.isRequired,
  onCancelBtnClick: PropTypes.func.isRequired
};

export default Step1;
