import React from 'react';
import PropTypes from 'prop-types';
import ActivityIcon from 'components/ActivityIcon';
import Cldr from 'utils/Cldr';
import I18n from 'utils/I18n';
import { getTrainingImpactColor } from 'utils/relative-effort';

import styles from './styles.scss';

function formatDate(date) {
  return Cldr.formatDateTime(new Date(date), {
    format: 'additional',
    type: 'yMEd'
  });
}

function formatMovingTime(movingTime) {
  return I18n.timespanFormatter().display(movingTime);
}

const Activities = ({ currentActivityId, activities }) => (
  <table className={styles.table}>
    <tbody>
      {activities.map((activity) => (
        <tr key={activity.id} className={styles.row}>
          <td className={styles.type}>
            <ActivityIcon type={activity.activity_type} size="small" />
          </td>
          <td className={styles.date}>
            {formatDate(activity.start_date_local)}
          </td>
          <td className={styles.name}>
            {currentActivityId === activity.id.toString() ? (
              activity.name
            ) : (
              <a href={`/activities/${activity.id}`}>{activity.name}</a>
            )}
            {activity.private && (
              <span className={`app-icon-wrapper ${styles.lockIconWrap}`}>
                <span
                  className={`app-icon icon-lock-closed-normal-xsmall ${styles.lockIcon}`}
                />
              </span>
            )}
          </td>
          <td className={styles.time}>
            {formatMovingTime(activity.moving_time)}
          </td>
          <td className={styles.score}>
            <span
              className="text-demi"
              style={{ color: getTrainingImpactColor(activity.trending_ratio) }}
            >
              {activity.suffer_score}
            </span>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

Activities.propTypes = {
  currentActivityId: PropTypes.string.isRequired,
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      moving_time: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      start_date_local: PropTypes.string.isRequired,
      suffer_score: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
      activity_type: PropTypes.string.isRequired
    })
  ).isRequired
};

export default Activities;
