import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const Track = ({ source, target }) => (
  <div
    className={styles.track}
    style={{
      left: `${source.percent}%`,
      width: `${target.percent - source.percent}%`
    }}
  />
);

Track.propTypes = {
  source: PropTypes.shape({
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired
  }).isRequired,
  target: PropTypes.shape({
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired
  }).isRequired
};

export default Track;
