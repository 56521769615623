import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@strava/ui/Button';
import Switch from '@strava/ui/Switch';
import SvgActionsVisibleDisabledNormalMedium from '@strava/icons/ActionsVisibleDisabledNormalMedium';
import SvgActivityDistanceNormalMedium from '@strava/icons/ActivityDistanceNormalMedium';
import SvgActionsGlobalNormalMedium from '@strava/icons/ActionsGlobalNormalMedium';

import I18n from 'utils/I18n';
import { trackV2 } from 'utils/analytics';
import createNetworkingClient from 'utils/networking-client';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import styles from './styles.scss';

function MapVisibilityControls({
  numPrivateLocations,
  defaultStreamPrivacy,
  defaultActivityMapVisibility,
  paths,
  isAthleteUnder18
}) {
  const i18nPrefix = 'strava.settings.privacy.map_visibility.';

  // mapsHidden is for sending the string value, 'everyone' / 'only_me', during the fetch request to the server to update the athlete settings
  const [mapsHidden, setMapsHidden] = useState(
    defaultActivityMapVisibility !== 'everyone'
  );

  // toggleSwitchState is for updating the UI of the toggle button. UI design requires the toggle to update prior to clicking confirm if an athlete under 18 clicks the toggle to expose map visibility to everyone
  const [toggleSwitchState, setToggleSwitchState] = useState(
    defaultActivityMapVisibility !== 'everyone'
  );

  const trackEvent = (action, element = null, properties = null) => {
    trackV2({
      category: 'privacy_settings',
      page: 'map_visibility',
      action,
      ...(element && { element }),
      ...(properties && { properties })
    });
  };
  const [openModal, setOpenModal] = useState(false);
  const closeModal = () => {
    setOpenModal(false);
    setToggleSwitchState(true);
  };

  const updateMapVisibility = (callback) => {
    trackEvent('click', 'hide_all_maps', {
      map_visibility: mapsHidden ? 'everyone' : 'only_you'
    });

    createNetworkingClient()
      .put(paths.athleteSettings, {
        settings: {
          default_activity_map_visibility: mapsHidden ? 'everyone' : 'only_me'
        }
      })
      .then(() => {
        setMapsHidden((prevMapsHidden) => !prevMapsHidden);
        callback();
      });
  };

  const onSwitchClick = () => {
    if (isAthleteUnder18 && toggleSwitchState) {
      setOpenModal(true);
      setToggleSwitchState(false);
    } else {
      updateMapVisibility(() =>
        setToggleSwitchState((prevSwitchState) => !prevSwitchState)
      );
    }
  };

  return (
    <div>
      <h3 className={styles.header}>{I18n.t(`${i18nPrefix}header`)}</h3>
      <a
        href="https://support.strava.com/hc/en-us/articles/115000173384"
        className={styles.learnMore}
        onClick={() =>
          trackEvent('click', 'learn_more', { article_id: 115000173384 })
        }
      >
        {I18n.t(`${i18nPrefix}learn_more`)}
      </a>
      <div className={styles.hideAddress}>
        <SvgActivityDistanceNormalMedium />
        <div id="map-visibility-hide-address">
          <div>{I18n.t_html(`${i18nPrefix}hide_address_v2_html`)}</div>
          <div>
            {I18n.t_html(
              `${i18nPrefix}address_hidden_html`,
              {
                count: numPrivateLocations,
                url: paths.hideAddress
              },
              {
                onLinkClick: () =>
                  trackEvent('click', 'currently_hidden_address')
              }
            )}
          </div>
        </div>
        <Button
          type="button"
          className={styles.addEditButton}
          onClick={() => {
            trackEvent('click', 'hide_specific_address');
            window.location.href = paths.hideAddress;
          }}
        >
          {I18n.t(
            numPrivateLocations ? `${i18nPrefix}edit` : `${i18nPrefix}add`
          )}
        </Button>
      </div>
      <div className={styles.hideAnywhere}>
        <SvgActionsGlobalNormalMedium />
        <div>{I18n.t_html(`${i18nPrefix}hide_anywhere_v2_html`)}</div>
        <Button
          type="button"
          className={styles.addEditButton}
          onClick={() => {
            trackEvent('click', 'hide_any_start_end');
            window.location.href = paths.hideAnywhere;
          }}
        >
          {I18n.t(
            defaultStreamPrivacy === 'disabled'
              ? `${i18nPrefix}add`
              : `${i18nPrefix}edit`
          )}
        </Button>
      </div>
      <div className={styles.hideAll}>
        <SvgActionsVisibleDisabledNormalMedium />
        <div>
          <div className={styles.hideAllLabel}>
            {I18n.t_html(`${i18nPrefix}hide_all_v2`)}
          </div>
          <div className={styles.hideAllDetail}>
            {I18n.t_html(`${i18nPrefix}hide_all_detail_html`)}
          </div>
        </div>
        <Switch
          name="default-activity-map-visibility"
          className={styles.switch}
          checked={toggleSwitchState}
          onClick={onSwitchClick}
          disabled={openModal}
        />
        <ConfirmModal
          title={I18n.t_html(
            'strava.settings.privacy.visibility.change_visibility'
          )}
          body={I18n.t_html(`${i18nPrefix}hide_all_warning_under_18`)}
          onConfirm={() => updateMapVisibility(() => setOpenModal(false))}
          isOpen={openModal}
          onDismiss={closeModal}
        />
      </div>
    </div>
  );
}

MapVisibilityControls.propTypes = {
  numPrivateLocations: PropTypes.number.isRequired,
  defaultStreamPrivacy: PropTypes.string.isRequired,
  defaultActivityMapVisibility: PropTypes.string.isRequired,
  paths: PropTypes.shape({
    hideAddress: PropTypes.string,
    hideAnywhere: PropTypes.string,
    athleteSettings: PropTypes.string
  }).isRequired,
  isAthleteUnder18: PropTypes.bool.isRequired
};

export default MapVisibilityControls;
