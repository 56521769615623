import React from 'react';
import PropTypes from 'prop-types';
import clsx from '@strava/ui/clsx';
import I18n from 'utils/I18n';
import Button from '@strava/ui/Button';
import styles from './PopupContents.scss';

function PopupContents({
  hasFailedToUpload,
  mediaType,
  remove,
  uuid,
  index,
  setHighlightMedia,
  highlightMediaId
}) {
  return hasFailedToUpload ? (
    <ul className={clsx(styles.popupButtonList)}>
      <div className={styles.popupText}>
        <div className={styles.popupHeader}>
          {mediaType === 2
            ? I18n.t(
                `templates.activities.edit.photos.error_handling.error_with_media`
              )
            : I18n.t(
                `templates.activities.edit.photos.error_handling.something_went_wrong`
              )}
        </div>
        <div>
          {I18n.t(
            `templates.activities.edit.photos.error_handling.try_uploading_again`
          )}
        </div>
      </div>
      <div className={styles.deleteContainer}>
        <Button
          className={styles.deleteToReupload}
          onClick={(e) => {
            remove(e, index);
          }}
          variant="primary"
        >
          {I18n.t(`templates.activities.edit.photos.delete`)}
        </Button>
      </div>
    </ul>
  ) : (
    <ul className={styles.popupButtonList}>
      <li>
        <Button
          className={styles.popupButton}
          onClick={(e) => {
            setHighlightMedia(e, index);
          }}
          disabled={highlightMediaId === uuid}
        >
          {I18n.t(`templates.activities.edit.photos.set_highlight_v2`)}
        </Button>
      </li>
      <li>
        <Button
          className={clsx(styles.popupButton, styles.delete)}
          onClick={(e) => {
            remove(e, index);
          }}
        >
          {I18n.t(`templates.activities.edit.photos.delete`)}
        </Button>
      </li>
    </ul>
  );
}

PopupContents.propTypes = {
  hasFailedToUpload: PropTypes.bool.isRequired,
  mediaType: PropTypes.number.isRequired,
  remove: PropTypes.func.isRequired,
  uuid: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  setHighlightMedia: PropTypes.func.isRequired,
  highlightMediaId: PropTypes.string.isRequired
};
export default PopupContents;
