import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import I18n from 'utils/I18n';
import Map, { MAP_STYLES } from '@strava/ui/Map';
import Polyline from '@strava/ui/Map/Polyline';
import Marker from '@strava/ui/Map/Marker';
import startMarkerSrc from '@strava/ui/Map/images/map-marker-start.png';
import endMarkerSrc from '@strava/ui/Map/images/map-marker-end.png';
import { getBoundsFromLatLng } from '@strava/ui/Map/mapboxGeometry';
import MapControls from './MapControls';
import styles from './ActivityDetailsMap.scss';
import PrivacyZonePolylines from './PrivacyZonePolylines';
import StravaMediaLightbox from '../StravaMediaLightbox';

const FALLBACK_LATLNG = [0, 0];

const ActivityDetailsMap = ({
  activityId,
  mapBounds,
  streamData,
  defaultHighlight,
  interactive = true,
  showCreateRoute = true,
  showGpxDownload = true,
  showFullScreenToggle = false,
  measurementUnits = 'metric',
  mapCreatedCallback
}) => {
  const polyline = streamData.latlng;

  // If we have privacy zone info from the stream request, we want to show those privacy zones on
  // the map via a polyline. Privacy stream data comes to the client in the form of an array of
  // numbers. The index of a given number corresponds to an index in the streamData.latLng array.
  // The values are either 0 or 2. 0 indicating that the corresponding latLng is not hidden, 2
  // indicating that it is. So we can look for the first index of a 0 value to figure out when the
  // privacy zones begin and end.
  const privacyZoneFromStartIndex = useMemo(
    () =>
      streamData.privacy ? streamData.privacy.findIndex((val) => val === 0) : 0,
    [streamData.privacy]
  );
  const privacyZoneFromEndIndex = useMemo(
    () =>
      streamData.privacy
        ? streamData.privacy.reverse().findIndex((val) => val === 0)
        : 0,
    [streamData.privacy]
  );

  const hasPrivacyZones =
    privacyZoneFromStartIndex > 0 || privacyZoneFromEndIndex > 0;

  // For some views, we want to highlight a section of the route by default, i.e. when viewing a map
  // for a segment within an activity
  const defaultHighlightLatLng = defaultHighlight
    ? [...streamData.latlng.slice(defaultHighlight[0], defaultHighlight[1])]
    : null;

  const [mapboxRef, setMapboxRef] = useState(null);
  const [currentMapBounds, setCurrentMapBounds] = useState(
    defaultHighlightLatLng ?? mapBounds
  );
  const [polylineHighlight, setPolylineHightlight] = useState(
    defaultHighlightLatLng ?? null
  );
  const [rabbitLatLng, setRabbitLatLng] = useState(null);
  const [mapStyle, setMapStyle] = useState('standard');
  const [mapStyleUrl, setMapStyleUrl] = useState(MAP_STYLES.standard);
  const [resizeMap, setResizeMap] = useState(false);
  const [mapPhotos, setMapPhotos] = useState(null);
  const [expandedPhoto, setExpandedPhoto] = useState(null);

  useEffect(() => {
    if (mapboxRef) {
      const bounds = getBoundsFromLatLng(currentMapBounds);

      mapboxRef.fitBounds(bounds, {
        linear: false,
        duration: 0,
        // TODO do we need to adjust padding at all?
        padding: 24
      });
    }
  }, [currentMapBounds, mapboxRef]);

  useEffect(() => {
    if (mapboxRef) {
      mapboxRef.resize();
      setResizeMap(false);
    }
  }, [mapboxRef, resizeMap]);

  const handleMapStyleChange = ({ value }) => {
    setMapStyle(value);
    setMapStyleUrl(MAP_STYLES[value]);
  };

  const onSetRabbitPosition = ({ detail }) => {
    const { index } = detail;
    const latLng = streamData.latlng[index];
    setRabbitLatLng(latLng);
  };

  const onHideRabbit = () => {
    setRabbitLatLng(null);
  };

  const onHighlightPolyline = ({ detail }) => {
    const { start, end } = detail;
    const latLng = [...streamData.latlng.slice(start, end)];
    setPolylineHightlight(latLng);
  };

  const onUnhighlightPolyline = () => {
    setPolylineHightlight(null);
  };

  const onHighlightPolylineAndCenterOnMap = ({ detail }) => {
    const { start, end } = detail;
    const latLng = [...streamData.latlng.slice(start, end)];

    setPolylineHightlight(latLng);
    setCurrentMapBounds(latLng);
  };

  const onResetMapBounds = () => {
    setCurrentMapBounds(mapBounds);
  };

  const onResizeMap = () => {
    setResizeMap(true);
  };

  const onRenderMapImages = ({ detail }) => {
    const { photos } = detail;
    setMapPhotos(photos);
  };

  useEffect(() => {
    document.addEventListener('SetRabbitPosition', onSetRabbitPosition);
    document.addEventListener('HideRabbit', onHideRabbit);

    document.addEventListener('HighlightPolyline', onHighlightPolyline);
    document.addEventListener('UnhighlightPolyline', onUnhighlightPolyline);
    document.addEventListener(
      'HighlightPolyLineAndCenterOnMap',
      onHighlightPolylineAndCenterOnMap
    );
    document.addEventListener('ResetMapBounds', onResetMapBounds);
    document.addEventListener('ResizeMap', onResizeMap);

    document.addEventListener('RenderMapImages', onRenderMapImages);

    return function cleanup() {
      [
        'SetRabbitPosition',
        'HideRabbit',
        'HighlightPolyline',
        'UnhighlightPolyline',
        'HighlightPolyLineAndCenterOnMap',
        'ResetMapBounds',
        'ResizeMap',
        'RenderMapImages'
      ].forEach((listener) => {
        document.removeEventListener(listener);
      });
    };
  }, []);

  useEffect(() => {
    if (mapCreatedCallback) {
      mapCreatedCallback()();
    }
  }, [mapCreatedCallback]);

  return (
    <Map
      mapboxRef={setMapboxRef}
      className={styles.map}
      latLng={currentMapBounds}
      language={I18n.language()}
      zoomControl={interactive}
      measurementUnits={measurementUnits}
      options={{
        style: `${mapStyleUrl}&exclude_pois=true&exclude_networks=true`,
        minZoom: 3,
        pitchWithRotate: true,
        dragRotate: true,
        interactive
      }}
    >
      {interactive && (
        <MapControls
          activityId={activityId}
          showFullScreenToggle={showFullScreenToggle}
          showGpxDownload={showGpxDownload}
          showCreateRoute={showCreateRoute}
          handleMapStyleChange={handleMapStyleChange}
          mapStyle={mapStyle}
          hasPrivacyZones={hasPrivacyZones}
        />
      )}

      <Polyline
        latLng={polyline}
        id="polyline"
        style={{
          // TODO set polyline style based on activity map style if applicable
          lineColor: '#FF0000',
          outlineWidth: 5
        }}
      />

      {hasPrivacyZones && !polylineHighlight && (
        <PrivacyZonePolylines
          polyline={polyline}
          privacyZoneFromStartIndex={privacyZoneFromStartIndex}
          privacyZoneFromEndIndex={privacyZoneFromEndIndex}
        />
      )}

      {polylineHighlight && (
        <Polyline
          latLng={polylineHighlight}
          id="polylineHighlight"
          style={{
            lineColor: '#105CB6',
            outlineWidth: 5
          }}
        />
      )}

      <Marker latLng={polyline[0] || FALLBACK_LATLNG}>
        <img
          className={styles.marker}
          src={startMarkerSrc}
          alt={I18n.t('strava.maps.mapbox_gl.start_marker_alt')}
        />
      </Marker>

      <Marker latLng={polyline[polyline.length - 1] || FALLBACK_LATLNG}>
        <img
          className={styles.marker}
          src={endMarkerSrc}
          alt={I18n.t('strava.maps.mapbox_gl.end_marker_alt')}
        />
      </Marker>

      <Marker latLng={rabbitLatLng || FALLBACK_LATLNG}>
        <div className={`${styles.marker} ${styles.rabbitMarker}`} />
      </Marker>

      {mapPhotos && (
        <>
          {mapPhotos.map((photo, index) => {
            const { attributes, id } = photo;
            if (attributes.lat && attributes.lng) {
              return (
                <Marker
                  latLng={[attributes.lat, attributes.lng]}
                  key={id}
                  legacyOptions={{ riseOnHover: true }}
                >
                  {/* TODO this is not ideal, as these elements are not able to be interacted with via
                      the keyboard. However, the existing map photos also do not support this, so we
                      aren't introducing any regressions here */}
                  {/* eslint-disable jsx-a11y/click-events-have-key-events */}
                  {/* eslint-disable jsx-a11y/no-static-element-interactions */}
                  <div
                    className={styles.photoMarker}
                    onClick={() => {
                      setExpandedPhoto({ ...attributes, index });
                    }}
                  >
                    <img
                      src={attributes.thumbnail}
                      alt={I18n.t('strava.maps.mapbox_gl.photo_marker_alt')}
                    />
                  </div>
                </Marker>
              );
            }
            return null;
          })}
          {expandedPhoto ? (
            <StravaMediaLightbox
              selectedPhotoIndex={expandedPhoto.index}
              photoList={mapPhotos.map((p) => p.attributes)}
              onMount={() => {}}
              onCloseRequest={() => {
                setExpandedPhoto(null);
              }}
              analyticProps={{}}
            />
          ) : null}
        </>
      )}
    </Map>
  );
};

ActivityDetailsMap.propTypes = {
  activityId: PropTypes.number.isRequired,
  mapBounds: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
  streamData: PropTypes.shape({
    latlng: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
    privacy: PropTypes.arrayOf(PropTypes.number)
  }).isRequired,
  defaultHighlight: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  interactive: PropTypes.bool,
  showGpxDownload: PropTypes.bool,
  showCreateRoute: PropTypes.bool,
  showFullScreenToggle: PropTypes.bool,
  measurementUnits: PropTypes.string,
  mapCreatedCallback: PropTypes.func
};

export default ActivityDetailsMap;
