import React from 'react';
import PropTypes from 'prop-types';
import createNetworkingClient from 'utils/networking-client';
import Spinner from '@strava/ui/Spinner';

import styles from './styles.scss';
import Disclaimer from './Disclaimer';
import ActivitySummary from './ActivitySummary';
import WeeklySummary from './WeeklySummary';

class TrainingImpact extends React.Component {
  state = {
    isLoading: true,
    disclaimerAgreed: false,
    weeklyScores: [],
    selectedWeekIndex: -1,
    trendingRatio: 0,
    activitySummary: null
  };

  componentDidMount() {
    const { athleteId, activityId } = this.props;

    createNetworkingClient()
      .get(`/athletes/${athleteId}/relative_effort`, {
        params: {
          activity_id: activityId
        }
      })
      .then((response) => {
        if (response && response.status === 200) {
          this.handleDataFetchComplete(response.data);
        } else {
          // TODO: show some error to the user
        }
      })
      .catch(() => {
        // TODO: show some error to the user
      });
  }

  handleDataFetchComplete = (data) => {
    const weeklyScores = data.weekly_scores.reverse();
    const selectedWeekIndex =
      weeklyScores.length - data.selected_week_index - 1;

    const activitySummary = data.bucket_detail
      ? {
          score: data.bucket_detail.relative_effort,
          label: data.bucket_detail.label ? data.bucket_detail.label : null
        }
      : null;

    this.setState({
      isLoading: false,
      disclaimerAgreed: data.disclaimer_agreed,
      weeklyScores,
      selectedWeekIndex,
      trendingRatio: data.trending_ratio,
      activitySummary
    });
  };

  handleDisclaimerContinueClick = () => {
    const { athleteId } = this.props;

    createNetworkingClient().post(
      `/athletes/${athleteId}/relative_effort_disclaimer_agreed`
    );

    this.setState({ disclaimerAgreed: true });
  };

  render() {
    const {
      isLoading,
      disclaimerAgreed,
      weeklyScores,
      selectedWeekIndex,
      trendingRatio,
      activitySummary
    } = this.state;
    const { activityId } = this.props;
    const showDisclaimer = !disclaimerAgreed;

    if (isLoading) {
      return (
        <div className={styles.spinnerContainer}>
          <Spinner size={16} />
        </div>
      );
    }

    return (
      <div className={styles.container}>
        {showDisclaimer && (
          <Disclaimer
            handleContinueClick={this.handleDisclaimerContinueClick}
          />
        )}
        <ActivitySummary
          summary={activitySummary}
          trendingRatio={trendingRatio}
        />
        <WeeklySummary
          currentActivityId={activityId}
          weeklyScores={weeklyScores}
          selectedWeekIndex={selectedWeekIndex}
        />
      </div>
    );
  }
}

TrainingImpact.propTypes = {
  athleteId: PropTypes.number.isRequired,
  activityId: PropTypes.string.isRequired
};

export default TrainingImpact;
