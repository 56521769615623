import React from 'react';

import I18n from 'utils/I18n';
import InfoModal from '../../InfoModal/InfoModal';

import styles from './FeatureEdInfoIcon.scss';

const FeatureEdInfoIcon = () => {
  return (
    <InfoModal className={styles.icon}>
      <>
        <div className={styles.header}>
          {I18n.t(
            `templates.activities.edit.feature_education.info_icon.header`
          )}
        </div>
        <div className={styles.content}>
          <p>
            {I18n.t(
              `templates.activities.edit.feature_education.info_icon.content_1`
            )}
          </p>
          <p>
            {I18n.t(
              `templates.activities.edit.feature_education.info_icon.content_2`
            )}
          </p>
          <p>
            {I18n.t(
              `templates.activities.edit.feature_education.info_icon.content_3`
            )}
          </p>
        </div>
      </>
    </InfoModal>
  );
};

export default FeatureEdInfoIcon;
