import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@strava/ui/Button';

import I18n from 'utils/I18n';
import Modal from 'components/shared/Modal';

import styles from './styles.scss';
import { toggleModal, hideInterstitial } from '../../actions';
import TaggingInterstitial from '../TaggingInterstitial';
import TaggingInviteContainer from '../TaggingInviteContainer';

export class Tagging extends React.Component {
  openModal = () => {
    this.props.handleToggleModal();
  };

  closeModal = () => {
    this.props.handleToggleModal();
  };

  addOthersBtn = () => (
    <Button
      type="button"
      variant="primary"
      className={styles.btnAddFriend}
      onClick={this.openModal}
    >
      {I18n.t('strava.activities.tagging.cta.add_others')}
    </Button>
  );

  someoneWithYou = () => (
    <>
      <span>{I18n.t('strava.activities.tagging.cta.title')}</span>
      <Button
        type="button"
        variant="primary"
        className={styles.btnAddFriend}
        onClick={this.openModal}
      >
        {I18n.t('strava.activities.tagging.cta.add_friends')}
      </Button>
    </>
  );

  continueClicked = () => {
    this.props.handleHideInterstitial();
  };

  renderInviteList = () => {
    const { showInterstitial = true } = this.props;
    let inviteContent;
    if (showInterstitial) {
      inviteContent = (
        <>
          <button
            className={`${styles.closeButton} btn btn-unstyled btn-close`}
            onClick={this.closeModal}
            title="Close modal"
          >
            <div className="app-icon icon-close icon-sm icon-white" />
          </button>
          <TaggingInterstitial
            activityType={this.props.activityType}
            triggerContinue={this.continueClicked}
          />
        </>
      );
    } else {
      inviteContent = (
        <>
          <button
            className={`${styles.closeButton} btn btn-unstyled btn-close`}
            onClick={this.closeModal}
            title="Close modal"
          >
            <div className="app-icon icon-close icon-sm" />
          </button>
          <TaggingInviteContainer
            activityId={this.props.activityId}
            activityType={this.props.activityType}
            athleteName={this.props.athleteName}
            athleteId={this.props.athleteId}
            findFriendsUrl={this.props.findFriendsUrl}
            branchLinkData={this.props.branchLinkData}
          />
        </>
      );
    }
    return (
      <Modal
        isOpen={this.props.isModalOpen ?? false}
        onRequestClose={this.closeModal}
        shouldCloseOnOverlayClick={false}
      >
        <div className={styles.dialog}>
          <div>{inviteContent}</div>
        </div>
      </Modal>
    );
  };

  render() {
    return (
      <div className="activity-athlete-tagging-inviter">
        {this.props.groupActivity ? this.addOthersBtn() : this.someoneWithYou()}
        {this.renderInviteList()}
      </div>
    );
  }
}

Tagging.propTypes = {
  groupActivity: PropTypes.bool,
  activityId: PropTypes.number.isRequired,
  activityType: PropTypes.string.isRequired,
  athleteId: PropTypes.number.isRequired,
  athleteName: PropTypes.string.isRequired,
  findFriendsUrl: PropTypes.string.isRequired,
  branchLinkData: PropTypes.shape({}).isRequired,
  isModalOpen: PropTypes.bool,
  handleToggleModal: PropTypes.func.isRequired,
  showInterstitial: PropTypes.bool,
  handleHideInterstitial: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    groupActivity: state.groupActivity,
    activityId: state.activityId,
    activityType: state.activityType,
    athleteId: state.activityId,
    athleteName: state.athleteName,
    findFriendsUrl: state.findFriendsUrl,
    branchLinkData: state.branchLinkData,
    isModalOpen: state.isModalOpen,
    showInterstitial: state.showInterstitial
  };
}

export default connect(
  mapStateToProps,
  {
    handleToggleModal: toggleModal,
    handleHideInterstitial: hideInterstitial
  }
)(Tagging);
