import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FocusableElement from 'components/shared/FocusableElement';
import I18n from 'utils/I18n';

import styles from './index.scss';
import {
  heightReported,
  settingCollapsed,
  settingExpanded,
  valueChanged,
  valueSaved
} from '../../actions';

import Footer from '../Footer';
import OptionList from '../OptionList';

const I18nPrefix = 'strava.settings.privacy.visibility.';
const I18nKey = {
  who_can_see: `${I18nPrefix}who_can_see`
};

export class ExpandableOptionList extends React.Component {
  static PaddingPx = 50;

  static InitialOptionHeightPx = 66;

  constructor(props) {
    super(props);

    this.optionListID = OptionList.identifier(props.name);
    this.refFocusable = React.createRef();
    this.refSelected = React.createRef();

    const { athleteId = null, isSubmittable } = props;
    if (isSubmittable && !athleteId) {
      throw new Error('`athleteId` is required for submittable component');
    }
  }

  handleSettingCollapse = () => {
    this.refFocusable.current.blur();
    this.props.settingCollapsed(this.props.analyticsReporter);
  };

  handleSettingExpand = () => {
    const { name, analyticsReporter } = this.props;

    this.refSelected.current.focus();

    // This is necessary because when the component is embedded within a form,
    // the name is the name of the form tag; activity[visibility]
    // and we only want visibility here.
    const formTagNameRegex = /\[(.*)\]/;
    const match = name.match(formTagNameRegex);
    if (match) {
      this.props.settingExpanded(analyticsReporter, match[1]);
      return;
    }
    this.props.settingExpanded(analyticsReporter, name);
  };

  handleValueSave = () => {
    const {
      athleteId = null,
      name,
      options,
      savedOptionIndex,
      selectedOptionIndex,
      analyticsReporter
    } = this.props;

    if (savedOptionIndex === selectedOptionIndex) {
      this.handleSettingCollapse();
      return;
    }

    this.props
      .valueSaved({
        athleteId,
        settingName: name,
        optionIndex: selectedOptionIndex,
        optionValue: options[selectedOptionIndex].value,
        analyticsReporter
      })
      .then(() => this.refFocusable.current.blur());
  };

  /**
   * styleContainer returns a style object with a `height` property when `isExpanded`,
   * it returns null otherwise.
   *
   * styleContainer is necessary for styling the collapsed ExpandableOptionList s.t.
   * there is no perceived shudder/jankiness as its contents become
   * absolutely positioned and have no DOM-impactful height when `isExpanded`.
   *
   */
  styleContainer = () => {
    const {
      isExpanded = false,
      selectedOptionHeight = ExpandableOptionList.InitialOptionHeightPx
    } = this.props;
    const height = ExpandableOptionList.PaddingPx + selectedOptionHeight;

    if (!isExpanded) {
      return null;
    }

    return { height: `${height}px` };
  };

  /**
   * styleMenu returns a style object with a `top` property when `isExpanded` and
   * `savedOptionIndex` > 0, it returns null otherwise.
   *
   * styleMenu sums height of all Options above the currently saved Option, and subtracts
   * that from the implicit `top: 0` so that the expanded widget is centered
   * around the Option that was selected when the widget expands.
   *
   */
  styleMenu = () => {
    const { isExpanded = false, options, savedOptionIndex } = this.props;

    if (!isExpanded) {
      return null;
    }

    if (savedOptionIndex === 0) {
      return null;
    }

    const height = options
      .slice(0, savedOptionIndex)
      .reduce((total, o) => total + (o.height || 0), 0);

    return { top: `-${height}px` };
  };

  render() {
    const { isExpanded = false } = this.props;

    let whoCanSeeClasses = styles.whoCanSee;
    if (!isExpanded) {
      whoCanSeeClasses += ` ${styles.collapsed}`;
    }

    return (
      <section
        className={styles.expandableOptionList}
        style={this.styleContainer()}
      >
        <FocusableElement
          aria-expanded={isExpanded}
          aria-controls={this.optionListID}
          className={whoCanSeeClasses}
          style={this.styleMenu()}
          refFocusable={this.refFocusable}
          isFocused={isExpanded}
          onBlur={this.handleSettingCollapse}
          onFocus={this.handleSettingExpand}
          tabIndex="0"
          data-testid="expandable-option-list"
        >
          <p className={`text-caption4 ${styles.heading}`}>
            {I18n.t(I18nKey.who_can_see)}
          </p>
          <OptionList
            {...this.props}
            isExpanded={isExpanded}
            refSelected={this.refSelected}
          />
          <Footer
            {...this.props}
            isExpanded={isExpanded}
            settingCollapsed={this.handleSettingCollapse}
            handleValueSave={this.handleValueSave}
          />
        </FocusableElement>
      </section>
    );
  }
}

ExpandableOptionList.propTypes = {
  // actions
  heightReported: PropTypes.func.isRequired,
  settingCollapsed: PropTypes.func.isRequired,
  settingExpanded: PropTypes.func.isRequired,
  valueChanged: PropTypes.func.isRequired,
  valueSaved: PropTypes.func.isRequired,

  // other props
  athleteId: PropTypes.number,
  name: PropTypes.string.isRequired,
  savedOptionIndex: PropTypes.number.isRequired,
  selectedOptionIndex: PropTypes.number.isRequired,
  selectedOptionHeight: PropTypes.number,
  isExpanded: PropTypes.bool,
  isSubmittable: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      height: PropTypes.number,
      title: PropTypes.string.isRequired,
      detail: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,

  // For Snowplow Tracking
  analyticsReporter: PropTypes.func.isRequired
};

export default connect(
  (state) => state,
  {
    heightReported,
    settingCollapsed,
    settingExpanded,
    valueChanged,
    valueSaved
  }
)(ExpandableOptionList);
