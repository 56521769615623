import React from 'react';
import PropTypes from 'prop-types';
import Button from '@strava/ui/Button';

import I18n from 'utils/I18n';

import styles from './index.scss';

const I18nPrefix = 'strava.settings.privacy.visibility.';
const I18nKey = {
  ok: `${I18nPrefix}ok`,
  cancel: `${I18nPrefix}cancel`
};

const Controls = ({ onCancel, onOk }) => (
  <>
    <Button
      key="cancel"
      type="button"
      className={styles.btnCancel}
      onClick={onCancel}
    >
      {I18n.t(I18nKey.cancel)}
    </Button>
    <Button
      key="ok"
      type="button"
      variant="primary"
      className={styles.btnOk}
      data-testid="controls-ok-btn"
      onClick={onOk}
    >
      {I18n.t(I18nKey.ok)}
    </Button>
  </>
);

Controls.propTypes = {
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default Controls;
