import React from 'react';
import PropTypes from 'prop-types';
import { rect } from 'd3-shape';
import styles from './styles.scss';
import calculateProgressFill from '../utils';

const HorizontalProgressChart = ({
  primaryProgress = 0.0,
  secondaryProgress = 0.0,
  canvasWidth,
  progressBarHeight = 2,
  incompleteProgressLimit = 0.98
}) => {
  const canvasHeight = progressBarHeight + 6;
  const secondaryHorizontalOffset = secondaryProgress * canvasWidth - 2;
  const secondaryVerticalOffset = canvasHeight - 2;

  const primaryProgressFill =
    calculateProgressFill(primaryProgress, incompleteProgressLimit) * 100.0;

  return (
    <svg
      style={{ overflow: 'visible', width: canvasWidth, height: canvasHeight }}
    >
      <g>
        <rect
          height={progressBarHeight}
          style={{ width: '100%' }}
          className={styles.progressBarEmpty}
        />
        <rect
          height={progressBarHeight}
          style={{ width: `${primaryProgressFill}%` }}
          className={styles.progressBarFilled}
        />
        <polygon
          points="0,4 6,4 3,0"
          transform={`translate(${secondaryHorizontalOffset}, ${secondaryVerticalOffset})`}
          className={styles.progressSecondaryMarker}
        />
      </g>
    </svg>
  );
};

HorizontalProgressChart.propTypes = {
  canvasWidth: PropTypes.number.isRequired,
  primaryProgress: PropTypes.number,
  secondaryProgress: PropTypes.number,
  progressBarHeight: PropTypes.number,
  incompleteProgressLimit: PropTypes.number
};

export default HorizontalProgressChart;
