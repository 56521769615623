import React from 'react';
import PropTypes from 'prop-types';
import InfoIcon from '@strava/icons/NavigationInformationNormalXsmall';
import Button from '@strava/ui/Button';

import I18n from 'utils/I18n';
import Tooltip from 'components/shared/Tooltip';

import styles from './styles.scss';

export const I18nPrefix = 'strava.perceived_exertion_input.toggle';

const Toggle = ({
  entityId,
  name,
  checked = false,
  disabled = false,
  onChange
}) => {
  const containerClassName = [styles.toggleContainer];

  if (disabled) {
    containerClassName.push(styles.disabled);
  }

  return (
    <div className={containerClassName.join(' ')}>
      <label htmlFor={`rpe-toggle-${entityId}`}>
        {/*
          The HTML specification says unchecked check boxes are not
          successful, and thus web browsers do not send them.
          In order to solve this we have a hidden input that has 0/1
          depending if checkbox is selected or not, this hidden input data
          get's sent to the server and not the actual checkbox value.
        */}
        <input name={name} type="hidden" value={checked ? 1 : 0} />
        <input
          id={`rpe-toggle-${entityId}`}
          type="checkbox"
          disabled={disabled}
          checked={checked ? 'checked' : ''}
          onChange={onChange}
        />
        {I18n.t(`${I18nPrefix}.label`)}
      </label>

      <Tooltip
        className={styles.tooltip}
        content={I18n.t(`${I18nPrefix}.learn_more.body_v2`)}
      >
        <Button
          type="button"
          variant="icon"
          className={styles.infoBtn}
          aria-label={I18n.t(`${I18nPrefix}.learn_more.cta.learn_more`)}
        >
          <InfoIcon fill="#999" aria-hidden={true} />
        </Button>
      </Tooltip>
    </div>
  );
};

Toggle.propTypes = {
  entityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool
};

export default Toggle;
