import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import I18n from 'utils/I18n';
import { trackV2 } from 'utils/analytics';
import createNetworkingClient from 'utils/networking-client';
import Button from '@strava/ui/Button';
import Spinner from '@strava/ui/Spinner';
import styles from './styles.scss';

import ExcludedSegmentEffortsModal from '../../../ExcludedSegmentEffortsModal';

const ExcludedSegmentEffortsItem = ({ entity, id }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [flaggedEfforts, setFlaggedEfforts] = useState([]);
  const [speedUnit, setSpeedUnit] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const url = '/frontend/segments/excluded_efforts';
      const params = { params: { entity, id } };
      createNetworkingClient()
        .get(url, params)
        .then(({ data }) => {
          setFlaggedEfforts(data.flagged_efforts);
          setSpeedUnit(data.speed_unit);
        })
        .catch(() => {})
        .finally(() => {
          setIsLoading(false);
        });
    };

    fetchData();
  }, [entity, id]);

  useEffect(() => {
    if (flaggedEfforts.length > 0) {
      trackV2({
        action: 'screen_enter',
        element: 'excluded_efforts',
        category: 'segments',
        page: 'segment_detail'
      });
    }
  }, [flaggedEfforts]);

  const spinner = (
    <div className={styles.spinner}>
      <Spinner />
    </div>
  );

  const openModal = () => {
    setIsModalOpen(true);
    trackV2({
      action: 'click',
      element: 'excluded_efforts',
      category: 'segments',
      page: 'segment_detail'
    });
    trackV2({
      action: 'screen_enter',
      category: 'segments',
      page: 'excluded_efforts'
    });
  };

  const closeModal = () => {
    setIsModalOpen(false);
    trackV2({
      action: 'screen_exit',
      category: 'segments',
      page: 'excluded_efforts'
    });
  };

  const item = (
    <li key="excluded-efforts">
      <div className={styles.dataValuePair}>
        <div className={styles.callOutAchievement}>
          {I18n.t(
            'strava.labs.activities.segments_view.excluded_efforts.heading'
          )}
        </div>
        <div className={styles.callOutEffort}>
          {`- `}
          <Button
            variant="text"
            className={styles.excludedEffortsBtn}
            type="button"
            onClick={openModal}
          >
            {flaggedEfforts.length}
          </Button>
        </div>
        <ExcludedSegmentEffortsModal
          entity={entity}
          id={id}
          flaggedEfforts={flaggedEfforts}
          speedUnit={speedUnit}
          isModalOpen={isModalOpen}
          onDismiss={closeModal}
        />
      </div>
    </li>
  );

  if (isLoading) {
    return spinner;
  }
  if (flaggedEfforts.length > 0) {
    return item;
  }
  return null;
};

ExcludedSegmentEffortsItem.propTypes = {
  entity: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired
};

export default ExcludedSegmentEffortsItem;
