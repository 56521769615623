import React from 'react';
import PropTypes from 'prop-types';
import sizeMe from 'react-sizeme';

import styles from './index.scss';

const NotVisibleStyle = {
  position: 'absolute',
  visibility: 'hidden',
  zIndex: '-9999'
};

class Option extends React.Component {
  /**
   * isVisible when the <li> is selected OR the list
   * is expanded
   */
  isVisible = () => this.props.isSelected || this.props.isExpanded;

  handleValueChange = () => {
    const { optionIndex, value, analyticsReporter } = this.props;
    this.props.valueChanged(optionIndex, value, analyticsReporter);
  };

  render() {
    const { detail, isSelected, name, title, refSelected, value } = this.props;

    return (
      <li
        className={styles.setting}
        style={this.isVisible() ? {} : NotVisibleStyle}
      >
        <label className={styles.label}>
          <input
            type="radio"
            name={name}
            value={value}
            checked={isSelected}
            onChange={this.handleValueChange}
            ref={isSelected && refSelected}
            tabIndex={isSelected ? '0' : '-1'}
          />
          <div className={styles.description}>
            <span className={styles.title}>{title}</span>
            <span className={styles.detail}>{detail}</span>
          </div>
        </label>
      </li>
    );
  }
}

Option.propTypes = {
  analyticsReporter: PropTypes.func.isRequired,
  detail: PropTypes.string.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  optionIndex: PropTypes.number.isRequired,
  refSelected: PropTypes.shape({}).isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  valueChanged: PropTypes.func.isRequired
};

export default sizeMe({ monitorWidth: false, monitorHeight: true })(Option);
