import * as React from 'react';
import PropTypes from 'prop-types';
import { color as uiToken } from '@strava/ui-tokens/js';

const HeartIconUnfilledSVG = ({ color = uiToken.colorCoreO3, size = 16 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden={true}
  >
    <path
      d="M14.2256 2.76413C13.4043 1.94233 12.3125 1.49023 11.1504 1.49023C9.98832 1.49023 8.89652 1.94233 8.07522 2.76363C8.04982 2.78903 8.02442 2.81443 8.00002 2.84033C7.97562 2.81443 7.95122 2.78903 7.92582 2.76413C6.23052 1.06783 3.46882 1.06883 1.77352 2.76413C0.0781156 4.46043 0.0781156 7.21973 1.77342 8.91603L8.00002 15.1421L14.2256 8.91603C15.0479 8.09473 15.5 7.00193 15.5 5.83983C15.5 4.67773 15.0479 3.58543 14.2256 2.76413ZM13.5186 8.20893L8.00002 13.7279L2.48052 8.20903C1.17482 6.90283 1.17482 4.77743 2.48052 3.47123C3.13382 2.81843 3.99222 2.49173 4.84962 2.49173C5.70802 2.49173 6.56542 2.81793 7.21782 3.47073C7.35742 3.61033 7.48152 3.75833 7.59182 3.91413L7.59742 3.92193H8.40282L8.40932 3.91263C8.51772 3.75783 8.64172 3.61043 8.78141 3.47123C10.047 2.20563 12.2531 2.20563 13.5187 3.47123C14.1515 4.10403 14.5001 4.94533 14.5001 5.83993C14.5001 6.73493 14.1515 7.57623 13.5187 8.20903L13.5186 8.20893Z"
      fill={color}
    />
  </svg>
);

HeartIconUnfilledSVG.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number
};

export default HeartIconUnfilledSVG;
