import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const Placeholder = ({
  height = '13px',
  width = '100%',
  className,
  ...otherProps
}) => (
  <div
    style={{ height, width }}
    className={`${styles.placeholder} ${className}`}
    {...otherProps}
  />
);

Placeholder.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

export default Placeholder;
