import { types } from './actions';

export default function reducer(state = {}, action) {
  let newState;
  let changedPartner;

  switch (action.type) {
    case types.VALUE_SAVED_FAILURE:
      changedPartner = state[action.partnerType][action.partnerName];
      return {
        ...state,
        [action.partnerType]: {
          ...state[action.partnerType],
          [action.partnerName]: {
            ...changedPartner,
            disabled: false
          }
        }
      };
    case types.VALUE_SAVED_REQUESTED:
      changedPartner = state[action.partnerType][action.partnerName];
      return {
        ...state,
        [action.partnerType]: {
          ...state[action.partnerType],
          [action.partnerName]: {
            ...changedPartner,
            disabled: true
          }
        }
      };
    case types.VALUE_SAVED_SUCCESS:
      changedPartner = state[action.partnerType][action.partnerName];
      newState = {
        ...state,
        [action.partnerType]: {
          ...state[action.partnerType],
          [action.partnerName]: {
            ...changedPartner,
            value: !changedPartner.value,
            disabled: false
          }
        },
        selected: action.partnerName
      };
      return { ...newState };

    default:
      return state;
  }
}
