import React, { useEffect } from 'react';
import I18n from 'utils/I18n';
import { trackV2 } from 'utils/analytics';
import PropTypes from 'prop-types';
import styles from './styles.scss';

const LimitedProfileEntry = ({ athleteName }) => {
  useEffect(() => {
    trackV2({
      page: 'athlete_profile',
      action: 'screen_enter',
      element: 'limited_profile_cta'
    });
  }, []);

  const I18nPrefix = 'strava.athletes.limited_profile';

  return (
    <div className={styles.content} data-testid="limited-profile-entry">
      <h3 className="text-title3">
        {I18n.t(`${I18nPrefix}.account_is_private`)}
      </h3>
      <div className={`text-footnote ${styles.description}`}>
        {I18n.t(`${I18nPrefix}.follow_athlete_cta`, { name: athleteName })}
      </div>
    </div>
  );
};

LimitedProfileEntry.propTypes = {
  athleteName: PropTypes.string.isRequired
};

export default LimitedProfileEntry;
