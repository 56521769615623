import createNetworkingClient from '../../utils/networking-client';

// these types represent the different states that the StarToggle component
// can be in
export const types = {
  TOGGLE_SEGMENT_STAR_SUCCESS: 'TOGGLE_SEGMENT_STAR_SUCCESS',
  TOGGLE_SEGMENT_STAR_FAILURE: 'TOGGLE_SEGMENT_STAR_FAILURE'
};

// this is the primary logic in the component, which performs a network call
// and based on the result of that network call dispatches success or failure
// state to the reducer
export const toggleSegmentStar = (segmentId, isActive) => (dispatch) => {
  const active = isActive ? 'off' : 'on';
  const url = `/segments/${segmentId}/starred`;

  const instance = createNetworkingClient();
  instance
    .put(url, { active, source: 'starred-segments' })
    .then(() => dispatch({ type: types.TOGGLE_SEGMENT_STAR_SUCCESS }))
    .catch(() => dispatch({ type: types.TOGGLE_SEGMENT_STAR_FAILURE }));
};

export default {
  types,
  toggleSegmentStar
};
