import React from 'react';
import PropTypes from 'prop-types';

import Button from '@strava/ui/Button';
import { ModalOverlay, ModalContent } from '@strava/ui/Modal';
import ActionsCancelNormalXsmall from '@strava/icons/ActionsCancelNormalXsmall';

import styles from './styles.scss';

const BaseModal = ({
  children,
  onSubmit = () => {},
  onDismiss = () => {},
  isSaving = false,
  hasChanges = false,
  title,
  buttonLabels,
  isOpen = false,
  hideSaveBtn = false
}) => {
  return (
    <ModalOverlay
      className={styles.overlay}
      isOpen={isOpen}
      onDismiss={onDismiss}
    >
      <ModalContent
        className={styles.modal}
        aria-labelledby="photo-lightbox-modal-title"
      >
        <div className={styles.content} data-testid="modal-content">
          <div className={styles.header}>
            <div className={styles.headerContent}>
              <h2
                data-testid="title"
                id="photo-lightbox-modal-title"
                className={`text-title3 text-book mt-0 mb-0 ${styles.headerTitle}`}
              >
                {title}
              </h2>
            </div>
            <Button
              variant="icon"
              data-testid="close-button"
              className={styles.closeButton}
              onClick={onDismiss}
              tabIndex={-1}
              type="button"
            >
              <ActionsCancelNormalXsmall />
            </Button>
          </div>

          <div className={styles.body}>
            <form onSubmit={onSubmit}>
              {children}
              {!hideSaveBtn && (
                <Button
                  variant="primary"
                  data-testid="save-button"
                  className={styles.saveBtn}
                  disabled={isSaving || !hasChanges}
                >
                  {/* eg. "Saving..." vs "Save" button labels */}
                  {isSaving ? buttonLabels.isSavingText : buttonLabels.saveText}
                </Button>
              )}
            </form>
          </div>
        </div>
      </ModalContent>
    </ModalOverlay>
  );
};

BaseModal.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func,
  onDismiss: PropTypes.func,
  isSaving: PropTypes.bool,
  hasChanges: PropTypes.bool,
  title: PropTypes.string.isRequired,
  buttonLabels: PropTypes.shape({
    saveText: PropTypes.string,
    isSavingText: PropTypes.string
  }).isRequired,
  hideSaveBtn: PropTypes.bool
};

export default BaseModal;
