import React, { useState } from 'react';
import PropTypes from 'prop-types';
import I18n from 'utils/I18n';
import Button from '@strava/ui/Button';

import styles from './styles.scss';

const I18nPrefix = 'strava.relative_effort.disclaimer';

function Disclaimer({ handleContinueClick }) {
  const [fadeOut, setFadeOut] = useState(false);

  return (
    <div className={`${styles.base} ${fadeOut ? styles.fadeOut : ''}`}>
      <div className={styles.dialog}>
        <div className={styles.content}>
          <h3 className="text-display1 mb-md">
            {I18n.t(`${I18nPrefix}.title`)}
          </h3>
          <p className="text-subhead">{I18n.t(`${I18nPrefix}.body`)}</p>
          <div className="mt-lg text-right">
            <Button
              type="button"
              variant="primary"
              className={`text-subhead ${styles.cta}`}
              onClick={() => {
                if (fadeOut) {
                  return;
                }
                setFadeOut(true);
                setTimeout(() => handleContinueClick(), 200);
              }}
            >
              {I18n.t(`${I18nPrefix}.cta`)}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

Disclaimer.propTypes = {
  handleContinueClick: PropTypes.func.isRequired
};

export default Disclaimer;
