const sortByDisplayName = (a, b) => {
  if (a.display < b.display) {
    return -1;
  }
  if (a.display > b.display) {
    return 1;
  }
  return 0;
};

// Sort by display name
// Remove any duplicate entries
// Remove viewing athlete from suggested results
export const sortAndFilter = (mentionableAthletes, viewingAthleteId) => {
  let entries = mentionableAthletes.sort((a, b) => sortByDisplayName(a, b));
  entries = Array.from(new Set(entries.map((a) => a.id))).map((id) => {
    return entries.find((a) => a.id === id);
  });
  entries = entries.filter(
    (obj) => String(obj?.id) !== String(viewingAthleteId)
  );
  return entries;
};

export default { sortAndFilter };
