import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Modal, { ModalTitle, ModalBody, ModalActions } from '@strava/ui/Modal';
import Button from '@strava/ui/Button';

import { csrfToken } from 'utils/networking-client';
import I18n from 'utils/I18n';

import styles from './styles.scss';

const CorrectDistance = ({ activityId, activeSource }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModalOpen = () => {
    setIsModalOpen(!isModalOpen);
  };

  const modalContents =
    activeSource === 'device' ? (
      <>
        <ModalBody>
          {I18n.t_html(
            'templates.activities.distance_correction.synthetic_html'
          )}
        </ModalBody>

        <ModalActions>
          <Button type="submit" variant="primary">
            {I18n.t(
              'templates.activities.distance_correction.correct_distance'
            )}
          </Button>
        </ModalActions>
      </>
    ) : (
      <>
        <ModalBody>
          {I18n.t_html(
            'templates.activities.distance_correction.synthetic_revert_html'
          )}
        </ModalBody>

        <ModalActions>
          <Button type="submit" variant="default">
            {I18n.t(
              'templates.activities.distance_correction.correct_distance_revert'
            )}
          </Button>
        </ModalActions>
      </>
    );

  const CorrectDistanceModal = (
    <Modal
      isOpen={isModalOpen}
      onDismiss={toggleModalOpen}
      hasClose={true}
      className={styles.modal}
      aria-labelledby="correct-distance-modal-title"
    >
      <form
        action={`/activities/${activityId}/swap_distance_stream?to_source=${
          activeSource === 'device' ? 'synthetic' : 'device'
        }`}
        method="post"
      >
        <input type="hidden" name="authenticity_token" value={csrfToken()} />

        <ModalTitle id="correct-distance-modal-title">
          {I18n.t('templates.activities.distance_correction.distance_help')}
        </ModalTitle>
        {modalContents}
      </form>
    </Modal>
  );

  return (
    <>
      {
        // This is needed in order to make the link match all the others in the rails side menu
        // TODO this should be changed to a sidebar of buttons for accesibility once moved out of rails
      }
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a href="#" onClick={toggleModalOpen}>
        {activeSource === 'device'
          ? I18n.t('templates.activities.distance_correction.correct_distance')
          : I18n.t(
              'templates.activities.distance_correction.correct_distance_revert'
            )}
      </a>
      {CorrectDistanceModal}
    </>
  );
};

CorrectDistance.propTypes = {
  activityId: PropTypes.number.isRequired,
  activeSource: PropTypes.string.isRequired
};

export default CorrectDistance;
