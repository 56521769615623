import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'utils/I18n';
import reUtils, {
  CONSTS,
  getZone,
  getPrimaryColor
} from 'utils/relative-effort';

import styles from './styles.scss';

export const I18nPrefix = 'strava.relative_effort.insight_week';

class WeekInsightText extends React.Component {
  getTitleText = (isCurrentWeek) => {
    const { cumulativeScore, buckets } = this.props;
    const zone = getZone(cumulativeScore, buckets);
    const titles = `${I18nPrefix}.${isCurrentWeek ? 'current' : 'past'}.titles`;

    switch (zone) {
      case CONSTS.ZONES.NONE:
        return I18n.t(`${titles}.none`);
      case CONSTS.ZONES.LOW:
        return I18n.t(`${titles}.low`);
      case CONSTS.ZONES.OPTIMUM:
        return I18n.t(`${titles}.optimum`);
      case CONSTS.ZONES.STEADY_PROGRESS:
        return I18n.t(`${titles}.steady_progress`);
      case CONSTS.ZONES.HIGH:
        return I18n.t(`${titles}.high`);
      case CONSTS.ZONES.VERY_HIGH:
        return I18n.t(`${titles}.very_high`);
      default:
        return '';
    }
  };

  getDescriptionText = (isCurrentWeek) => {
    const { cumulativeScore, buckets } = this.props;
    const zone = getZone(cumulativeScore, buckets);

    if (isCurrentWeek) {
      const descriptions = `${I18nPrefix}.current.descriptions`;
      switch (zone) {
        case CONSTS.ZONES.NONE:
          return I18n.t(`${descriptions}.none`);
        case CONSTS.ZONES.LOW:
          return I18n.t(`${descriptions}.low`, {
            value: parseInt(buckets[0], 10)
          });
        case CONSTS.ZONES.OPTIMUM:
          return I18n.t(`${descriptions}.optimum`);
        case CONSTS.ZONES.STEADY_PROGRESS:
          return I18n.t(`${descriptions}.steady_progress`, {
            value: parseInt(buckets[2] - cumulativeScore, 10)
          });
        case CONSTS.ZONES.HIGH:
          return I18n.t(`${descriptions}.high`);
        case CONSTS.ZONES.VERY_HIGH:
          return I18n.t(`${descriptions}.very_high`);
        default:
          return '';
      }
    } else {
      const descriptions = `${I18nPrefix}.past.descriptions`;
      switch (zone) {
        case CONSTS.ZONES.NONE:
          return I18n.t(`${descriptions}.none`);
        case CONSTS.ZONES.LOW:
          return I18n.t(`${descriptions}.low_v2`, {
            value: parseInt(buckets[0], 10)
          });
        case CONSTS.ZONES.OPTIMUM:
          return I18n.t(`${descriptions}.optimum_v2`);
        case CONSTS.ZONES.STEADY_PROGRESS:
          return I18n.t(`${descriptions}.steady_progress_v2`, {
            value: parseInt(buckets[2] - cumulativeScore, 10)
          });
        case CONSTS.ZONES.HIGH:
          return I18n.t(`${descriptions}.high_v2`);
        case CONSTS.ZONES.VERY_HIGH:
          return I18n.t(`${descriptions}.very_high_v2`);
        default:
          return '';
      }
    }
  };

  render() {
    const { activityWeekDate, cumulativeScore, buckets } = this.props;
    const isCurrentWeek = reUtils.isCurrentWeek(activityWeekDate);

    return (
      <>
        <div
          className="text-headline text-bold mb-xs"
          style={{ color: getPrimaryColor(cumulativeScore, buckets) }}
        >
          {this.getTitleText(isCurrentWeek)}
        </div>
        <div className={`text-footnote mb-sm ${styles.description}`}>
          {this.getDescriptionText(isCurrentWeek)}
        </div>
      </>
    );
  }
}

WeekInsightText.propTypes = {
  activityWeekDate: PropTypes.instanceOf(Date).isRequired,
  cumulativeScore: PropTypes.number.isRequired,
  buckets: PropTypes.arrayOf(PropTypes.number).isRequired
};

export default WeekInsightText;
