import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import I18n from 'utils/I18n';
import { trackV2 } from 'utils/analytics';
import createNetworkingClient from 'utils/networking-client';
import Spinner from '@strava/ui/Spinner';
import styles from './styles.scss';

import ExcludedSegmentEffortsModal from '../../segments/ExcludedSegmentEffortsModal';

const ExcludedEfforts = ({ activityId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [flaggedEfforts, setFlaggedEfforts] = useState([]);
  const [speedUnit, setSpeedUnit] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const url = '/frontend/segments/excluded_efforts';
      const params = { params: { entity: 'activity', id: activityId } };
      createNetworkingClient()
        .get(url, params)
        .then(({ data }) => {
          setFlaggedEfforts(data.flagged_efforts);
          setSpeedUnit(data.speed_unit);
        })
        .catch(() => {})
        .finally(() => {
          setIsLoading(false);
        });
    };

    fetchData();
  }, [activityId]);

  useEffect(() => {
    if (flaggedEfforts.length > 0) {
      trackV2({
        action: 'screen_enter',
        element: 'excluded_efforts',
        category: 'activity_detail',
        page: 'activity_detail'
      });
    }
  }, [flaggedEfforts]);

  const spinner = (
    <div className={styles.spinner}>
      <Spinner />
    </div>
  );

  const openModal = () => {
    setIsModalOpen(true);
    trackV2({
      action: 'click',
      element: 'excluded_efforts',
      category: 'activity_detail',
      page: 'activity_detail'
    });
    trackV2({
      action: 'screen_enter',
      category: 'segments',
      page: 'excluded_efforts'
    });
  };

  const closeModal = () => {
    setIsModalOpen(false);
    trackV2({
      action: 'screen_exit',
      category: 'segments',
      page: 'excluded_efforts'
    });
  };

  const button = (
    <>
      <button className="compact minimal" type="button" onClick={openModal}>
        {I18n.t(
          'strava.labs.activities.segments_view.excluded_efforts.show_lightbox',
          { count: flaggedEfforts.length }
        )}
      </button>
      <ExcludedSegmentEffortsModal
        entity="activity"
        id={activityId}
        flaggedEfforts={flaggedEfforts}
        speedUnit={speedUnit}
        isModalOpen={isModalOpen}
        onDismiss={closeModal}
      />
    </>
  );

  if (isLoading) {
    return spinner;
  }

  if (flaggedEfforts.length > 0) {
    return button;
  }

  return null;
};

ExcludedEfforts.propTypes = {
  activityId: PropTypes.number.isRequired
};

export default ExcludedEfforts;
