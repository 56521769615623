import createNetworkingClient from 'utils/networking-client';
import Analytics from 'utils/analytics';

const Helper = {};

Helper.pageStates = {
  FILTERED_STATE: 'filtered',
  UNFILTERED_STATE: 'unfiltered'
};
Helper.destinationUrl = `/challenges`;

let networkClientInstance = null;
Helper.instance = () => {
  if (!networkClientInstance) {
    networkClientInstance = createNetworkingClient({ timeout: 15000 });
  }
  return networkClientInstance;
};

Helper.reducer = {};
Helper.events = {};

Helper.reducer.actions = {
  CHALLENGE_JOINED_STATUS_CHANGE: 'CHALLENGE_JOIN_STATUS_CHANGE',
  DOB_UPDATE_FAILED: 'DOB_UPDATE_FAILED',
  DOB_UPDATE_REQUESTED: 'DOB_UPDATE_REQUESTED',
  DOB_UPDATE_SUCCESS: 'DOB_UPDATE_SUCCESS',
  FILTER_ADDED: 'FILTER_ADDED',
  FILTER_REMOVED: 'FILTER_REMOVED',
  FILTER_REMOVE_ALL: 'FILTER_REMOVE_ALL',
  FILTERED_REQUEST_SUCCEEDED: 'FILTERED_REQUEST_SUCCEEDED',
  FILTERED_REQUEST_FAILED: 'FILTERED_REQUEST_FAILED',
  MODAL_CHANGE_DATE: 'MODAL_CHANGE_DATE',
  MODAL_CLOSE: 'MODAL_CLOSE',
  MODAL_OPEN: 'MODAL_OPEN',
  UNFILTERED_REQUEST_SUCCEEDED: 'UNFILTERED_REQUEST_SUCCEEDED',
  UNFILTERED_REQUEST_FAILED: 'UNFILTERED_REQUEST_SUCCEEDED'
};

Helper.reducer.reduce = (state, action) => {
  const data = { ...state };

  if (action.name === Helper.reducer.actions.CHALLENGE_JOINED_STATUS_CHANGE) {
    data.challengesJoinStatus[action.challengeId] = action.joinedStatus;
    data.gatingModal = null;
  }

  if (action.name === Helper.reducer.actions.DOB_UPDATE_FAILED) {
    data.gatingModal = {
      ...data.gatingModal,
      requestInProgress: false
    };
  }

  if (action.name === Helper.reducer.actions.DOB_UPDATE_REQUESTED) {
    data.gatingModal = {
      ...data.gatingModal,
      requestInProgress: true
    };
  }

  if (action.name === Helper.reducer.actions.DOB_UPDATE_SUCCESS) {
    const { date } = action;
    const dateOfBirth = [
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate()
    ].join('-');

    data.currentAthlete = { ...data.currentAthlete, dateOfBirth };
    data.gatingModal = {
      ...data.gatingModal,
      requestInProgress: false
    };
  }

  if (action.name === Helper.reducer.actions.FILTER_ADDED) {
    if (!data.selectedFilters.includes(action.identifier)) {
      data.selectedFilters = [...data.selectedFilters, ...[action.identifier]];
      Helper.events.trackFilterChanged(
        'selection',
        data.selectedFilters.map((filter) => filter)
      );
    }
  }

  if (action.name === Helper.reducer.actions.FILTER_REMOVED) {
    if (data.selectedFilters.includes(action.identifier)) {
      data.selectedFilters = data.selectedFilters.filter(
        (filterName) => filterName !== action.identifier
      );
    }

    if (!data.selectedFilters.length) {
      data.pageState = Helper.pageStates.UNFILTERED_STATE;
    }

    Helper.events.trackFilterChanged(
      'deselection',
      data.selectedFilters.map((filter) => filter)
    );
  }

  if (action.name === Helper.reducer.actions.FILTER_REMOVE_ALL) {
    data.selectedFilters = [];
    data.pageState = Helper.pageStates.UNFILTERED_STATE;
    Helper.events.trackFilterChanged('clear_all', []);
  }

  if (action.name === Helper.reducer.actions.FILTERED_REQUEST_SUCCEEDED) {
    data.filteredChallenges = action.data.challenges;
    data.availableFilters = action.data.filters;
    data.pageState =
      data.selectedFilters.length > 0
        ? Helper.pageStates.FILTERED_STATE
        : Helper.pageStates.UNFILTERED_STATE;
  }

  if (action.name === Helper.reducer.actions.MODAL_CHANGE_DATE) {
    data.gatingModal = {
      ...data.gatingModal,
      ageGate: {
        ...(data.gatingModal && data.gatingModal.ageGate),
        dateSelected: action.date
      }
    };
  }

  if (action.name === Helper.reducer.actions.MODAL_CLOSE) {
    data.gatingModal = null;
  }

  if (action.name === Helper.reducer.actions.MODAL_OPEN) {
    const { gatingConditions, joinChallengeFn, dateSelected } = action;
    data.gatingModal = {
      gatingConditions,
      joinChallengeFn,
      ageGate: { dateSelected },
      requestInProgress: false
    };
  }

  if (action.name === Helper.reducer.actions.UNFILTERED_REQUEST_SUCCEEDED) {
    data.availableFilters = action.data.filters;
    data.pageState = Helper.pageStates.UNFILTERED_STATE;
  }

  if (action.name === Helper.reducer.actions.FILTERED_REQUEST_FAILED) {
    data.filteredChallenges = [];
    data.pageState = Helper.pageStates.UNFILTERED_STATE;
  }
  return data;
};

Helper.reducer.initialState = (promotedChallenge, gallerySections, filters) => {
  const hash = {
    challengesJoinStatus: {}, // responsible for flipping join button states
    currentAthlete: null, // holds changes that have been made to currentAthlete
    gatingModal: null, // optional object that describes the (age) gating modal
    selectedFilters: [], // controls which filters are selected
    availableFilters: filters, // controls which filters are visible
    pageState: Helper.pageStates.UNFILTERED_STATE, // controls which state of the page to show
    filteredChallenges: [] // Challenges that are displayed when a filter is active
  };

  hash.challengesJoinStatus[promotedChallenge.id] = promotedChallenge.joined;
  Object.keys(gallerySections).forEach((key) => {
    gallerySections[key].challenges.forEach((challenge) => {
      hash.challengesJoinStatus[challenge.id] = challenge.joined;
    });
  });
  return hash;
};

Helper.requestFilteredChallenges = (selectedFilters, dispatch) => {
  return Helper.instance()
    .get(Helper.destinationUrl, {
      params: {
        json: true,
        filters: selectedFilters
      }
    })
    .then((response) => {
      dispatch({
        name:
          selectedFilters.length > 0
            ? Helper.reducer.actions.FILTERED_REQUEST_SUCCEEDED
            : Helper.reducer.actions.UNFILTERED_REQUEST_SUCCEEDED,
        data: response.data
      });
    })
    .catch(() =>
      dispatch({
        name:
          selectedFilters.length > 0
            ? Helper.reducer.actions.FILTERED_REQUEST_FAILED
            : Helper.reducer.actions.UNFILTERED_REQUEST_FAILED
      })
    );
};

Helper.joinChallenge = ({
  externalJoinUrl,
  challengeId,
  joined,
  locale,
  loggedIn,
  dispatch
}) => {
  if (externalJoinUrl) {
    window.location = externalJoinUrl;
    return true;
  }
  if (loggedIn) {
    return createNetworkingClient()
      .post(
        joined
          ? `/challenges/${challengeId}/leave`
          : `/challenges/${challengeId}/join`
      )
      .then((response) => {
        if (response && (response.status === 204 || response.status === 200)) {
          dispatch({
            name: Helper.reducer.actions.CHALLENGE_JOINED_STATUS_CHANGE,
            challengeId,
            joinedStatus: !joined
          });
        } else {
          window.location = `/errors/500?hl=${locale}`;
        }
      });
  }
  return Helper.instance()
    .get(
      `/challenges/${challengeId}/login_and_join?redirect_url=${window.location}`
    )
    .then((response) => {
      const { data } = response;
      if (data.redirect && data.redirectUrl) {
        window.location = data.redirectUrl;
      } else {
        window.location = `/errors/500?hl=${locale}`;
      }
    });
};

Helper.events.trackChallengeClick = (id, element) => {
  Analytics.trackV2({
    action: 'click',
    element,
    category: 'challenges',
    page: 'challenge_gallery',
    properties: { challenge_id: id }
  });
};

Helper.events.trackFilterChanged = (actionType, selectedFilters) => {
  Analytics.trackV2({
    action: 'click',
    element: 'filter',
    category: 'challenges',
    page: 'challenge_gallery',
    properties: { select_type: actionType, filter: selectedFilters }
  });
};
export default Helper;
