import React from 'react';
import PropTypes from 'prop-types';

import I18n from 'utils/I18n';
import styles from './styles.scss';
import FollowersList from '../FollowersList';
import TaggingLinkCopy from '../TaggingLinkCopy';

const TaggingInviteContainer = (props) => (
  <div className={styles.inviteContainer}>
    <h2 className={styles.title}>
      {I18n.t('strava.activities.tagging.invite_module.title')}
    </h2>
    <div className={styles.inviteContainerBody}>
      <FollowersList />
    </div>
    <div className={styles.footer}>
      <h3 className={styles.footerTitle}>
        {I18n.t('strava.activities.tagging.invite_module.not_on_strava')}
      </h3>
      <p className={styles.footerDescription}>
        {I18n.t('strava.activities.tagging.invite_module.share_activity')}
      </p>
      <TaggingLinkCopy
        branchLinkData={props.branchLinkData}
        activityId={props.activityId}
        activityType={props.activityType}
        athleteName={props.athleteName}
        athleteId={props.athleteId}
      />
    </div>
  </div>
);

TaggingInviteContainer.propTypes = {
  activityId: PropTypes.number.isRequired,
  activityType: PropTypes.string.isRequired,
  athleteId: PropTypes.number.isRequired,
  athleteName: PropTypes.string.isRequired,
  branchLinkData: PropTypes.shape({}).isRequired
};

export default TaggingInviteContainer;
