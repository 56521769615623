import { useState } from 'react';
import createNetworkingClient from 'utils/networking-client';
import { logError } from '../../../../../utils/sentry';

const useFetchFollowers = ({ resultsPerPage }) => {
  const [results, setResults] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const errorState = (e) => {
    setError(e);
    setIsLoading(false);
    logError(e);
  };

  /**
   * Computes the page number that is to be used for retrieving more results based on
   * the current number of rows that have already been fetched.
   * eg. If an athlete has 50 rows retrieved, the next page is 50/25 + 1 (== 2)
   *
   * @param {boolean} reset - forces the page number to be 1
   * @returns {number} representing the page number which is used for pagination
   */
  const pageNum = (reset) =>
    reset ? 1 : Math.ceil(results.length / resultsPerPage) + 1;

  /**
   *  Retrieves follows data using endpoint FollowsController#index
   * @param {Object} param
   * @param {String} param.selectionOption the type of follows selected by athlete (eg. "suggested", "blocked", "following")
   * @param {Boolean} param.reset a switch to indicate if an athlete changed the follows type. When the value is true, it means that
   * a new type of follows was selected so we want to clear the results instead of appending to the previous results.
   */
  const loadFollows = async ({ selectedOption: followsType, reset }) => {
    setIsLoading(true);
    setHasMore(false);
    const url = `follows?type=${followsType}&page=${pageNum(reset)}`;

    if (reset) {
      // start all over again when the follows type has been changed
      setResults([]);
    }

    try {
      await createNetworkingClient()
        .get(url, { params: {} })
        .then((response) => {
          if (response && response.status === 200) {
            const { data } = response;
            const fetchedFollows = data.follows;

            setResults((prev) => [...prev, ...fetchedFollows]);
            setHasMore(fetchedFollows.length >= resultsPerPage);
            setIsLoading(false);
          } else {
            errorState(response.error);
          }
        })
        .catch((err) => {
          errorState(err);
        });
    } catch (err) {
      errorState(err);
    }
  };

  return { loadFollows, isLoading, results, hasMore, error };
};

export default useFetchFollowers;
