import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@strava/ui/Button';
import I18n from 'utils/I18n';
import SvgActionsKudoNormalXsmall from '@strava/icons/ActionsKudoNormalXsmall';
import SvgActionsKudoHighlightedXsmall from '@strava/icons/ActionsKudoHighlightedXsmall';
import { color } from '@strava/ui-tokens/js';
import SvgActionsCommentNormalXsmall from '@strava/icons/ActionsCommentNormalXsmall';
import Cldr from 'utils/Cldr';
import {
  OPEN_MODAL_COMMENTS_TAB,
  OPEN_MODAL_KUDOS_TAB
} from '../../../components/shared/KudosCommentsAndAchievements/kudosAndComments.actions';
import styles from './styles.scss';
import { updateKudosEndpoint } from '../../../components/shared/utils/updateKudosEndpoint';
import KudosAndCommentsContext from '../../../components/shared/KudosCommentsAndAchievements/KudosAndCommentsContext';
import useKudosAndCommentsReducer from '../../../hooks/kudosAndComments/useKudosAndCommentsReducer';
import { handleKudosAndCommentsNotificationLinkNavigation } from '../../../components/shared/utils/handleKudosAndCommentsNotificationLinkNavigation';
import KudosCommentsAndAchievementsModal from '../../../components/shared/KudosCommentsAndAchievements/KudosCommentsAndAchievementsModal/KudosCommentsAndAchievementsModal';
import {
  commentsProps,
  entitiesWithKudosAndComments,
  viewingAthleteProps
} from '../../../components/Feed/props/feed-prop-types';
import CommentDeleteAlertDialog from '../../../components/shared/KudosCommentsAndAchievements/CommentDeleteAlertDialog';
import onClickKudo from '../../../components/shared/utils/onClickKudo';

const ADPKudosAndComments = ({
  canKudo,
  commentsEnabled,
  comments,
  kudosCount,
  entity,
  entityId,
  ownerName,
  ownerAvatarUrl,
  ownerAthleteId,
  activityName,
  clubMentionsEnabled,
  ownedByCurrentAthlete,
  viewingAthlete
}) => {
  const [canKudoStatus, setCanKudoStatus] = useState(canKudo);
  const [currentKudoCount, setCurrentKudoCount] = useState(kudosCount);
  const [hasKudoed, setHasKudoed] = useState(false);
  const entityEndpoint = updateKudosEndpoint({ entity });
  const prefix = 'components.feed.social';

  const { kudosAndCommentsEntityState, dispatch } = useKudosAndCommentsReducer(
    entityId,
    entity,
    comments
  );
  const providerState = {
    kudosAndCommentsEntityState,
    dispatch
  };

  // Using comments from kudosAndCommentsEntityState rather than ruby props
  // allows us to know when a new comment has been posted
  const entityStateComments = kudosAndCommentsEntityState.comments[entityId];
  const commentReactionAnalytics = {
    category: 'activity_detail',
    page: 'comment',
    buttonElement: 'like_comment_btn_on_modal',
    listElement: 'like_list_btn_on_modal'
  };
  const entityOwner = {
    displayImage: ownerAvatarUrl,
    name: ownerName,
    profileUrl: `/athletes/${ownerAthleteId}`,
    type: 'athlete'
  };

  const onClickKudoSuccessful = useCallback(() => {
    // Not allowed to kudo more than once
    setCanKudoStatus(false);
    setHasKudoed(true);
  }, []);

  useEffect(() => {
    if (kudosAndCommentsEntityState.kudoPosted) {
      setCurrentKudoCount((prev) => prev + 1);
      onClickKudoSuccessful();
    }
  }, [kudosAndCommentsEntityState.kudoPosted, onClickKudoSuccessful, dispatch]);

  useEffect(() => {
    handleKudosAndCommentsNotificationLinkNavigation({ entityId, dispatch });

    const handleHashChange = (event) => {
      // clicking on a notification to view kudos or comments on the current activity will
      // add the `kudos` or `comments` hash to the url but not reload the page. We need to listen
      // to the hash change event to open the modal when this happens.
      handleKudosAndCommentsNotificationLinkNavigation({
        entityId,
        dispatch,
        url: event.newUrl
      });
    };

    window.addEventListener('hashchange', handleHashChange);

    return () => window.removeEventListener('hashchange', handleHashChange);
  }, [dispatch, entityId]);

  return (
    <KudosAndCommentsContext.Provider value={providerState}>
      <React.Suspense fallback={<div />}>
        <div className={styles.adpKudosCommentsContainer}>
          <div className={styles.kudosButtonContainer}>
            {canKudoStatus && (
              <Button
                className={styles.giveKudosBtn}
                onClick={() =>
                  onClickKudo({
                    entityEndpoint,
                    entityId,
                    dispatch,
                    onClickKudoSuccessful
                  })
                }
                data-testid="give-kudos-btn"
              >
                {I18n.t(`${prefix}.icon.give_kudos`)}
              </Button>
            )}
            <Button
              type="button"
              variant="default"
              className={styles.socialButton}
              title={I18n.t(`${prefix}.view_all_kudos`)}
              onClick={() =>
                dispatch({
                  type: OPEN_MODAL_KUDOS_TAB,
                  payload: {
                    element: 'kudo_icon',
                    selectedEntityId: entityId
                  }
                })
              }
              data-testid="adp-kudos_button"
            >
              {canKudoStatus || ownedByCurrentAthlete ? (
                <SvgActionsKudoNormalXsmall
                  fill={color.colorExtendedNeutralN2}
                  data-testid="unfilled_kudos"
                  className={styles.unfilledKudoIcon}
                />
              ) : (
                <SvgActionsKudoHighlightedXsmall
                  className={hasKudoed ? styles.kudoedIcon : styles.kudoIcon}
                  fill={color.colorCoreO3}
                  data-testid="filled_kudos"
                />
              )}
              <div title={I18n.t(`${prefix}.view_all_kudos`)}>
                <span data-testid="kudos_count" className={styles.count}>
                  {currentKudoCount}
                </span>
              </div>
            </Button>
          </div>

          {commentsEnabled && (
            <Button
              type="button"
              variant="default"
              className={styles.socialButton}
              data-testid="open_comment_modal_button"
              onClick={() =>
                dispatch({
                  type: OPEN_MODAL_COMMENTS_TAB,
                  payload: { element: 'comment', selectedEntityId: entityId }
                })
              }
              title={I18n.t(`${prefix}.view_all_comments`)}
            >
              <SvgActionsCommentNormalXsmall className={styles.commentIcon} />
              <span data-testid="comments_count" className={styles.count}>
                {Cldr.formatDecimal(entityStateComments.length)}
              </span>
            </Button>
          )}
          {(kudosAndCommentsEntityState.openKudosCommentModal ||
            kudosAndCommentsEntityState.isViewingCommentReactionsList) &&
            kudosAndCommentsEntityState.selectedEntityId === entityId && (
              <KudosCommentsAndAchievementsModal
                entity={entity}
                entityId={entityId}
                entityOwner={entityOwner}
                hasOtherAthletes={entity === 'group_activity'}
                title={activityName}
                viewingAthlete={viewingAthlete}
                kudosCount={currentKudoCount}
                clubMentionsEnabled={clubMentionsEnabled}
                commentsEnabled={commentsEnabled}
                segAndBestEffortAchievements={[]}
                commentReactionAnalytics={commentReactionAnalytics}
              />
            )}
          <CommentDeleteAlertDialog />
        </div>
      </React.Suspense>
    </KudosAndCommentsContext.Provider>
  );
};

ADPKudosAndComments.propTypes = {
  canKudo: PropTypes.bool.isRequired,
  comments: commentsProps.isRequired,
  kudosCount: PropTypes.number.isRequired,
  clubMentionsEnabled: PropTypes.bool.isRequired,
  entityId: PropTypes.string.isRequired,
  ownerName: PropTypes.string.isRequired,
  ownerAvatarUrl: PropTypes.string.isRequired,
  ownedByCurrentAthlete: PropTypes.bool.isRequired,
  ownerAthleteId: PropTypes.number.isRequired,
  activityName: PropTypes.string.isRequired,
  commentsEnabled: PropTypes.bool.isRequired,
  entity: entitiesWithKudosAndComments.isRequired,
  viewingAthlete: viewingAthleteProps.isRequired
};

export default ADPKudosAndComments;
