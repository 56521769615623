import React, { useEffect, useState } from 'react';

import { TranslationContext } from '@strava/container-context';
import EmbedModal from '@strava/embed-modal';
import I18n from 'utils/I18n';

const validateEmbedModalProps = (props) => {
  if (typeof props.embedId !== 'string' || props.embedId === '') {
    console.error('embed modal props.embedId must be a non-empty string');
    return false;
  }
  const validEmbedTypes = ['activity', 'route'];
  if (!validEmbedTypes.includes(props.embedType)) {
    console.error(
      `embed modal props.embedType must be one of ${validEmbedTypes.join(', ')}`
    );
    return false;
  }
  return true;
};

const EmbedModalWrapper = () => {
  const [embedModalProps, setEmbedModalProps] = useState(null);

  useEffect(() => {
    function handleOpenDialog(e) {
      // do a runtime check of the embed modal props so that we fail early
      // with a good warning rather than generating an invalid embed modal
      if (!validateEmbedModalProps(e.detail)) {
        return;
      }
      const { embedId, embedType } = e.detail;
      setEmbedModalProps({ embedId, embedType });
    }

    document.addEventListener('OpenEmbedDialog', handleOpenDialog);

    return () => {
      document.removeEventListener('OpenEmbedDialog', handleOpenDialog);
    };
  }, []);

  function closeModal() {
    setEmbedModalProps(null);
  }

  if (!embedModalProps) {
    return null;
  }

  const { embedType, embedId } = embedModalProps;

  return (
    <TranslationContext.Provider
      value={{
        t: (key, props = {}) => I18n.t(key, props),
        getLanguage: () => I18n.language()
      }}
    >
      <EmbedModal
        isOpen={true}
        onDismiss={closeModal}
        embedType={embedType}
        embedId={embedId}
      />
    </TranslationContext.Provider>
  );
};

export default EmbedModalWrapper;
