import React from 'react';
import PropTypes from 'prop-types';

import Analytics from 'utils/analytics';
import I18n from 'utils/I18n';

import { connect } from 'react-redux';

import InviteFriends from './InviteFriends';
import ChallengeJoinButton from './ChallengeJoinButton';
import DataGrid from '../DataGrid';

import styles from './styles.scss';
import AgeGateModal from '../../../shared/components/AgeGateModal';
import {
  handleModalClose,
  handleDateSelectionChange,
  handleDateUpdateInProgress,
  handleDateUpdateSuccess,
  handleDateUpdateFailure
} from '../../actions';

export const I18nPrefix = 'strava.challenges.challenge_detail';

export const TopSidebar = ({
  currentAthlete,
  header,
  challengeId,
  ended,
  joined,
  externalJoinUrl,
  completed,
  rewardUrl = '',
  rewardButtonText,
  loggedIn,
  participantCount,
  auxiliaryStats,
  teamStats,
  gatingConditions,
  gatingModal,
  onHandleModalClose,
  onHandleDateSelectionChange,
  onHandleDateUpdateInProgress,
  onHandleDateUpdateSuccess,
  onHandleDateUpdateFailure,
  locale,
  onlyTeamStats = false,
  showTeamStats
}) => {
  function trackRewardAnalytics() {
    Analytics.trackV2({
      action: 'click',
      category: 'challenges',
      element: 'redeem_reward',
      page: 'challenge_details',
      properties: { challenge_id: challengeId }
    });
  }

  function redeemReward() {
    return (
      <a
        key="rewardButton"
        href={rewardUrl}
        target="_blank"
        rel="noopener noreferrer"
        onClick={trackRewardAnalytics}
        className={`btn btn-primary btn-sm btn-block text-footnote text-bold ${styles.button}`}
      >
        {rewardButtonText}
      </a>
    );
  }

  function inviteFriends(outline) {
    if (!loggedIn) {
      return null;
    }

    return (
      <InviteFriends
        key="inviteFriends"
        outline={outline}
        challengeId={challengeId}
        currentAthlete={currentAthlete}
        challengeName={header.name}
        challengeLogoUrl={header.challengeLogoUrl}
      />
    );
  }

  function moreChallenges(outline) {
    return (
      <a
        key="moreChallenges"
        href="/challenges"
        className={`btn btn-primary btn-sm btn-block text-footnote text-bold ${
          styles.button
        } ${outline && 'btn-outline'}`}
      >
        {I18n.t(`${I18nPrefix}.more_challenges`)}
      </a>
    );
  }

  function joinButton() {
    return (
      <ChallengeJoinButton
        key="joinButton"
        {...{
          challengeId,
          currentAthlete,
          joined,
          loggedIn,
          externalJoinUrl,
          gatingConditions
        }}
      />
    );
  }

  function buttonGroup() {
    if (!joined) {
      return ended ? [moreChallenges()] : [joinButton(), inviteFriends(true)];
    }
    if (!completed) {
      return ended ? [moreChallenges()] : [joinButton(), inviteFriends()];
    }
    if (rewardUrl) {
      return ended
        ? [redeemReward(), moreChallenges(true)]
        : [redeemReward(), inviteFriends(true)];
    }
    return ended ? [moreChallenges()] : [moreChallenges(), inviteFriends(true)];
  }

  function overallStatsHeading() {
    if (auxiliaryStats.length > 0) {
      return (
        <h2 className={`text-headline ${styles.header}`}>
          {I18n.t(`${I18nPrefix}.overall_stats`)}
        </h2>
      );
    }
    return null;
  }

  function overallStats() {
    if (auxiliaryStats.length > 0) {
      return (
        <div className={styles.dataGrid}>
          {auxiliaryStats && (
            <DataGrid
              dataGrid={auxiliaryStats}
              containerClass={styles.statsRow}
            />
          )}
        </div>
      );
    }
    return null;
  }

  function teamStatsHeading() {
    if (showTeamStats && teamStats.length > 0) {
      return (
        <h2 className={`text-headline ${styles.header}`}>
          {I18n.t(`${I18nPrefix}.team_stats`)}
        </h2>
      );
    }
    return null;
  }

  function teamStatsList() {
    if (teamStats.length > 0) {
      return (
        <div className={styles.dataGrid}>
          {teamStats && (
            <DataGrid dataGrid={teamStats} containerClass={styles.statsRow} />
          )}
        </div>
      );
    }
    return null;
  }

  if (onlyTeamStats) {
    return (
      <p className={styles.container}>
        {teamStatsHeading()}
        {teamStatsList()}
      </p>
    );
  }
  return (
    <div className={styles.container}>
      <div className={styles.buttonGroup}>{buttonGroup()}</div>
      <div className={styles.dataGrid}>
        {participantCount && teamStats.length === 0 && (
          <DataGrid dataGrid={participantCount} />
        )}
      </div>
      {overallStatsHeading()}
      {overallStats()}
      {showTeamStats && teamStatsHeading()}
      {showTeamStats && teamStatsList()}
      <AgeGateModal
        handleModalClose={onHandleModalClose}
        handleDateSelectionChange={onHandleDateSelectionChange}
        handleDateUpdateInProgress={onHandleDateUpdateInProgress}
        handleDateUpdateSuccess={onHandleDateUpdateSuccess}
        handleDateUpdateFailure={onHandleDateUpdateFailure}
        currentAthlete={currentAthlete}
        gatingModal={gatingModal}
        locale={locale}
      />
    </div>
  );
};

TopSidebar.propTypes = {
  currentAthlete: PropTypes.shape({}),
  header: PropTypes.shape({
    name: PropTypes.string.isRequired,
    challengeLogoUrl: PropTypes.string.isRequired
  }).isRequired,
  challengeId: PropTypes.number.isRequired,
  ended: PropTypes.bool.isRequired,
  joined: PropTypes.bool.isRequired,
  externalJoinUrl: PropTypes.string,
  completed: PropTypes.bool.isRequired,
  rewardUrl: PropTypes.string,
  rewardButtonText: PropTypes.string.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  participantCount: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  auxiliaryStats: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  teamStats: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  gatingConditions: PropTypes.shape({}),
  gatingModal: PropTypes.shape({}),
  onHandleModalClose: PropTypes.func.isRequired,
  onHandleDateSelectionChange: PropTypes.func.isRequired,
  onHandleDateUpdateInProgress: PropTypes.func.isRequired,
  onHandleDateUpdateSuccess: PropTypes.func.isRequired,
  onHandleDateUpdateFailure: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  onlyTeamStats: PropTypes.bool,
  showTeamStats: PropTypes.bool.isRequired
};

export default connect(
  (state) => ({
    currentAthlete: state.currentAthlete,
    header: state.header,
    challengeId: state.challengeId,
    ended: state.ended,
    joined: state.joined,
    externalJoinUrl: state.externalJoinUrl,
    completed: state.completed,
    rewardUrl: state.rewardUrl,
    rewardButtonText: state.rewardButtonText,
    loggedIn: state.loggedIn,
    participantCount: state.participantCount,
    auxiliaryStats: state.auxiliaryStats,
    teamStats: state.teamStats,
    gatingConditions: state.gatingConditions,
    gatingModal: state.gatingModal
  }),
  {
    onHandleModalClose: handleModalClose,
    onHandleDateSelectionChange: handleDateSelectionChange,
    onHandleDateUpdateInProgress: handleDateUpdateInProgress,
    onHandleDateUpdateSuccess: handleDateUpdateSuccess,
    onHandleDateUpdateFailure: handleDateUpdateFailure
  }
)(TopSidebar);
