import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { displayName } from 'utils/generalUtils';

const AthleteName = ({ firstName, lastName }) => {
  const [name, setName] = useState({ firstName, lastName });

  const handleNameChange = useCallback((e) => {
    const { value, name: targetName } = e.target;
    setName((prev) => {
      return { ...prev, [targetName]: value };
    });
  }, []);

  useEffect(() => {
    window.addEventListener('OnBoardingNameChange', handleNameChange, false);
    return () => {
      window.removeEventListener('OnBoardingNameChange', handleNameChange);
    };
  }, [handleNameChange]);

  return <h2 data-cy="dashboard-athlete-name">{displayName(name)}</h2>;
};

AthleteName.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired
};
export default AthleteName;
