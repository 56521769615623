import React from 'react';
import PropTypes from 'prop-types';

import reUtils from 'utils/relative-effort';

import WeekInsightText from './WeekInsightText';
import WeekInsightStats from './WeekInsightStats';
import WeeklyGraph from './WeeklyGraph';
import DateRange from './DateRange';

export const I18nPrefix = 'strava.relative_effort';

class WeeklySummary extends React.Component {
  snapToPoint = (index) => {
    const { onSelectedWeekIndexUpdate } = this.props;
    onSelectedWeekIndexUpdate(index);
  };

  onMouseEnter = () => {
    clearTimeout(this.mouseLeaveTimer);
  };

  onMouseLeave = () => {
    const { onSelectedWeekIndexUpdate, defaultWeekIndex } = this.props;

    clearTimeout(this.mouseLeaveTimer);
    this.mouseLeaveTimer = setTimeout(() => {
      onSelectedWeekIndexUpdate(defaultWeekIndex);
    }, 250);
  };

  render() {
    const { weeklyScores, selectedWeekIndex, defaultWeekIndex } = this.props;
    const selectedWeek = weeklyScores[selectedWeekIndex];
    const { year, week } = selectedWeek;
    const activityWeekDate = reUtils.getDateFromYearWeek(year, week);

    return (
      <>
        <div className="mb-md">
          <WeekInsightText
            activityWeekDate={activityWeekDate}
            cumulativeScore={selectedWeek.cumulative_score}
            buckets={selectedWeek.buckets}
          />
        </div>
        <div className="mb-md">
          <DateRange date={activityWeekDate} />
        </div>
        <div className="mb-lg">
          <WeekInsightStats
            cumulativeScore={selectedWeek.cumulative_score}
            dailyScores={selectedWeek.daily_scores}
            buckets={selectedWeek.buckets}
          />
        </div>
        <div onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
          <WeeklyGraph
            defaultWeekIndex={defaultWeekIndex}
            selectedWeekIndex={selectedWeekIndex}
            weeklyScores={weeklyScores}
            snapToPoint={this.snapToPoint}
          />
        </div>
      </>
    );
  }
}

WeeklySummary.propTypes = {
  weeklyScores: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultWeekIndex: PropTypes.number.isRequired,
  selectedWeekIndex: PropTypes.number.isRequired,
  onSelectedWeekIndexUpdate: PropTypes.func.isRequired
};

export default WeeklySummary;
