import React from 'react';
import PropTypes from 'prop-types';
import Button from '@strava/ui/Button';

import I18n from 'utils/I18n';

import banner from '../../assets/banner-tagging-interstitial.jpg';
import retinaBanner from '../../assets/banner-tagging-interstitial@2x.jpg';
import styles from './styles.scss';

const TaggingInterstitial = (props) => (
  <div className={styles.interstitialContainer}>
    <div className="card">
      <img
        alt="Invite"
        className={`${styles.banner} card-image-top`}
        src={banner}
        srcSet={`${banner} 1x, ${retinaBanner} 2x`}
      />
      <div className={styles.body}>
        <h2 className={styles.title}>
          {I18n.t('strava.activities.tagging.feature_ed.title', {
            activity_type: I18n.t(
              `strava.activities.types_v2.${props.activityType}`
            )
          })}
        </h2>
        <p className={styles.description}>
          {I18n.t('strava.activities.tagging.feature_ed.description')}
        </p>
        <p className={styles.description}>
          {I18n.t('strava.activities.tagging.feature_ed.privacy')}
        </p>
        <div className="mt-lg text-right">
          <Button
            type="button"
            variant="primary"
            className={styles.continueButton}
            onClick={props.triggerContinue}
          >
            {I18n.t('strava.activities.tagging.feature_ed.cta')}
          </Button>
        </div>
      </div>
    </div>
  </div>
);

TaggingInterstitial.propTypes = {
  activityType: PropTypes.string.isRequired,
  triggerContinue: PropTypes.func.isRequired
};

export default TaggingInterstitial;
