import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import styles from './styles.scss';

const Breadcrumbs = ({ breadcrumbs }) => {
  const [first, ...crumbs] = breadcrumbs;

  return (
    <ol className={`${styles.list} mb-0`}>
      <li className={styles.item}>
        <a href={first.href}>{first.text}</a>
      </li>

      {crumbs.map((b, i) => {
        const last = i === crumbs.length - 1;

        return (
          <li key={b.href} className={styles.item}>
            {last ? b.text : <Link to={b.href}>{b.text}</Link>}
          </li>
        );
      })}
    </ol>
  );
};

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    })
  ).isRequired
};

export default Breadcrumbs;
