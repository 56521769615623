import React from 'react';
import PropTypes from 'prop-types';
import Button from '@strava/ui/Button';
import Spinner from '@strava/ui/Spinner';
import Avatar from '@strava/ui/Avatar';

import I18n from 'utils/I18n';

import styles from './styles.scss';
import { followerStatus } from '../../const';

class FollowersListItem extends React.Component {
  addButtonClickHandler = (athleteId, activityId) => () => {
    this.props.handleInvite(athleteId, activityId);
  };

  addButtonClassName = () => {
    const { follower } = this.props;
    const status =
      follower.status === followerStatus.COMPLETE ? styles.complete : '';

    return `${styles.addButton} ${status}`;
  };

  addButtonVariant = () => {
    const { follower } = this.props;

    if (follower.status === followerStatus.READY) {
      return 'primaryOutline';
    }
    return 'default';
  };

  renderSubmitButtonContent = () => {
    switch (this.props.follower.status) {
      case followerStatus.SUBMITTING:
        return (
          <div data-testid="loading">
            <Spinner />
          </div>
        );
      case followerStatus.COMPLETE:
        return I18n.t('strava.activities.tagging.invite_module.requested');
      default:
        return I18n.t('strava.activities.tagging.invite_module.add_cta');
    }
  };

  renderErrorMessage = () => {
    const { follower } = this.props;

    return (
      <div className={styles.errorMessage}>
        <p>
          <span className={`${styles.errorIcon} app-icon icon-close icon-xs`} />
          Sorry, we were unable to add {follower.name}. Try again later.
        </p>
      </div>
    );
  };

  render() {
    const { follower, activityId } = this.props;
    const { athleteId, name, location, avatarUrl } = follower;

    return (
      <li className={`${styles.listItem}`}>
        <div className={`${styles.content} media media-middle`}>
          <div className="media-left">
            <Avatar
              type="athlete"
              src={avatarUrl}
              name={`${name}'s avatar`}
              size="small"
              data-testid="avatar"
            />
          </div>
          <div className="media-body">
            <div className={`${styles.name}`}>{name}</div>
            <div className={`${styles.location}`}>{location}</div>
          </div>
          <div className={`${styles.buttonWrapper} media-actions`}>
            <Button
              type="button"
              variant={this.addButtonVariant()}
              className={this.addButtonClassName()}
              onClick={this.addButtonClickHandler(athleteId, activityId)}
              disabled={follower.status !== followerStatus.READY}
            >
              {this.renderSubmitButtonContent()}
            </Button>
          </div>
        </div>
        {follower.status === followerStatus.ERROR && this.renderErrorMessage()}
      </li>
    );
  }
}

FollowersListItem.propTypes = {
  follower: PropTypes.shape({
    athleteId: PropTypes.number.isRequired,
    avatarUrl: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    status: PropTypes.number.isRequired
  }).isRequired,
  activityId: PropTypes.number.isRequired,
  handleInvite: PropTypes.func.isRequired
};

export default FollowersListItem;
