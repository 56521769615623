import * as React from 'react';
import PropTypes from 'prop-types';
import { color as uiToken } from '@strava/ui-tokens/js';

const HeartIconFilledSVG = ({ color = uiToken.colorCoreO3, size = 16 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden={true}
  >
    <path
      d="M14.2256 2.76413C13.4043 1.94233 12.3125 1.49023 11.1504 1.49023C9.98832 1.49023 8.89652 1.94233 8.07522 2.76363C8.04982 2.78903 8.02442 2.81443 8.00002 2.84033C7.97562 2.81443 7.95122 2.78903 7.92582 2.76413C6.23052 1.06783 3.46882 1.06883 1.77352 2.76413C0.0781156 4.46043 0.0781156 7.21973 1.77342 8.91603L8.00002 15.1421L14.2256 8.91603C15.0479 8.09473 15.5 7.00193 15.5 5.83983C15.5 4.67773 15.0479 3.58543 14.2256 2.76413Z"
      fill={color}
    />
  </svg>
);

HeartIconFilledSVG.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number
};

export default HeartIconFilledSVG;
