import React from 'react';
import PropTypes from 'prop-types';
import Collapsible from 'react-collapsible';

import CollapsibleTitleWithCaret from 'components/CollapsibleTitleWithCaret';
import Discussion from 'components/Discussions';
import { connect } from 'react-redux';
import QualifyingActivities from '../QualifyingActivities';
import styles from './styles.scss';

import Leaderboard from '../Leaderboard';

const CollapsibleSection = ({
  challengeId,
  sections,
  currentAthlete = null,
  joined = false
}) => {
  const createMarkup = (html) => ({ __html: html });

  const buildSections = () =>
    sections.map((section) => (
      <div key={section.title}>
        <Collapsible
          open={section.openOnLoad}
          transitionTime={250}
          trigger={
            <CollapsibleTitleWithCaret
              title={section.title}
              arrowStyle="icon-caret-up"
            />
          }
          triggerWhenOpen={
            <CollapsibleTitleWithCaret
              title={section.title}
              arrowStyle="icon-caret-down"
            />
          }
        >
          <div className={styles.subsectionContainer}>
            {section.content.map((content) => (
              <div key={content.key} className={styles.subsection}>
                {content.heading && (
                  <div
                    className={`text-subhead ${styles.dark}`}
                    id={
                      content.qualifyingActivities
                        ? 'qualifying-activities'
                        : ''
                    }
                  >
                    {content.heading}
                  </div>
                )}

                {/* HTML is being (dangerously) rendered because this description is
              added by an admin from a dashboard on admin.strava.com */}
                {content.text && (
                  <div
                    className={`text-subhead ${styles.light}`}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={createMarkup(content.text)}
                  />
                )}

                {content.imageUrl && (
                  <img
                    className={styles.sectionImage}
                    src={content.imageUrl}
                    alt={content.imageTitle || ''}
                    title={content.imageTitle || ''}
                  />
                )}

                {/* Leaderboard */}
                {content.key === 'leaderboard' && (
                  <Leaderboard
                    currentAthlete={currentAthlete}
                    challengeId={challengeId}
                    joined={joined}
                  />
                )}

                {/* Qualifying activities */}
                {content.qualifyingActivities && (
                  <QualifyingActivities
                    activities={content.qualifyingActivities}
                    id="challenge_detail_page_qualifying_activities"
                  />
                )}

                {/* Discussions */}
                {content.key === 'discussions' && (
                  <Discussion
                    discussionPath={content.discussionPath}
                    currentAthlete={currentAthlete}
                  />
                )}
              </div>
            ))}
          </div>
        </Collapsible>
        <hr className={styles.line} />
      </div>
    ));

  return (
    <div data-testid="collapsible-sections">
      {sections.length > 0 && buildSections(sections)}
    </div>
  );
};

CollapsibleSection.propTypes = {
  challengeId: PropTypes.number.isRequired,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string.isRequired,
          heading: PropTypes.string,
          text: PropTypes.string,
          imageUrl: PropTypes.string,
          imageTitle: PropTypes.string,
          qualifyingActivities: PropTypes.arrayOf(PropTypes.shape({}))
        })
      ).isRequired,
      openOnLoad: PropTypes.bool.isRequired
    })
  ).isRequired,
  currentAthlete: PropTypes.shape({}),
  joined: PropTypes.bool
};

export default connect((state) => ({
  joined: state.joined
}))(CollapsibleSection);

export { CollapsibleSection };
