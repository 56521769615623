import React from 'react';
import PropTypes from 'prop-types';

import I18n from 'utils/I18n';

import styles from './index.scss';

const I18nPrefix = 'strava.settings.partner_integrations.';
const I18nPrefixSelected = `${I18nPrefix}settings_changed.`;
const I18nKey = {
  enabled: `${I18nPrefix}enabled`,
  disabled: `${I18nPrefix}disabled`,
  seeYourActivities: `${I18nPrefix}see_your_activities`,
  selected: {
    enabled: `${I18nPrefixSelected}enabled_without_date`,
    disabled: `${I18nPrefixSelected}disabled_without_date`
  }
};

export const getDescriptionString = (partnerName, value) => {
  if (value) {
    return I18n.t(I18nKey.selected.disabled, {
      partnerName
    });
  }
  return I18n.t(I18nKey.selected.enabled, {
    partnerName
  });
};

export const renderText = (selected, name, value) => {
  if (selected) {
    return (
      <div className={styles.description}>
        <p>{getDescriptionString(name, value)}</p>
      </div>
    );
  }
  return null;
};

const OptOutSetting = ({ partner, partnerType, onChange, selected }) => (
  <div className={styles.optOutSection}>
    <div className={styles.partnerImageContainer}>
      <img
        className={styles.partnerImage}
        src={partner.profileImage}
        alt={partner.name}
      />
    </div>
    <div className={styles.partnerBody}>
      <h4 className={`text-subhead ${styles.partnerName}`}>
        {partner.partnerName}
      </h4>
      <form action="">
        <label className={styles.input}>
          <input
            type="radio"
            name={partner.partnerName}
            checked={!partner.value}
            disabled={partner.disabled}
            onChange={() => onChange({ ...partner, partnerType })}
          />
          <span className={styles.radioLabel}>{I18n.t(I18nKey.enabled)}</span>
        </label>
        <label className={styles.input}>
          <input
            type="radio"
            name={partner.partnerName}
            checked={partner.value}
            disabled={partner.disabled}
            onChange={() => onChange({ ...partner, partnerType })}
          />
          <span className={styles.radioLabel}>{I18n.t(I18nKey.disabled)}</span>
        </label>
      </form>
      {renderText(selected, partner.partnerName, partner.value)}
    </div>
  </div>
);

OptOutSetting.propTypes = {
  onChange: PropTypes.func.isRequired,
  partnerType: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  partner: PropTypes.shape({
    name: PropTypes.string,
    disabled: PropTypes.bool,
    optOutName: PropTypes.string.isRequired,
    partnerName: PropTypes.string.isRequired,
    profileImage: PropTypes.string.isRequired,
    value: PropTypes.bool.isRequired
  }).isRequired
};

export default OptOutSetting;
