import React from 'react';
import PropTypes from 'prop-types';
import {
  HashRouter as Router,
  Switch,
  Redirect,
  Route
} from 'react-router-dom';

import createNetworkingClient from 'utils/networking-client';
import { trackV2 } from 'utils/analytics';
import I18n from 'utils/I18n';

import Step from './components/Step';
import Summary from './components/Summary';

import styles from './index.scss';

const I18nPrefix = 'strava.settings.edit_past_activities.';
const I18nKey = {
  areYouSure: `${I18nPrefix}are_you_sure`
};

const AnalyticsCategory = 'edit_past_activities';
const AnalyticsAction = {
  Click: 'click',
  Error: 'error',
  ScreenEnter: 'screen_enter',
  ScreenExit: 'screen_exit'
};

const SummaryRoute = 'summary';
const ConfirmationRoute = 'confirmation'; // psuedo 'route' for analytics, this is the window.confirm

class EditPastActivitiesApp extends React.Component {
  constructor(props) {
    super(props);

    const { attributes } = props;
    this.flow = attributes.map((a) => a.name).concat(SummaryRoute);

    this.state = {
      isSubmitted: false,
      isSubmitting: false,
      updates: {},
      summary: {}
    };
  }

  trackState = ({ page, action, element, properties }) => () => {
    const category = AnalyticsCategory;
    const { updates } = this.state;

    trackV2({
      category,
      page,
      action,
      element,
      properties: { setting_value: updates, ...properties }
    });
  };

  trackScreenEnter = (page) =>
    this.trackState({ page, action: AnalyticsAction.ScreenEnter });

  trackScreenExit = (page) =>
    this.trackState({ page, action: AnalyticsAction.ScreenExit });

  trackClick = (page, element) =>
    this.trackState({ page, element, action: AnalyticsAction.Click });

  trackError = (page, status) =>
    this.trackState({
      page,
      action: AnalyticsAction.Error,
      properties: { status }
    });

  handleCancel = (page) => {
    return () => {
      this.trackClick(page, 'cancel')();
      this.routeToReturnTo();
    };
  };

  routeToReturnTo = () => {
    const { returnTo } = this.props;
    window.location = returnTo;
  };

  handleValueChange = (name, title) => (value, changes) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        updates: {
          ...prevState.updates,
          [name]: value
        },
        summary: {
          ...prevState.summary,
          [name]: { title, changes }
        }
      };
    }, this.trackClick(name, value));
  };

  confirmSubmit = () => {
    const page = ConfirmationRoute;

    this.trackScreenEnter(page)();
    const { summary } = this.state;

    const changes = Object.keys(summary).map((k) => {
      return `* ${summary[k].changes}`;
    });

    const copy = `${I18n.t(I18nKey.areYouSure)}\n${changes.join('\n')}`;

    // eslint-disable-next-line no-alert
    const didSubmit = window.confirm(copy);

    const element = didSubmit ? 'next' : 'cancel';
    this.trackClick(page, element)();
    this.trackScreenExit(page)();
    return didSubmit;
  };

  handleSubmit = () => {
    this.trackClick(SummaryRoute, 'next')();
    const { updates } = this.state;
    const { url } = this.props;

    if (!this.confirmSubmit()) {
      return;
    }

    const successState = { isSubmitted: true, isSubmitting: false };
    const failState = { isSubmitting: false };

    this.setState({ isSubmitting: true }, () => {
      createNetworkingClient()
        .post(url, { updates })
        .then((response) => {
          const status = response && response.status;
          if (status === 202) {
            this.setState(successState);
            return;
          }

          this.trackError(ConfirmationRoute, status)();
          this.setState(failState);
        })
        .catch((error) => {
          const status = error.response && error.response.status;
          this.trackError(ConfirmationRoute, status)();
          if (status === 429) {
            this.routeToReturnTo();
            return;
          }
          // unexpected error
          this.setState(failState);
        });
    });
  };

  render() {
    const { attributes } = this.props;
    const { isSubmitted, isSubmitting, updates, summary } = this.state;

    return (
      <Router>
        <div className={styles.container}>
          <Switch>
            <Redirect exact={true} from="/" to={this.flow[0]} />
            {attributes.map((attr) => (
              <Route path={`/${attr.name}`} key={attr.name}>
                <Step
                  {...attr}
                  flow={this.flow}
                  isSubmitted={isSubmitted}
                  handleCancel={this.handleCancel(attr.name)}
                  trackNext={this.trackClick(attr.name, 'next')}
                  trackScreenEnter={this.trackScreenEnter(attr.name)}
                  trackScreenExit={this.trackScreenExit(attr.name)}
                  handleValueChange={this.handleValueChange(
                    attr.name,
                    attr.title
                  )}
                  currentValue={updates[attr.name]}
                />
              </Route>
            ))}
            <Route path={`/${SummaryRoute}`}>
              <Summary
                {...this.props}
                {...{ isSubmitted, isSubmitting, summary }}
                handleCancel={this.handleCancel(SummaryRoute)}
                handleSubmit={this.handleSubmit}
                trackScreenEnter={this.trackScreenEnter(SummaryRoute)}
                trackScreenExit={this.trackScreenExit(SummaryRoute)}
              />
            </Route>
          </Switch>
        </div>
      </Router>
    );
  }
}

EditPastActivitiesApp.propTypes = {
  url: PropTypes.string.isRequired,
  returnTo: PropTypes.string.isRequired,
  attributes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['OptionList']),
      config: PropTypes.object
    })
  ).isRequired
};

export default EditPastActivitiesApp;
