import React from 'react';
import PropTypes from 'prop-types';
import Button from '@strava/ui/Button';
import Spinner from '@strava/ui/Spinner';

import createNetworkingClient from 'utils/networking-client';
import I18n from 'utils/I18n';

import Select from '../Select';

import styles from './styles.scss';

const I18nPrefix = 'strava.challenges.challenge_detail';

class StravaInvite extends React.Component {
  state = {
    submitRequested: false,
    submitSucceeded: false,
    errorMessage: '',
    successMessage: '',
    inviteValue: ''
  };

  handleChange = (selectedAthletesArr) => {
    if (!selectedAthletesArr) {
      this.setState({ inviteValue: '' });
      return;
    }
    const ids = selectedAthletesArr.map((athlete) => athlete.id);

    this.setState({ inviteValue: ids.join(',') });
  };

  handleSubmit = () => {
    const { inviteValue } = this.state;
    const { challengeId } = this.props;

    this.setState({ submitRequested: true });

    const instance = createNetworkingClient();

    instance
      .put(`/challenges/${challengeId}/invite`, { athlete_ids: inviteValue })
      .then((response) => {
        if (response && response.status === 200) {
          this.onSubmitSuccess(response.data);
        } else {
          this.onSubmitFail();
        }
      })
      .catch(() => {
        this.onSubmitFail();
      });
  };

  onSubmitSuccess = () => {
    this.setState({
      submitRequested: false,
      submitSucceeded: true,
      errorMessage: '',
      successMessage: I18n.t(`${I18nPrefix}.success_message`)
    });

    setTimeout(() => {
      const { closeModal } = this.props;
      closeModal();
    }, 2000);
  };

  onSubmitFail = () => {
    this.setState({
      submitRequested: false,
      errorMessage: I18n.t(`${I18nPrefix}.error_message`)
    });
  };

  renderMessage = () => {
    const { errorMessage, successMessage } = this.state;

    if (errorMessage) {
      return (
        <h2 className={`${styles.message} ${styles.error}`}>{errorMessage}</h2>
      );
    }

    if (successMessage) {
      return (
        <h2 className={`${styles.message} ${styles.success}`}>
          {successMessage}
        </h2>
      );
    }

    return null;
  };

  render() {
    const { inviteValue, submitRequested, submitSucceeded } = this.state;
    const { friends = [], challengeName, currentAthlete } = this.props;

    return (
      <>
        {this.renderMessage()}
        {submitRequested ? (
          <div className={styles.spinner}>
            <Spinner />
          </div>
        ) : (
          !submitSucceeded && (
            <div>
              {I18n.t(`${I18nPrefix}.to`)}
              <div className={styles.selectInput}>
                <Select options={friends} handleChange={this.handleChange} />
              </div>
              <div>
                <div>{I18n.t(`${I18nPrefix}.message`)}</div>
                <div className="text-callout">
                  {I18n.t(`${I18nPrefix}.invite_message`, {
                    athleteName: currentAthlete.name,
                    challengeName
                  })}
                </div>
              </div>
              <Button
                key="invite"
                variant="primary"
                disabled={!inviteValue.trim() || submitRequested}
                onClick={this.handleSubmit}
                className={`btn btn-sm ${styles.submitButton}`}
              >
                {I18n.t(`${I18nPrefix}.invite`)}
              </Button>
            </div>
          )
        )}
      </>
    );
  }
}

StravaInvite.propTypes = {
  friends: PropTypes.arrayOf(PropTypes.shape({})),
  closeModal: PropTypes.func.isRequired,
  challengeId: PropTypes.number.isRequired,
  currentAthlete: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  challengeName: PropTypes.string.isRequired
};

export default StravaInvite;
