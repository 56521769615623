import React from 'react';
import PropTypes from 'prop-types';
import Button from '@strava/ui/Button';
import I18n from 'utils/I18n';

import refreshIcon from '@strava/orion/assets/icons/actions_refresh_normal_large.svg';

import styles from './styles.scss';

const I18nPrefix = 'strava.relative_effort.loading_failure';

const LoadingError = ({ handleRetry }) => (
  <div className={styles.container}>
    <div className={styles.refreshIcon}>
      <div className="app-icon-wrapper">
        <div
          className="app-icon icon-xxl"
          style={{ backgroundImage: `url(${refreshIcon})` }}
        />
      </div>
    </div>
    <p className={`text-footnote ${styles.message}`}>
      {I18n.t(`${I18nPrefix}.update_failed`)}
    </p>
    <Button
      type="button"
      variant="default"
      className={`text-footnote text-heavy ${styles.cta}`}
      onClick={handleRetry}
    >
      {I18n.t(`${I18nPrefix}.cta`)}
    </Button>
  </div>
);

LoadingError.propTypes = {
  handleRetry: PropTypes.func.isRequired
};

export default LoadingError;
