import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ActionsCancelNormalXsmall from '@strava/icons/ActionsCancelNormalXsmall';
import Button from '@strava/ui/Button';
import styles from './FeatureEdPopup.scss';

function FeatureEdPopup({ steps, toProgressStrings, setShowTooltip }) {
  const [stepNumber, setStepNumber] = useState(0);

  return (
    <div className={styles.container}>
      <div className={styles.topRow}>
        {stepNumber + 1}/{steps.length}
        <ActionsCancelNormalXsmall
          onClick={() => {
            setShowTooltip(false);
          }}
          className={styles.cancel}
        />
      </div>
      <div className={styles.midRow}>{steps[stepNumber]}</div>
      <div className={styles.bottomRow}>
        <Button
          type="submit"
          variant="default"
          id="next-button"
          onClick={() => {
            setStepNumber((prevState) => prevState + 1);
            if (stepNumber + 1 >= steps.length) {
              setShowTooltip(false);
            }
          }}
        >
          {toProgressStrings[stepNumber]}
        </Button>
      </div>
    </div>
  );
}

FeatureEdPopup.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  toProgressStrings: PropTypes.arrayOf(PropTypes.string).isRequired,
  setShowTooltip: PropTypes.func.isRequired
};
export default FeatureEdPopup;
