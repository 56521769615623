import React from 'react';
import PropTypes from 'prop-types';

import I18n from 'utils/I18n';
import ActivityIcon from 'components/ActivityIcon';
import CircularProgressChart from '../CircularProgressChart';
import HorizontalProgressChart from '../HorizontalProgressChart';

import styles from './styles.scss';

const I18nKey = 'strava.progress_goals';

const ProgressGoalDisplay = ({
  goal,
  formatters,
  circularChartCanvasSize = {
    width: 40,
    height: 40
  }
}) => {
  const {
    period,
    value,
    threshold: target,
    goal_type: goalType,
    activity_types: activityTypes,
    year_percent_complete: percentYearComplete
  } = goal;

  // The Goals backend implements goals with multiple activity types, but
  // only single activity type is supported in product.
  const activityType = activityTypes[0];

  // No Goal in Wambach is implemented as an active goal with a goal of 0
  const noGoal = target <= 0;
  const progress = noGoal ? 0 : Math.min(1.0, value / target);
  const isAnnualGoal = period === 'year';
  const relativeAnnualProgress = value - target * (percentYearComplete || 0);
  const annualProgressKey = relativeAnnualProgress >= 0 ? 'ahead' : 'behind';

  const formatValue = (valueType, goalValue, includeScalarUnit = true) => {
    switch (valueType) {
      case 'elevation':
        if (includeScalarUnit) {
          return formatters.elevationFormatter.formatShort(goalValue);
        }
        return formatters.elevationFormatter.format(goalValue);
      case 'time':
        return formatters.timespanFormatter.abbreviatedNoTags(goalValue, false);
      case 'distance':
        if (includeScalarUnit) {
          return formatters.distanceFormatter.formatShort(goalValue, 0);
        }
        return formatters.distanceFormatter.format(goalValue, 0);
      default:
        return '';
    }
  };

  const formatLabels = () => {
    if (noGoal) {
      return {
        primary: I18n.t(`${I18nKey}.goals_display.no_goal`),
        secondary: I18n.t(`${I18nKey}.goals_display.completed`, {
          formatted_completed_value: formatValue(goalType, value)
        }),
        annual: ''
      };
    }
    const goalRemaining = target - value;
    const goalSecondaryFormatted = I18n.t(`${I18nKey}.goals_display.subtitle`, {
      formatted_current_value: formatValue(goalType, value, false),
      formatted_goal_amount: formatValue(goalType, target)
    });
    let goalPrimaryFormatted = I18n.t(`${I18nKey}.goals_display.remaining`, {
      formatted_remaining_value: formatValue(goalType, goalRemaining)
    });
    if (goalRemaining <= 0) {
      goalPrimaryFormatted = I18n.t(`${I18nKey}.goals_display.achieved`, {
        formatted_completed_value: formatValue(goalType, target)
      });
    }
    const goalAnnualFormatted = I18n.t(
      `${I18nKey}.goals_display.annual_progress.${annualProgressKey}`,
      {
        formatted_value: formatValue(goalType, Math.abs(relativeAnnualProgress))
      }
    );
    return {
      primary: goalPrimaryFormatted,
      secondary: goalSecondaryFormatted,
      annual: goalAnnualFormatted
    };
  };

  const formattedLabels = formatLabels();

  return (
    <div className={styles.goalSection}>
      <div className={styles.goalFlex}>
        <div>
          <CircularProgressChart
            progress={isAnnualGoal ? 0 : progress}
            canvasWidth={circularChartCanvasSize.width}
            canvasHeight={circularChartCanvasSize.height}
          >
            <ActivityIcon type={activityType} size="Xsmall" />
          </CircularProgressChart>
        </div>
        <div className={styles.goalStats}>
          <div className={`text-footnote ${styles.goalStatRemaining}`}>
            {formattedLabels.primary}
          </div>
          <div className={`text-caption2 ${styles.goalStatCompleted}`}>
            {formattedLabels.secondary}
          </div>
          <div className={`text-caption2 ${styles.goalStatActivityType}`}>
            {I18n.t(`strava.activities.types.${activityType}`)}
          </div>
        </div>
      </div>
      {isAnnualGoal && (
        <div>
          <div className={styles.goalHorizontalContainer}>
            <HorizontalProgressChart
              primaryProgress={progress}
              secondaryProgress={percentYearComplete}
              canvasWidth={248}
            />
          </div>
          <div className={`text-caption2 ${styles.goalAnnualRemainingLabel}`}>
            {formattedLabels.annual}
          </div>
        </div>
      )}
    </div>
  );
};

ProgressGoalDisplay.propTypes = {
  circularChartCanvasSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number
  }),
  formatters: PropTypes.shape({
    elevationFormatter: PropTypes.object.isRequired,
    timespanFormatter: PropTypes.object.isRequired,
    distanceFormatter: PropTypes.object.isRequired
  }).isRequired,
  goal: PropTypes.shape({
    id: PropTypes.number,
    goal_type: PropTypes.string,
    activity_types: PropTypes.arrayOf(PropTypes.string),
    period: PropTypes.string,
    threshold: PropTypes.number,
    value: PropTypes.number,
    year_percent_complete: PropTypes.number
  }).isRequired
};

export default ProgressGoalDisplay;
