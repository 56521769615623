import React from 'react';
import PropTypes from 'prop-types';
import Cldr from 'utils/Cldr';
import styles from './styles.scss';

const CurrentPlace = ({ currentPlaceLabel, viewingAthleteRank, size }) => (
  <div
    data-testid="current_place"
    className={`text-subhead ${styles.container}`}
  >
    {`${currentPlaceLabel}: ${
      viewingAthleteRank ? Cldr.formatDecimal(viewingAthleteRank) : '--'
    } / ${size ? Cldr.formatDecimal(size) : '--'}`}
  </div>
);

CurrentPlace.propTypes = {
  viewingAthleteRank: PropTypes.number,
  size: PropTypes.number,
  currentPlaceLabel: PropTypes.string.isRequired
};

export default CurrentPlace;
