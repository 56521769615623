import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const CollapsibleTitleWithCaret = ({ title, arrowStyle }) => (
  <div className={styles.container}>
    <h2 className="text-headline mb-0">{title}</h2>
    <span className="app-icon-wrapper">
      <span className={`app-icon ${arrowStyle} icon-dark icon-sm`} />
    </span>
  </div>
);

CollapsibleTitleWithCaret.propTypes = {
  title: PropTypes.string.isRequired,
  arrowStyle: PropTypes.string.isRequired
};

export default CollapsibleTitleWithCaret;
