import React from 'react';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-textarea-autosize';
import Button from '@strava/ui/Button';
import Avatar from '@strava/ui/Avatar';

import createNetworkingClient from 'utils/networking-client';
import I18n from 'utils/I18n';

import styles from './styles.scss';

const I18nPrefix = 'components.discussions.posts';

class CreatePost extends React.Component {
  state = {
    value: '',
    isSubmitting: false
  };

  handleChange = (event) => {
    this.setState({ value: event.target.value });
  };

  submitPost = () => {
    const { value } = this.state;
    const { discussionPath } = this.props;

    const instance = createNetworkingClient();

    this.setState({ isSubmitting: true });

    // The only validation we need for discussions is that there is text
    // Might be overkill to need this, though
    if (!value.trim()) {
      this.onSubmitFail();
      return;
    }

    instance
      .post(
        discussionPath,
        { text: value },
        { headers: { Accept: 'text/javascript' } }
      )
      .then((response) => {
        if (response && response.status === 200) {
          this.onSubmitSuccess(response.data);
        } else {
          this.onSubmitFail();
        }
      })
      .catch(() => {
        this.onSubmitFail();
      });
  };

  onSubmitSuccess = (data) => {
    const { onCreateSuccess } = this.props;

    this.setState({ value: '', isSubmitting: false });
    onCreateSuccess(data);
  };

  onSubmitFail = () => {
    this.setState({ isSubmitting: false });
    throw new Error('Your post submission failed. Please try again');
  };

  render() {
    const { value, isSubmitting } = this.state;
    const { name, avatarSrc } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.formLayout}>
          <div className={styles.avatar}>
            <Avatar name={name} type="athlete" src={avatarSrc} />
          </div>
          <div className={styles.inputWrapper}>
            <label htmlFor="create-post" className="text-caption2">
              {I18n.t(`${I18nPrefix}.create_a_post`)}
            </label>
            <TextareaAutosize
              id="create-post"
              className={styles.input}
              value={value}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <Button
          disabled={isSubmitting || !value.trim()}
          className={`btn btn-sm ${styles.submit}`}
          onClick={this.submitPost}
        >
          {I18n.t(`${I18nPrefix}.post`)}
        </Button>
      </div>
    );
  }
}

CreatePost.propTypes = {
  avatarSrc: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onCreateSuccess: PropTypes.func.isRequired,
  discussionPath: PropTypes.string.isRequired
};

export default CreatePost;
