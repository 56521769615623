import createNetworkingClient from 'utils/networking-client';
import { devLog } from '../../utils/devUtils';
import { logError } from '../../utils/sentry';
import { sortAndFilter } from './StravaMentionsInput.utils';

// TODO::: re-implement caching after club-mentions-web has been deployed at 100%
const fetchData = (url, viewingAthleteId) => {
  return createNetworkingClient()
    .get(url)
    .then(({ data }) => {
      return sortAndFilter(data, viewingAthleteId);
    })
    .catch((e) => {
      logError(e);
      return [];
    });
};

export const fetchMentionableEntities = ({
  entity,
  entityId,
  viewingAthleteId,
  surfaceType
}) => {
  devLog(
    `[StravaMentionsInput]#fetchMentionableEntities {entity: ${entity}, entityId: ${entityId},  surfaceType: ${surfaceType}}`
  );

  let url = '/athlete/mentionable_entities';
  if (entity && entityId) {
    url = `/athlete/mentionable_entities?parent_type=${entity}&parent_id=${entityId}`;
    if (surfaceType) {
      url += `&surface_type=${surfaceType}`;
    }
  }
  return fetchData(url, viewingAthleteId);
};

export default { fetchMentionableEntities };
