import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash-es';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import 'videojs-mux';

import cx from '@strava/ui/clsx';
import { publicServiceEnv } from 'utils/public-service-env';

import styles from './Video.scss';

const {
  MUX_ENV_KEY = null,
  VIDEOJS_LOG_LEVEL = 'error',
  MUX_DATA_VIEWER_USER_ID = null
} = publicServiceEnv;

// Only show error logs from videojs as warning and other logs can get noisy in
// production.
//
// Also by adding videojs-mux there will be warning like:
// "VIDEOJS: WARN: videojs.Hls is deprecated. Use videojs.Vhs instead."
// which is mostly noise that can be ignored as internally videojs-mux uses Hls
// and not Vhs. However videojs internally switches Hls to Vhs and it is not
// an actual issue.
// https://github.com/videojs/http-streaming/blob/721e1bfeedfd452f2fc2bb829a241faf2810b535/src/videojs-http-streaming.js#L1310-L1311
//
videojs.log.level(VIDEOJS_LOG_LEVEL);

function Video({ options, className, onReady }) {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    if (!playerRef.current) {
      if (!videoRef.current) {
        return;
      }

      const initTime = Date.now();
      playerRef.current = videojs(videoRef.current, {
        controlBar: {
          children: [
            'playToggle',
            'volumePanel',
            'currentTimeDisplay',
            'progressControl',
            'durationDisplay',
            'fullscreenToggle'
          ]
        },
        ...(MUX_ENV_KEY && {
          plugins: {
            mux: {
              debug: false,
              disableCookies: true,
              respectDoNotTrack: true,
              data: {
                env_key: MUX_ENV_KEY,
                viewer_user_id: MUX_DATA_VIEWER_USER_ID,
                player_init_time: initTime,
                player_name: 'web'
              }
            }
          }
        }),
        ...options
      });

      if (onReady) {
        onReady(playerRef.current);
      }
    } else {
      const player = playerRef.current;

      // As sources usually are a really small object there should not be any
      // issues with using isEqual and deep comparing two objects
      if (!isEqual(player.options().sources, options.sources)) {
        player.src(options.sources);
      }
      if (player.autoplay() !== options.autoplay) {
        player.autoplay(options.autoplay);
      }
      if (player.controls() !== options.controls) {
        player.controls(options.controls);
      }
      if (player.muted() !== options.muted) {
        player.muted(options.muted);
      }
      if (player.loop() !== options.loop) {
        player.loop(options.loop);
      }
    }
  }, [options, videoRef]);

  useEffect(() => {
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player={true}>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        ref={videoRef}
        className={cx(
          'video-js vjs-big-play-centered',
          className,
          styles.videoPlayer
        )}
        playsInline={true}
        autoPlay={false}
        muted={true}
      />
    </div>
  );
}

Video.propTypes = {
  // Full list of options can be checked here:
  // https://docs.videojs.com/tutorial-options.html
  // The following is not a complete list so please
  // add more as needed!
  options: PropTypes.shape({
    autoplay: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.oneOf(['muted', 'play', 'any'])
    ]),
    sources: PropTypes.arrayOf(
      PropTypes.shape({
        src: PropTypes.string,
        type: PropTypes.string
      })
    ),
    controls: PropTypes.bool,
    muted: PropTypes.bool,
    loop: PropTypes.bool
  }).isRequired,
  onReady: PropTypes.func,
  className: PropTypes.string
};

export default Video;
