/* eslint-disable import/prefer-default-export */
// Takes Time Tag and converts displayed time to time ago from current time
// ex) <time class="timeago" datetime="2021-07-17T00:53:01Z">3 days ago</time>
// returns <time class="timeago" datetime="2021-07-17T00:53:01Z">July 17, 2021</time>
import I18n from 'utils/I18n';

export const buildTimeAgoTimestamp = (timestamp) => {
  const timestampParsed = new DOMParser()
    .parseFromString(timestamp, 'text/html')
    .body.querySelector('time')
    .getAttribute('datetime');
  const timeToReplace = new DOMParser()
    .parseFromString(timestamp, 'text/html')
    .body.querySelector('time').innerHTML;
  const timeAgo = I18n.timespanFormatter().constructor.timeago(
    Date.parse(timestampParsed)
  );
  return timestamp.replace(timeToReplace, timeAgo);
};
