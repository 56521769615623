import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@strava/ui/Avatar';

import styles from './styles.scss';

const messageTextStyleClass = (size) => {
  return size === 'small' ? `text-subhead` : `text-caption1`;
};

const Facepile = ({ athletes, message, size, className = '', ...props }) => (
  <div className={`${styles.container} ${className}`} {...props}>
    <ol className={styles.avatarList}>
      {athletes.map((athlete) => (
        <li className={styles.avatar} key={athlete.id}>
          <Avatar
            name={athlete.displayName}
            type="athlete"
            size={size}
            className={`${styles.avatarImg}`}
            src={athlete.profileImg}
          />
        </li>
      ))}
    </ol>
    {message && (
      <div className={`${messageTextStyleClass(size)} ${styles.message}`}>
        {message}
      </div>
    )}
  </div>
);

Facepile.propTypes = {
  athletes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      displayName: PropTypes.string.isRequired,
      profileImg: PropTypes.string.isRequired
    })
  ).isRequired,
  message: PropTypes.string,
  size: PropTypes.oneOf(['xsmall', 'small']),
  className: PropTypes.string
};

export default Facepile;
