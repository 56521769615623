import React from 'react';
import PropTypes from 'prop-types';
import { Manager, Reference, Popper } from 'react-popper';

import Portal from '../Portal';

import styles from './styles.scss';

class Tooltip extends React.Component {
  state = {
    isOpen: this.props.isOpen
  };

  handleShow = () => {
    this.setState({ isOpen: true });
  };

  handleHide = () => {
    this.setState({ isOpen: false });
  };

  tooltipClassName = () => {
    const { className } = this.props;
    const toolTipClasses = [styles.tooltip];

    if (className) {
      toolTipClasses.push(className);
    }

    return toolTipClasses.join(' ');
  };

  render() {
    const { children, content, placement = 'top' } = this.props;
    const { isOpen } = this.state;

    return (
      <Manager>
        <Reference>
          {({ ref }) =>
            React.cloneElement(children, {
              ref,
              onMouseOver: this.handleShow,
              onMouseLeave: this.handleHide,
              onFocus: this.handleShow,
              onBlur: this.handleHide
            })
          }
        </Reference>
        <Portal id="tooltip-container">
          {isOpen ? (
            <Popper placement={placement}>
              {({ style, placement: dataPlacement, ref, arrowProps }) => (
                <div
                  ref={ref}
                  role="tooltip"
                  style={style}
                  className={styles.tooltipContainer}
                  data-placement={dataPlacement}
                >
                  <div className={this.tooltipClassName()}>{content}</div>
                  <div
                    ref={arrowProps.ref}
                    style={arrowProps.style}
                    className={styles.arrow}
                    data-placement={dataPlacement}
                  />
                </div>
              )}
            </Popper>
          ) : null}
        </Portal>
      </Manager>
    );
  }
}

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.string.isRequired,
  placement: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  className: PropTypes.string,
  isOpen: PropTypes.bool
};

export default Tooltip;
