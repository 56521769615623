export const buildInitialKudosAndCommentsState = (overrides) => ({
  entity: null,
  parentEntityId: null,
  entityId: null,
  openKudosCommentModal: false,
  selectedTabIndex: 0,
  kudoPosted: false,
  newComment: null,
  openCommentDeleteDialog: false,
  isViewingCommentReactionsList: false,
  selectedComment: null,
  ...overrides
});

export default { buildInitialKudosAndCommentsState };
