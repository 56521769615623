import React from 'react';
import PropTypes from 'prop-types';
import Button from '@strava/ui/Button';
import I18n from 'utils/I18n';

import SvgActionsRefreshNormalLarge from '@strava/icons/ActionsRefreshNormalLarge';

import styles from './styles.scss';

const I18nKey = 'strava.progress_goals';

const FetchError = ({ handleRetry }) => (
  <div className={styles.container}>
    <div className={styles.refreshIcon}>
      <div className="app-icon-wrapper">
        <SvgActionsRefreshNormalLarge className="app-icon icon-xxl" />
      </div>
    </div>
    <p className={`text-footnote ${styles.message}`}>
      {I18n.t(`${I18nKey}.update_failed`)}
    </p>
    <Button
      type="button"
      variant="default"
      className={`text-footnote text-heavy ${styles.cta}`}
      onClick={handleRetry}
    >
      {I18n.t(`${I18nKey}.cta`)}
    </Button>
  </div>
);

FetchError.propTypes = {
  handleRetry: PropTypes.func.isRequired
};

export default FetchError;
