import React from 'react';
import PropTypes from 'prop-types';
import Button from '@strava/ui/Button';

import I18n from 'utils/I18n';

import ShareModal from './components/ShareModal';

import styles from './styles.scss';

const I18nPrefix = 'strava.challenges.challenge_detail';

class InviteFriends extends React.Component {
  state = { modalIsOpen: false };

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false
    });
  };

  render() {
    const { modalIsOpen } = this.state;
    const {
      outline = false,
      challengeLogoUrl,
      challengeName,
      currentAthlete,
      challengeId
    } = this.props;

    return (
      <div className={styles.container}>
        <Button
          variant={outline ? 'primaryOutline' : 'primary'}
          className={`btn-sm btn-block text-footnote ${styles.button}`}
          onClick={this.openModal}
        >
          {I18n.t(`${I18nPrefix}.invite_friends`)}
        </Button>
        <ShareModal
          modalIsOpen={modalIsOpen}
          closeModal={this.closeModal}
          challengeId={challengeId}
          challengeLogoUrl={challengeLogoUrl}
          challengeName={challengeName}
          currentAthlete={currentAthlete}
        />
      </div>
    );
  }
}

InviteFriends.propTypes = {
  challengeId: PropTypes.number.isRequired,
  challengeLogoUrl: PropTypes.string.isRequired,
  currentAthlete: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  challengeName: PropTypes.string.isRequired,
  outline: PropTypes.bool
};

export default InviteFriends;
