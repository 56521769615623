import React from 'react';
import PropTypes from 'prop-types';

import ActionsVisibleDisabledNormalXsmall from '@strava/icons/ActionsVisibleDisabledNormalXsmall';
import styles from './styles.scss';

const Handle = ({ domain: [min, max], handle, getHandleProps }) => {
  return (
    <button
      role="slider"
      type="button"
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuenow={handle.value}
      className={styles.slider}
      style={{
        left: `${handle.percent}%`
      }}
      {...getHandleProps(handle.id)}
    >
      <div>
        <ActionsVisibleDisabledNormalXsmall />
      </div>
    </button>
  );
};

Handle.propTypes = {
  domain: PropTypes.arrayOf(PropTypes.number).isRequired,
  handle: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired
  }).isRequired,
  getHandleProps: PropTypes.func.isRequired
};

export default Handle;
