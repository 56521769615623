import React from 'react';

const KudosAndCommentsContext = React.createContext();
// Context object accepts a displayName string property.
// React DevTools uses this string to determine what to display for the context.
KudosAndCommentsContext.displayName = 'KudosAndCommentsContext';

export function useKudosAndCommentsContext() {
  const context = React.useContext(KudosAndCommentsContext);

  if (!context) {
    throw new Error(
      'useKudosAndCommentsContext must be used within the KudosAndCommentsContext.Provider'
    );
  }

  return context;
}

export default KudosAndCommentsContext;
